@import "~antd/dist/antd.less";
@import "./variables.less";

//Save for future used
/* @-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */

@keyframes swing {
    0% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(10deg);
    }
}

@keyframes blinking {
    0% {
        opacity: 0.75;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.25;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

#root {
    height: 100vh;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: @font-size-12;
}
.no-padding.historicalevent {
    &.ant-table-expanded-row {
        .ant-table-pagination {
            margin-top: @pxh-15 !important;
            margin-bottom: @pxh-15 !important;
        }
    }

    & > .ant-table-cell {
        .no-padding;
        background: @bg-white;
    }
}

.can-hover {
    cursor: pointer;
}

.ant-row {
    width: 100%;
}

.ant-menu {
    &.ant-menu-inline-collapsed {
        & > .ant-menu-item {
            //.padding-2px-important(0);
            .flex-column-center;
            justify-content: center;
            line-height: 0;

            & > .ant-menu-item-icon {
                // margin-left: (@px-2 * 4) !important;
                margin-left: 0;
            }
        }
    }
}

.custom-subtitle {
    .font-size-2px(7);
    font-weight: bold;
}

.mg-btm-title {
    margin-bottom: @pxh-10;
}

.tiny-description {
    .ant-descriptions-header {
        margin-bottom: 0px;
        .ant-descriptions-title {
            .font-size-2px(4);
        }
    }
    .ant-descriptions-view {
        .ant-descriptions-item {
            padding-bottom: 0px;
        }
    }
}

.badge-blinking {
    span {
        &:nth-child(1) {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
                animation: antStatusProcessing 1.2s infinite ease-in-out;
                content: "";
            }
            &.ant-badge-status-success::after {
                border: 1px solid @color-sucess;
            }
            &.ant-badge-status-error::after {
                border: 1px solid @color-error;
            }
        }
    }
    &.success {
        span {
            &:nth-child(2) {
                color: @color-sucess;
            }
        }
    }
    &.error {
        span {
            &:nth-child(2) {
                color: @color-error;
            }
        }
    }
}

.overlay-spinning {
    .ant-spin-spinning {
        position: fixed !important;
        top: 50% !important;
        margin-top: @pxh-10 * -1;

        .ant-progress {
            .flex-column-center;
            width: @px-100 * 3 !important;
            margin-left: @px-100 * -1.5 !important;
        }

        .ant-spin-text {
            .font-size-2px-bold(8);
            position: absolute;
            margin-top: @pxh-50 * -1;
        }
    }
}

.fixed-time-count-down {
    .flex-row-between;
    .padding-2px(1.5, 7);
    align-items: center;
    border: 1px solid #d4d4d4;
    border-radius: 3px;

    .anticon {
        .font-size-2px(9);
        margin-right: @px-10;
    }
}

.collapse-box-container {
    .main-border;

    .title {
        .flex-row-between;
        align-items: center;
        border-bottom: 1px solid @main-border-color;

        .left {
            .font-size-2px-bold(7);
            .padding-2px(3, 5);
        }
        .right {
            .flex-row;
            text-align: right;

            .btn-collapse {
                .padding-2px(3, 5);
                display: inline-block;
                cursor: pointer;

                .anticon {
                    .font-size-2px(8);
                }
            }
        }
    }
    .content {
        .padding-2px(3, 5);
        background: #fbfbfb;
        box-shadow: inset -1px 1px 3px #d5d5d5;

        .main-content {
            margin-top: @pxh-10;
            max-height: @pxh-100;
            overflow-y: auto;
        }
    }
}

.free-text-container {
    width: 100%;
    height: auto;

    &.empty-list {
        height: @pxh-30;

        p {
            .font-size-2px(7);
        }
    }

    &.view-only {
        pointer-events: none;
    }

    &-container:hover {
        .main-border-bg;
    }

    .tag-container {
        .padding-2px(3);
        padding-top: @pxh-2 * 2;
    }
    .ant-tag {
        .margin-2px(1.5);
        width: auto;
    }
    .ant-typography {
        padding: 0px @px-10;
    }
    .ant-typography:hover {
        text-decoration: underline;
    }
}

.ant-tabs-nav {
    .ant-tabs-nav-list {
        .ant-tabs-tab {
            border-radius: 5px 5px 0 0 !important;

            &-active {
                .main-bg;

                & > div {
                    color: @color-white !important;
                }
            }
        }
    }
}

.segment-opt-component {
    .content {
        margin-top: @pxh-15;

        &.border {
            .main-border;
        }
    }
}

.empty-container {
    .padding-2px(30, 0);
}

.custom-skeleton {
    .padding-2px(10, 0);

    &-input {
        .padding-2px(2.5, 0);
    }
}

.main-layout {
    min-height: 100vh !important;
}

.header {
    .flex-row-between;
    .padding-2px(12, 9);
    align-items: center;
    height: @pxh-30;
    background-color: #f0f2f5;

    .left-panel {
        .flex-row;
        align-items: center;

        .collapse-btn {
            .padding-2px(1.3);
            margin-left: @px-30 * -1.05;
            border-radius: 30px;
            background: #f0f2f5;
            z-index: 2;
            position: fixed;

            .ant-btn {
                background-color: #034c58;
            }
        }
    }

    .right-panel {
        .padding-2px(5);

        .setting {
            button:first-child {
                padding-left: 0;
            }

            button:last-child {
                color: @color-white;
                border-radius: 30px !important;
                background: #c1c1c1;
            }
            .ant-btn-icon-only {
                border: none;
            }
        }

        .user-info-panel {
            .flex-column;
            line-height: 1.3;
            justify-content: center;
            align-items: flex-end;

            span:first-child {
                font-size: @font-size-16;
                .font-bold;
            }

            span:last-child {
                color: #8b8b8b;
            }
        }

        .navigation {
            .flex-column;
            line-height: 1.3;
            justify-content: center;
            margin-right: @px-15;

            & > .btn-group {
                display: flex;
                flex-direction: row !important;

                .ant-btn {
                    margin-right: @px-5;
                }
            }
        }

        .notification {
            .flex-column;
            line-height: 1.3;
            justify-content: center;
            margin-right: @px-15;

            .notification-component {
                .notification-button {
                    background: transparent;
                    border: 1px solid #dfdfdf;

                    .notif-icon {
                        font-size: @font-size-20;
                        color: #434343;
                    }

                    &.active {
                        background: @bg-white;
                        border: none;

                        .notif-icon {
                            .padding-2px(1);
                            animation: swing 0.2s infinite ease-in-out;
                        }
                    }
                }
            }
        }

        .centralized-settings {
            .flex-column;
            line-height: 1.3;
            justify-content: center;
            margin-right: @px-15;

            .btn-settings {
                background: transparent;
                border: 1px solid #dfdfdf;

                .anticon {
                    font-size: @font-size-20;
                    color: #434343;
                }
            }
        }
    }
}

.main-content-div {
    .padding-2px(0, 9);
}

.back-to-top-container {
    right: @px-15 !important;

    .anticon-up {
        .flex-column-center;
        border-radius: 30px;
        justify-content: center;
        height: @pxh-10 * 3;
        background: @main-app-color;
        color: @color-white;
    }
}

.notif-list-container {
    .main-bg-white;
    max-height: (@pxh-100 * 7) !important;
    width: (@px-100 * 5) !important;
    overflow: auto !important;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 30%) !important;
    border-radius: 7px !important;

    .header-div {
        .flex-row-between;
        margin: 0;

        .notif-header {
            .main-bg-white;
            .padding-2px(7.5, 10);
            .font-size-2px-bold(10);
            .flex-column-center;
            justify-content: center;
        }

        .mark-all-as-read-button {
            .margin-2px(10, 0);

            &.non-present {
                .hide;
            }
        }
    }

    .list-item-notif {
        .padding-2px(5, 10);
    }

    .list-item-notif:hover {
        cursor: pointer;
        background-color: #f4f3f3;
    }

    .list-item-unread-flag {
        height: @pxh-2 * 3;
        width: @px-2 * 3;
        background-color: #126e68;
        border-radius: 50%;

        &.isRead {
            .hide;
        }
    }

    .ant-divider-horizontal {
        margin-top: 0;
    }
}

.site-main-content {
    .site-page-header {
        .no-padding;
        margin-bottom: 0px !important;

        .ant-breadcrumb {
            li {
                .flex-row;
                align-items: center;
                justify-content: center;

                .ant-breadcrumb-link {
                    .name-container {
                        .padding-2px(2, 7.5);
                    }
                }
            }
        }
        .ant-page-header-heading {
            .main-bg-white;
            .padding-2px(5, 10);
        }
    }

    .page-content {
        border-top: 1px solid #e5e3e3 !important;
    }
}

.single-page {
    &.with-background {
        .padding-2px(5);
        background: @bg-white;
    }

    & > div {
        display: none;

        &.active {
            display: block;
        }
    }

    .site-main-content {
        .site-page-header {
            .ant-page-header-heading {
                background-color: #fafafa !important;
            }
        }
    }
}

.setting-overlay-panel {
    width: @px-100 * 1.8;
}

.siderbar-container {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0.4375rem 0rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.4);
    overflow: auto;
    position: fixed !important; // need !important to work, layout wrapping header + content witch css - marginInlineStart
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    scrollbar-width: thin;
    scrollbar-color: unset;

    .ant-menu-dark {
        ul.ant-menu-sub {
            background: #2f3e46 !important;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        .ant-menu-item-group {
            .ant-menu-item-group-title {
                font-size: @font-size-12;
                color: #959595;
                background: #34454d;
            }
        }
    }

    .ant-menu-item-icon,
    .ant-menu-title-content,
    .ant-menu-submenu-arrow,
    .ant-menu-item .anticon {
        color: @color-white;
    }
    .ant-menu-item {
        //padding-left: @px-25 !important;
        color: @color-white;
    }
    .ant-menu-submenu {
        .ant-menu-item {
            padding-left: @px-20 !important;
        }
    }
}

.iswatch {
    color: #c1c1c1 !important;

    &.selected {
        color: @main-app-color !important;
    }
}

.logo-container {
    .logo {
        width: @px-20;
        height: @pxh-20;
        cursor: none;
    }

    span {
        .font-size-2px-bold(8);
        color: @color-white !important;
    }

    .kbarshortcut {
        display: none;

        height: fit-content;
        justify-content: center;
        align-items: center;
        gap: 2px;
        font-size: 0.75rem !important;
        font-weight: normal !important;
        line-height: normal;

        span {
            font-size: 0.75rem !important;
            font-weight: normal !important;
            height: fit-content;
            display: inline-block;
            align-self: center;
            background: #ccc;
            border-radius: 4px;
            color: #333 !important;
            padding: 2px 5px;
        }
    }
}
.ant-layout-sider:not(.ant-layout-sider-collapsed) .logo-container:hover {
    .kbarshortcut {
        display: inline-flex;
    }
}

.nav-segmented {
    border-radius: 10px !important;
    .margin-2px(0, 6);
    margin-bottom: @pxh-25 !important;

    .ant-segmented-group {
        border-radius: 10px !important;

        .ant-segmented-item {
            .padding-2px(0, 12);
            border-radius: 10px;
        }
    }
}

.step-btns-group {
    border-top: 1px solid #d0d0d0;
    padding-top: @pxh-20;

    Button {
        margin-right: @px-10;
        width: @px-100;
    }
}

.loading-container {
    .flex-column-center;
    justify-content: center;
    height: @pxh-100 * 2;
}

// .csv-link-btn {
//     .padding-2px(3, 12.5);
//     color: @color-white;
//     background-color: #00615d;
//     font-size: small;
// }
// .csv-link-btn:hover {
//     background-color: #106d67;
//     color: @color-white;
//     font-size: small;
// }

// DataTable Styles
.row-action-button-container {
    .flex-row;
    justify-content: space-evenly;
    align-items: center;

    .ant-btn-icon-only {
        padding: 0;
        height: @pxh-15;
    }
}

.flexi-selectedRow-Container {
    .padding-2px(2, 4);

    .ant-tag {
        margin-top: @pxh-2 * 2;
        margin-bottom: @pxh-2 * 2;
    }
}

.flexi-datatable {
    &.bordered {
        .main-border;
    }

    .ant-table-pagination {
        margin-right: @px-15 !important;
    }

    .ant-table-expanded-row {
        .ant-table-pagination {
            margin-top: @pxh-30 !important;
            margin-bottom: 0px !important;
        }

        .flexi-datatable {
            .ant-table-layout-fixed {
                margin: 0 !important;
            }
        }
    }

    .filter-tags-container {
        border-bottom: 1px solid @main-border-color;

        .filter-tags-div {
            .padding-2px(2.5, 8.5);

            .ant-tag {
                display: inline-flex;
                //margin-bottom: 8px;

                .text-wrap {
                    max-width: @px-100 * 6;
                    display: inline-block;
                    text-wrap: wrap;
                }
            }
        }
    }
}

.flexi-table {
    .ant-table-pagination {
        padding-left: @px-20;
    }
    .ant-table-body {
        .nice-scrollbar;

        table {
            font-size: @font-size-12;
            tbody {
                &.ant-table-tbody {
                    & tr {
                        &:nth-child(odd) {
                            td {
                                background: #f9f9f9;
                            }
                        }
                        &:nth-child(odd):not(.ant-table-expanded-row-level-1):hover {
                            td {
                                background: #ececec !important;
                            }
                        }
                        &.ant-table-row-selected {
                            td {
                                background: #fffddf !important;
                            }
                        }
                        &.ant-table-row-selected:hover {
                            td {
                                background: #fffccb !important;
                            }
                        }
                        &:nth-child(odd):is(.ant-table-row-selected):hover {
                            td {
                                background: #fffccb !important;
                            }
                        }
                        &.tiny-row {
                            td {
                                padding-top: @pxh-10 * 0.3 !important;
                                padding-bottom: @pxh-10 * 0.3 !important;
                            }
                        }
                        &.ant-table-expanded-row-level-1 {
                            table {
                                tbody {
                                    &.ant-table-tbody {
                                        & tr {
                                            &:nth-child(even) {
                                                td {
                                                    background: @bg-white;
                                                }
                                            }
                                            &:nth-child(even):hover {
                                                td {
                                                    background: #ececec;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-table-cell {
        //.padding-2px(5, 2.5);

        &.row-xsmall-size,
        &.row-xxsmall-size,
        &.row-small-size,
        &.row-middle-size,
        &.row-large-size,
        &.row-xlarge-size,
        &.row-xxlarge-size,
        &.row-currency {
            &:has(.highlight) {
                background-color: #ffdcdc !important;
            }
        }
        &.row-xsmall-size,
        &.row-xxsmall-size,
        &.row-small-size,
        &.row-middle-size {
            &:has(.row-yellow) {
                background-color: #ffeeb7 !important;
            }
        }
    }
}

.login-container {
    .flex-column-center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .background-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 1;
    }

    .login-form {
        .flex-column;
        .main-bg-white;
        // .padding-2px(15);
        padding: 16px;
        justify-content: center;
        width: clamp(320px, @px-100 * 3.2, 500px);
        border-radius: 10px;
        z-index: 10;
        box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 0.2), 1rem 1rem 0.625rem -0.3125rem rgb(0 0 0 / 0.3);

        .img-logo {
            height: auto;
            width: 100%;
        }

        .login-btn {
            .margin-2px(10, 0);
        }

        .title-div {
            .font-size-2px-bold(9);
            margin-top: @pxh-10;

            & > span {
                .font-size-2px(10);
                color: #00615d;
            }
        }

        .title-div-2 {
            margin-bottom: @pxh-20 * 2;
        }

        & > form {
            margin-top: @pxh-30;
        }
    }
}

.row-dropdow-panel {
    .ant-dropdown-menu-item {
        padding-left: 0px;
        padding-right: 0px;

        button {
            width: 100%;
            padding-left: @px-20;
            padding-right: @px-20;
        }
    }
}

.forbidden-container {
    .flex-column-center;
    justify-content: center;
    height: 100vh;

    & > span {
        .font-size-2px-bold(20);
        color: #034854;
        width: 43%;
        text-align: center;
        line-height: 1.3;
    }

    img {
        width: auto;
        height: 65%;
    }

    Button {
        width: @px-100;
    }
}

.reset-password-pattern {
    .padding-2px(2.5, 5);
    background: #fde5e4;
    margin-bottom: @pxh-10;

    .error {
        &.anticon {
            font-size: @font-size-16;
            color: @color-error;
        }

        &:not(.anticon) {
            font-size: @font-size-12;
            margin-left: @px-10;
        }
    }
}

.reset-password-container {
    .flex-row;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;

    .reset-password-panel {
        .padding-2px(15);
        width: @px-100 * 4.5;
        border: 1px solid #999999;
        border-radius: 5px;
        box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 60%);

        .title {
            .font-size-2px-bold(13);
            margin-bottom: @pxh-20;
        }

        .description {
            margin-bottom: @pxh-20;
        }
    }
}

.account-360-profile {
    .padding-2px(7);
    background: @bg-white;
    border-radius: 5px;
    margin-top: @pxh-15;

    &-acc-info {
        .flex-row-between;
        margin-bottom: @pxh-15;
        border-bottom: 1px solid @main-border-color;

        .left {
            .info-container {
                padding-bottom: @pxh-15;

                .item {
                    .padding-2px(0, 7.5);
                    display: inline-block;
                    min-width: @px-100 * 1.5;
                    border-right: 1px solid @main-border-color;

                    & > span {
                        display: block;
                        font-size: @font-size-14;
                        text-align: center;
                        color: #6e6e6e;

                        &:first-child {
                            .font-size-2px-bold(10);
                            color: @main-app-color;
                        }
                    }
                }
            }
        }
        .right {
            .title-extra-panel {
                .flex-row;

                button {
                    .padding-2px(1.5, 5);
                    margin: 0;
                    margin-left: @px-10 * 1.3;
                    width: auto;
                    box-shadow: 1px 1px 4px rgb(0 0 0 / 30%);
                    border-radius: 3px;
                    background: @main-app-color;

                    &.watchlist {
                        background: @bg-white;
                        color: #c1c1c1;

                        &.selected {
                            background: @main-app-color;
                            color: @color-white;
                        }
                    }
                }
                .watch-group-name {
                    .padding-2px(2, 7.5);
                    border-radius: 3px 0px 0px 3px;
                    margin-right: @px-15 * -1;
                    background: #f6f6f6;
                    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.35);

                    .name {
                        .font-size-2px-bold(7);
                        text-align: right;
                    }

                    &.view-only {
                        margin-right: 0px !important;
                        border-radius: 3px !important;
                    }
                }
            }
        }
    }

    &-navs {
        .flex-row-between;
        margin-bottom: @pxh-15 !important;

        .left {
            .ant-segmented {
                border-radius: 5px;

                .ant-segmented-item {
                    border-radius: 5px !important;
                    min-width: @px-100 * 1.2;
                }
            }
        }
    }

    &-content {
        width: 100%;
        padding: 0;
        padding-bottom: @pxh-20;

        & > .content {
            .flex-row;

            .major-content {
                .flex-row;
                width: 100%;

                .main-panel {
                    width: 70%;

                    // .account-main-info {
                    //     .flex-row;
                    //     justify-content: space-around;
                    //     padding-top: @pxh-15;
                    //     padding-bottom: @pxh-15;
                    //     background: @main-app-color;
                    //     box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
                    //     border-radius: 0 0 12px 12px;

                    //     & > div {
                    //         display: inline;
                    //         text-align: center;

                    //         span {
                    //             display: block;
                    //             font-size: 1.1vw;
                    //             font-weight: bold;
                    //             color: @color-white;
                    //             line-height: 1.8vw;

                    //             &.title {
                    //                 font-size: 0.9vw;
                    //                 font-weight: normal;
                    //                 color: #c8c8c8;
                    //             }
                    //         }
                    //     }
                    // }
                }

                .sub-panel {
                    width: 30%;
                    padding-left: @px-20;
                    margin-left: @px-20;
                    border-left: 1px solid @main-border-color;

                    .additional-info-container {
                        .padding-2px(10, 12.5);
                        margin-bottom: @pxh-15;
                        background: @bg-white;
                        box-shadow: 1px 2px 8px rgb(0 0 0 / 25%);
                        border-radius: 5px;

                        .remarks-container {
                            border-bottom: 1px solid @main-border-color;
                            margin-bottom: @pxh-10;
                        }
                        .remarks-label {
                            .padding-2px(3, 0);
                            color: #8e8e8e;
                            font-size: @font-size-18;
                        }

                        .middle-div {
                            border-bottom: 1px solid @main-border-color;
                            padding-top: @pxh-5;
                            padding-bottom: @pxh-5;
                            margin-bottom: @pxh-10;
                        }

                        .title {
                            .font-size-2px-bold(9);
                            margin-left: @px-2 * 3;
                        }

                        & > div > .ant-row {
                            .padding-2px(2.5, 0);
                        }

                        .flex-column {
                            &.bordered-left {
                                border-left: 1px solid @main-border-color;
                                padding-left: @px-15;
                            }

                            &.bordered-bottom {
                                border-bottom: 1px solid @main-border-color;
                                padding-bottom: @pxh-5;
                                margin-bottom: @pxh-10;
                            }

                            p {
                                font-size: @font-size-18;
                                margin-bottom: 0px !important;

                                &.subtitle {
                                    font-size: @font-size-14;
                                    color: @subtitle-color;
                                }
                            }
                        }
                    }
                }

                .tagging-container,
                .historical-trades-container {
                    width: 100%;

                    .inner-container {
                        background: @bg-white;
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

                        .tagging-event-comment-col {
                            .event-line {
                                font-size: @font-size-12;
                            }
                            .comment-line > span {
                                font-size: @font-size-12;
                            }
                        }
                    }
                }
            }

            //     .navigations {
            //         width: 170px;
            //         text-align: right;
            //         margin-right: -25px;

            //         .btns-nav {
            //             width: 130px;
            //             height: 52px;
            //             box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
            //             border-radius: 3px;
            //             font-size: @font-size-14;
            //             font-weight: bold;
            //             background: @main-border-color;
            //             color: #000000;
            //             margin-bottom: 10px;
            //             border: none;
            //             white-space: break-spaces;
            //             line-height: 1.2;

            //             &.active {
            //                 width: 150px;
            //                 background: @main-app-color;
            //                 color: @color-white;
            //             }
            //         }
            //     }
        }
    }
}

.chart-panel {
    .flex-row-between;
    .main-bg-white;
    .padding-2px(9);
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    .c-left {
        .flex-column;
        width: calc(100% - (@px-100 * 2.5));

        &.no-rightExtra {
            width: 100%;
        }

        .title {
            .flex-row-between;
            margin-top: @pxh-25 * -1;

            .title-left-panel {
                .flex-row;

                .icon-panel {
                    .flex-row;
                    width: @px-10 * 5;
                    height: @pxh-10 * 5;
                    background: @main-app-color;
                    border-radius: 8px;
                    color: @color-white;
                    font-size: @font-size-30;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 1px 2px 2px rgb(0 0 0 / 40%);
                }

                .title-desc-panel {
                    .padding-2px(6.5);
                    padding-bottom: 0;
                    margin-top: @pxh-2;

                    span {
                        display: block;

                        &.title-text {
                            .font-size-2px-bold(8);
                        }
                        &.title-desc {
                            font-size: @font-size-14;
                            color: @subtitle-color;
                        }
                    }
                }
            }

            .titleExtra {
                padding-top: @pxh-15;
                margin-top: @pxh-15;
            }
        }

        & > .content {
            .padding-2px(0, 10);
            display: block;

            .pie-chart {
                .padding-2px(0, 5);
            }

            .chart {
                margin-top: @pxh-20;
            }
        }
    }

    .c-right {
        .flex-column;
        border-left: 1px solid @main-border-color;
        justify-content: space-between;

        .main-content {
            & > div {
                .flex-column;
                width: @px-100 * 2.5;

                .desc-tags {
                    .tag-div {
                        .padding-2px(4.5, 9);
                        display: inline;
                        float: right;
                        background: #f6f6f6;
                        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.35);
                        border-radius: 5px 0px 0px 5px;
                        margin-right: @px-10 * -1.9;

                        span {
                            font-size: @font-size-16;
                            line-height: 1;
                        }
                    }
                }

                .content {
                    padding-top: @pxh-20;
                    padding-bottom: @pxh-20;

                    .tag-summary-desc-panel {
                        .flex-column;
                        align-items: flex-end;
                        margin-right: @px-10 * -1.3;

                        .value-small-tag-div {
                            .flex-row-between;
                            .padding-2px(2.5, 0);
                            text-align: right;

                            .title {
                                padding-right: @px-5;
                                width: @px-100 * 1.5;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            .tagDiv {
                                span {
                                    background-color: @main-app-color;
                                    border-radius: 15px;
                                    min-width: @px-20 * 2;
                                    text-align: center;
                                    color: @color-white;
                                    border: none;
                                }
                            }
                        }

                        .title-value-div {
                            .flex-row-between;
                            .padding-2px(2.5, 5);
                            padding-left: @px-15;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .footer {
            margin-left: @px-20;
            padding-top: @pxh-10;
            border-top: 1px solid @main-border-color;
            text-align: center;
        }
    }

    &.for-print {
        box-shadow: none !important;

        .c-left {
            .icon-panel {
                .hide;
            }

            .title-desc-panel {
                .title-text {
                    font-size: @font-size-18 !important;
                    margin-top: @pxh-10 !important;
                }
            }
        }

        .c-right {
            .main-content {
                & > div {
                    width: auto !important;
                    min-width: @px-100 * 1.84;
                }
            }

            .tag-div {
                box-shadow: none !important;
            }
        }
    }

    &.aa-c {
        .c-left {
            width: calc(100% - (@px-100 * 1.84));
        }
        .c-right {
            .main-content {
                & > div {
                    width: @px-100 * 1.84;
                }
            }
        }
    }

    &.chart-Small {
        .c-left {
            width: 100%;

            .title {
                .icon-panel {
                    margin-right: @px-10;
                }
                .title-desc-panel {
                    padding-top: @pxh-10 * 1.2;

                    .title-text {
                        line-height: 1.3;
                    }
                    .title-desc {
                        font-size: @font-size-10 !important;
                    }
                }

                .titleExtra {
                    margin-top: 0px;

                    .chart-popover-icon {
                        font-size: @font-size-22;
                        color: @main-app-color;
                    }
                }
            }
            .content {
                padding: 0px;
            }
        }
    }

    &.intraday-chart-panel {
        border-radius: 5px 5px 3px 3px;
        box-shadow: 0px 1px 3px #0000006d;
        border: 1px solid @border-color-4;
        .c-left {
            .title {
                .icon-panel {
                    display: none !important;
                }
                .title-desc-panel {
                    .title-text {
                        color: @main-app-color;
                        font-size: @font-size-18 !important;
                        margin-left: -@px-15;
                    }
                }
            }
        }
    }
}

.tag-summary-desc-panel {
    .flex-column;
    align-items: flex-end;
    margin-right: @px-10 * -1.3;

    .value-small-tag-div {
        .flex-row-between;
        .padding-2px(2.5, 0);
        text-align: right;

        .title {
            padding-right: @px-5;
            width: @px-100 * 1.5;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .tagDiv {
            span {
                background-color: @main-app-color;
                border-radius: 15px;
                min-width: @px-20 * 2;
                text-align: center;
                color: @color-white;
                border: none;
            }
        }
    }

    .title-value-div {
        .flex-row-between;
        .padding-2px(2.5, 5);
        padding-left: @px-15;
        width: 100%;
    }
}

.chart-popover-small {
    .tag-summary-desc-panel {
        margin-right: 0px;

        .title-value-div {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .chart-title {
        .font-size-2px-bold(8);
        border-bottom: 1px solid @main-border-color;
        padding-bottom: @pxh-5;
        margin-bottom: @pxh-5;
    }

    .footer-div {
        .flex-row-between;
        border-top: 1px solid @main-border-color;
        margin-top: @pxh-10;
        padding-top: @pxh-5;
    }
}

.account-risk-score-container {
    box-shadow: 1px 2px 8px rgb(0 0 0 / 25%);
    border-radius: 12px;
    display: inline-block !important;

    .account-risk-score-panel {
        .flex-row-between;
        border-radius: 12px;

        .radar-panel {
            .flex-column-center;
            .padding-2px(12.5, 10);
            padding-left: 0;
            justify-content: space-between;
            width: calc(100%);

            .radar-container {
                width: 95%;
            }

            .title {
                .flex-column-center;
                margin-bottom: @pxh-20;

                .chartTitle {
                    .padding-2px(1.5, 15);
                    font-size: @font-size-12;
                    border-radius: 20px;
                    background: #f6f6f6;
                    box-shadow: inset 0px 1px 4px #00000059;
                }
                .chartDesc {
                    font-size: @font-size-14;
                    color: @subtitle-color;
                }
            }
        }

        .title-panel {
            .flex-column;
            .margin-2px(12.5, 5);
            justify-content: center;
            border-right: 1px solid #ebebeb;

            .description-panel {
                width: @px-100 * 1.85;
            }

            .type-div {
                .flex-row-between;
                text-align: center;

                .icon {
                    .flex-column-center;
                    justify-content: center;

                    .anticon {
                        font-size: @font-size-15;
                    }

                    .label {
                        font-size: @font-size-10;
                        width: @px-10 * 7;
                    }
                }

                & > div {
                    .font-size-2px-bold(7);
                    width: 50%;
                }
            }

            .value-panel {
                .title {
                    .flex-row;
                    .padding-2px(2.5, 0);
                    justify-content: center;
                    text-align: center;

                    span {
                        font-size: @font-size-13;
                    }
                }

                .type-div {
                    & > div {
                        border-right: 1px solid rgba(0, 0, 0, 0.1);

                        &:nth-child(2) {
                            border-right: none !important;
                        }
                    }
                }
            }

            .account {
                color: #ff9f40;
            }
            .brand {
                color: #4bc0c0;
            }

            &.no-brand {
                .type-div {
                    .account {
                        border-right: none;
                        width: 100%;
                    }
                    .brand {
                        .hide;
                    }
                }
            }
        }
    }

    &.for-print {
        box-shadow: none !important;

        .account-risk-score-panel {
            .chartTitle {
                box-shadow: none !important;
                border: 1px solid @main-border-color;
            }
        }
    }
}

.comments-history {
    .padding-2px(15, 12.5);
    background: @bg-white;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    overflow: auto;
    height: @pxh-100 * 5.5;

    &.for-print {
        .padding-2px(7.5, 12.5);
        box-shadow: none !important;
        height: auto !important;

        .top {
            margin-bottom: @pxh-2 * 4;
        }

        .ant-timeline-item {
            padding-bottom: @pxh-5;

            .timeline-box {
                .padding-2px(2.5, 7.5);

                .timeline-head {
                    span.anticon {
                        .hide;
                    }
                }
            }
        }
    }

    .top {
        .flex-row-between;
        margin-bottom: @pxh-30;
        margin-top: @pxh-15;
    }

    .title {
        .font-size-2px-bold(9);
    }

    .timeline-box {
        .padding-2px(4.5, 9);
        .main-border;
        border-radius: 0 5px 5px 5px;

        .timeline-head {
            span.anticon {
                margin-right: @px-10 * -1;
                font-size: @font-size-12;
            }
        }
    }

    .timeline-head {
        .flex-row-between;
    }

    .timeline-title {
        font-size: @font-size-14;
        margin-bottom: @pxh-10 * 0.7;
    }

    .timeline-description {
        font-size: @font-size-11;
        color: #474747;
    }
}

.delete-modal-comment {
    .delete-modal-comment-box {
        .padding-2px(4.5, 9);
        .main-border;
        border-radius: 0 5px 5px 5px;
    }
    .delete-modal-comment-title {
        font-size: @font-size-14;
        margin-bottom: @pxh-10 * 0.7;
        overflow-wrap: break-word;
    }
    .delete-modal-comment-description {
        font-size: @font-size-11;
        color: #474747;
    }
}

.risk-score-view-container {
    .risk-score-items-panel {
        .metric-list-container {
            .margin-2px(12.5);
            .main-bg-white;

            .ant-list-item {
                .flex-row;
                align-items: flex-start;
                padding-top: @pxh-20;
            }

            .metric-panel {
                width: 33%;

                .title {
                    .font-bold;
                }

                .subtitle {
                    color: @subtitle-color;
                }
            }

            .metric-score-panel {
                .flex-row;
                width: 67%;

                .metric-score {
                    .flex-column;
                    justify-content: flex-start;
                    align-items: end;

                    span {
                        .font-size-2px-bold(14);

                        &.label {
                            font-size: @font-size-11;
                            font-weight: normal;
                        }
                    }
                }

                .tier-panel {
                    border-left: 1px solid @main-border-color;
                    margin-left: @px-20;
                    padding-left: @px-20;
                    padding-top: @pxh-15;
                    width: 100%;

                    .tier-item {
                        width: 100%;
                        display: block;

                        .dot {
                            .font-size-2px-bold(10);
                            line-height: 1;
                        }

                        .score {
                            float: right;
                        }
                    }
                }
            }
        }
    }
}

.create-risk-score-panel {
    border-right: 1px solid #0000000f;
    padding-right: @px-10;

    .ant-table-tbody {
        .ant-table-expanded-row {
            & > .ant-table-cell {
                background: @bg-white;
            }
        }
    }

    .link-clickable {
        span {
            color: @color-link;
        }
    }

    .metric-outer-container {
        .flexi-datatable {
            .flexi-table {
                .ant-table.ant-table-small.ant-table-layout-fixed.ant-table-fixed-column {
                    .margin-2px(0);
                }
            }
        }
    }
}

.tier-panel {
    padding-left: @px-10;

    .tier-list {
        .main-border;

        .tier-list-title {
            .flex-row-between;
            .padding-2px(5, 7.5);

            align-items: center;
            background: #fafafa;

            h1 {
                margin-bottom: 0;
            }
        }

        .list-item-div {
            border-top: 1px solid @main-border-color;

            .list-item-style {
                .flex-row-between;
                .padding-2px(5, 7.5);
                align-content: center;
                border-bottom: 1px solid @main-border-color;

                &:last-child {
                    border-bottom: none;
                }

                .left-item {
                    .flex-row-between;
                    width: 100%;

                    .expression-div {
                        width: 70%;
                    }
                    .point-div {
                        width: 30%;
                        padding-left: @px-20;
                        border-left: 1px solid @main-border-color;
                    }
                }

                span {
                    .flex-column-center;
                }
            }
        }
    }
}

.empty-list-item {
    .padding-2px(15);
}

.rsp-error-desc-div {
    margin-bottom: @pxh-25 !important;

    .rsp-error-desc {
        margin-bottom: 0;
        padding-left: @px-25;
    }
}

.rsp-mt-info {
    .flex-row-between;
    align-items: center;

    .rsp-mt-icon {
        color: @color-error;
    }
}

.versioning-panel {
    .hide;
    position: fixed;
    bottom: 0;
    left: @px-20;
    color: @color-white;
}

// #region Component - comment - titlebox
.score-card-box-container {
    border: 1px solid #dedede !important;

    .ant-card-head {
        padding: 0px;
        background: @bg-white;

        .ant-card-head-title {
            padding: 0px;

            .header-panel {
                .flex-row-between;
                overflow: hidden;
                //height: @pxh-100 * 0.7;

                .score-panel {
                    .flex-column-center;
                    justify-content: center;
                    position: relative;
                    top: @pxh-100 * -2.15;
                    left: @px-10 * 0.8;
                    width: @px-100 * 0.7;
                    height: @pxh-100 * 5;
                    background: @main-app-color;
                    color: @color-white;
                    transform: rotate(50deg);
                    box-shadow: -1px 0px 2px rgb(0 0 0 / 40%);

                    & > div {
                        transform: rotate(-50deg);

                        & > span {
                            .font-size-2px-bold(18);
                            text-shadow: 1px 1px 2px black;

                            &.text {
                                font-size: @font-size-14;
                                font-weight: normal;
                                margin-right: @px-15;
                            }
                        }
                    }
                }

                .info {
                    .flex-column;
                    .padding-2px(5, 15);

                    .title {
                        .font-size-2px-bold(8);
                    }

                    .subtitle {
                        font-size: @font-size-14;
                        color: @subtitle-color;
                    }
                }
            }
        }
    }

    .content {
        background: #fafafa;
        width: 100%;
        box-shadow: inset 0px 2px 2px rgb(0 0 0 / 15%);
    }
}
// #endregion

.account-summary-panel {
    box-sizing: border-box;
    // margin-top: 10%;

    .top {
        .flex-row-between;
    }

    &.for-print {
        .padding-2px(7.5, 12.5);
        margin-top: 0px !important;

        .title {
            margin-bottom: @pxh-5;
        }

        .metric-items-container {
            display: grid;
            grid-template-columns: repeat(4, 25%) !important;

            .metric-item {
                &:nth-child(odd) {
                    margin-left: 1% !important;
                }
                &:nth-child(even) {
                    margin-left: 1% !important;
                }
            }
        }

        .summary-icon {
            border-radius: 0px !important;
            box-shadow: none !important;
        }

        .metric-item {
            border-radius: 0px !important;
        }
    }

    &.mt-10 {
        margin-top: 10%;
    }

    .title {
        .font-size-2px-bold(9);
    }

    .metric-items-container {
        display: grid;
        grid-template-columns: repeat(2, 50%);

        .metric-item {
            .flex-row;
            border: 1px solid #d6d6d6;
            border-radius: 5px;
            width: 98%;
            min-height: @pxh-50;
            margin: 2% 1%;

            &:nth-child(odd) {
                margin-left: 0;
                margin-right: 2%;
            }

            &:nth-child(even) {
                margin-right: 0;
                margin-left: 2%;
            }

            .summary-icon {
                .flex-row;
                justify-content: center;
                align-items: center;
                background-color: @main-app-color;
                box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.4);
                border-radius: 5px 0px 0px 5px;
                align-items: center;
                min-width: @px-50;

                span {
                    .font-size-2px-bold(8.5);
                    color: @color-white;
                }
            }

            .summary-info {
                .flex-column;
                justify-content: center;
                padding-left: @px-10;

                .summary-title {
                    font-size: @font-size-14;
                    color: @subtitle-color;
                    line-height: 1.5;
                    width: @px-100 * 1.1;

                    &.all-metrics-modal {
                        width: @px-100 * 1.05;
                    }
                }
                .summary-title-for-print {
                    font-size: @font-size-14;
                    color: @subtitle-color;
                    line-height: 1.5;
                }
                .summary-value {
                    .font-size-2px-bold(6.5);
                    line-height: 1.5;
                }
            }
        }

        &.pop-up-grid {
            .flex-column;

            .account-summary-group {
                display: block;
                grid-template-columns: none;
                margin-bottom: @pxh-20;

                &:last-child {
                    margin-bottom: 0px;
                }

                .group-title-container {
                    margin-bottom: @pxh-15;

                    span {
                        .font-size-2px-bold(8);
                        display: inline-block;
                        padding-bottom: @pxh-10;
                        border-bottom: 5px solid @main-app-color;
                    }
                }
                .metric-container {
                    display: grid;
                    grid-template-columns: repeat(4, 25%) !important;

                    .metric-item {
                        &:nth-child(odd) {
                            margin-left: 1% !important;
                        }
                        &:nth-child(even) {
                            margin-left: 1% !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1520px) {
    .summary-info {
        .summary-title {
            width: @px-100 * 1.02 !important;
        }
    }
}

.empty-container {
    &-2 {
        .flex-column;
        .margin-2px(5);
        justify-content: center;
    }
    &-3 {
        .flex-column;
        .margin-2px-important(2.5, 0);
        justify-content: center;
    }
}

.brand-risk-score-form-container {
    .main-row {
        margin-bottom: @pxh-20;

        & > .ant-col {
            &:nth-child(1) {
                padding-right: @px-20;
            }
            &:nth-child(2) {
                border-left: 1px solid @main-border-color;
                padding-left: @px-20;
            }
        }

        .selected-metric-title {
            margin-bottom: @pxh-30;
            padding-bottom: @pxh-10;
            padding-top: @pxh-10;
            border-bottom: 1px solid @border-color-2;

            span {
                .font-size-2px-bold(8);
            }
        }

        .selected-metric-panel {
            display: flex;
            flex-wrap: wrap;

            .metric-item {
                width: 49%;

                .ant-col.ant-form-item-label {
                    width: @px-100 * 2.15;
                }
            }
        }
    }
}

.advance-analysis-container {
    .padding-2px(10, 2.5);
    width: 100%;

    & > div {
        margin-bottom: @pxh-30;
    }
}

.export-excel-container {
    .flex-column-center;
    background: @main-border-color;
    min-height: 100vh;

    .tools-panel {
        .padding-2px(6, 0);
        width: @px-100 * 11.4;
        text-align: right;
        position: fixed;
        top: 0;
        background: @main-border-color;
        z-index: 2;
    }

    .print-content {
        .padding-2px(10);
        width: @px-100 * 11.4;
        background: @bg-white;
        margin-top: @pxh-30 * 2;

        .print-ele {
            .main-border;
            margin-bottom: @pxh-20;
        }
    }

    .summary-panel {
        .padding-2px(10);

        .title {
            .font-size-2px-bold(10);
            padding-bottom: @pxh-20;
            margin-bottom: @pxh-20;
            border-bottom: 1px solid @main-border-color;
        }
    }
}

.print-account-overview-container {
    .padding-2px(5);
    background: @main-app-color;
    text-align: center;

    &.no-markup {
        background: transparent !important;
        padding: 0px !important;
    }

    & > div {
        span {
            display: block;
            min-width: @px-100 * 2.6;
            text-align: center;
            color: @color-white;

            &:nth-child(even) {
                .font-size-2px-bold(9);
            }
        }
    }

    &.version-2 {
        & > div {
            display: inline-block;
            margin-bottom: @pxh-10;
        }
    }
}

.print-additional-info-container {
    .padding-2px(10, 12.5);

    .title {
        .font-size-2px-bold(9);
    }

    .left-section {
        padding-right: @px-15;

        &.watchlisted {
            border-right: 1px solid @main-border-color;
        }
    }

    .right-section {
        padding-left: @px-15;
        padding-top: @pxh-5;
    }

    .middle-div {
        border-bottom: 1px solid @main-border-color;
        padding-bottom: @pxh-5;
        margin-bottom: @pxh-10;
        margin-top: @pxh-5;
    }

    & > div > .ant-row {
        .padding-2px(2.5, 0);
    }

    .flex-column {
        &.bordered-left {
            border-left: 1px solid @main-border-color;
            padding-left: @px-15;
        }

        &.bordered-bottom {
            border-bottom: 1px solid @main-border-color;
            padding-bottom: @pxh-5;
            margin-bottom: @pxh-10;
        }

        span {
            font-size: @font-size-14;

            &.subtitle {
                color: @subtitle-color;
            }
        }
    }
}

.uploaded-panel {
    .flex-column-center;
    text-align: center;

    .temp-image-panel {
        width: @px-100 * 4.6;
        height: @pxh-100 * 3;

        .image-panel {
            .flex-column-center;
            width: 100%;
            height: @pxh-100 * 3;
            justify-content: center;
            margin-bottom: @pxh-10;

            .img-clss {
                max-width: @px-100 * 4.6;
                max-height: @pxh-100 * 3;
                width: @px-100 * 4.6;
                height: auto;
            }
        }

        .remove-overlay {
            .flex-column-center;
            width: 100%;
            height: @pxh-100 * 3;
            background: @bg-black;
            top: @pxh-100 * -3.1;
            position: relative;
            z-index: 10;
            white-space: nowrap;
            opacity: 0;
            transition: all 0.3s;
            justify-content: center;

            &:hover {
                opacity: 1;
            }

            .btn-remove {
                font-size: @font-size-32;
            }
        }
    }
}

.attachment-container {
    .flex-row-between;

    .attachments-item-panel {
        .padding-2px(10);

        .image-panel {
            background: #f1f1f1;
        }
        .desc-panel {
            padding-top: @pxh-10;
        }
    }

    .remove-panel {
        .flex-column;
        position: absolute;
        right: @px-20;
        margin-top: @pxh-15;

        & > button {
            .padding-2px(2.5);
            width: @px-100 * 0.6;
            height: @pxh-50 * 0.8;
            margin-bottom: @pxh-15;
            opacity: 0;
        }

        .btn-remove {
            background: #cd0d00;
        }

        .btn-edit {
            background: @main-app-color;
        }
    }

    &:hover {
        .remove-panel {
            & > button {
                opacity: 1;
            }
        }
    }
}

.historical-trades-container {
    width: 100%;
}

.monitor-panel-container {
    width: 100%;
    margin-top: @pxh-20;

    .rules-container {
        margin-top: @pxh-25;

        .nav-container {
            .flex-row-between;

            .nav-segmented {
                margin-left: 0px !important;
                margin-right: 0px !important;
                margin-bottom: @pxh-2 !important;
            }
        }

        .event-rule-header {
            .flex-row-between;

            & > button {
                margin-left: @px-10;
            }
        }
    }

    .custom-breadcrum-container {
        .flex-row;
        .padding-2px(2.5, 5);
        .margin-2px(4.5, 0);
        .main-border-2;
        margin-bottom: @pxh-10;
        border-radius: 5px;
        background: #f4f4f4;

        & > span {
            margin-right: @px-10;
        }

        span {
            .font-bold;

            &.ant-breadcrumb-link {
                color: @main-app-color;
            }
        }
    }

    .selected-time-container {
        font-size: @font-size-16;
        margin-bottom: @pxh-20;

        span {
            .padding-2px(2.5, 10);
            .main-border-2;
            border-radius: 5px;
            background: @bg-white;
        }
    }

    .selected-account-container {
        .main-border-2;
        .font-size-2px(8);
        .padding-2px(2.5, 10);
        margin-bottom: @pxh-20;
        color: @subtitle-color;
        border-radius: 5px;
        background: @bg-white;

        span {
            margin-left: @px-100 * 0.7;
            margin-right: @px-5;

            &:first-child {
                margin-left: 0px;
            }

            &.title {
                .font-bold;
                color: @color-black;
            }
        }
    }
}

.chart-main-div {
    .padding-2px(10);
    padding-top: 0;
}

.main-title-row-1 {
    padding-bottom: @px-50 * 1.5;
}

.chart-title-container {
    .flex-row;
    .padding-2px(4.5, 10);
    background: #f4f4f4;
    color: @color-black;
    border-radius: 0px 0px 5px 5px;
    //margin-top: @pxh-20 * -1;
    margin-bottom: @pxh-5;
    box-shadow: inset 0px -1px 2px rgb(0 0 0 / 40%);

    .title {
        .font-size-2px-bold(8);
        border-right: 1px solid @border-color-2;
        padding-right: @px-20 !important;
        margin-right: @px-20 !important;
    }
}

.chart-threshold-container {
    .flex-row;
    flex-flow: wrap;

    .chart-threshold-item {
        .main-border-2;
        .padding-2px(0, 5);
        margin-right: @px-5;
        border-radius: 4px;

        & > span:nth-child(2) {
            padding-left: @px-10;
        }
    }
}

.chart-threshold-item {
    .flex-row-between;
    font-size: @font-size-12;
    align-items: center;
    background: transparent;
}

.threshold-panel {
    .padding-2px(0);
    padding-top: @pxh-10;

    .chart-threshold-container {
        display: inline-block !important;

        .chart-threshold-item {
            float: left;
            margin-bottom: @pxh-5;
        }
    }
}

.historical-account-level-container {
    .padding-2px(7.5, 12.5);

    .ant-breadcrumb {
        margin-bottom: @pxh-30;
    }

    .event-desc-container {
        .main-border;
        .margin-2px(5, 0);
        .padding-2px(5);
        color: @color-black;
        background: #fafafa;

        span {
            .font-bold;
        }

        .captured-desc {
            color: @main-app-color;
        }
    }
}

.historical-event-summary-account-info {
    color: #757575;

    span {
        &.title {
            .font-bold;
            color: @color-black;
            margin-left: @px-30 * 2;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.tiles {
    .padding-2px(10);
    padding-top: 0;
    background: @bg-white;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);

    .title-container {
        .flex-column;
        //.padding-2px(10);
        justify-content: flex-start;

        .title {
            .font-size-2px-bold(8);
        }

        .subtitle {
            color: @subtitle-color;
        }

        &.table-view {
            .title {
                font-size: @font-size-16;
            }
            .subtitle {
                font-size: @font-size-14;
                font-weight: 500;
            }
        }
    }
}

.dashboard-container {
    .content-card {
        .padding-2px(10);
        padding-top: 0;
        min-height: 50vh;

        .timer {
            .flex-row;
            justify-content: flex-end;
            margin-bottom: @pxh-20;
        }

        .title {
            .font-size-2px-bold(9);
        }

        .desc {
            color: @subtitle-color;
            margin-left: @px-20;
        }
    }

    .header-title {
        .font-size-2px-bold(9);
        color: @main-app-color;
        margin-bottom: @pxh-10;
    }

    .main-content {
        .flex-row;
        min-height: 70vh;

        .left {
            width: calc(100% - (@px-100 * 4.2));
            margin-right: @px-20;

            .monitor-panel-container {
                margin-top: 0;
            }
        }
        .right {
            width: @px-100 * 4;
        }
    }
}

.holiday-notice-container {
    background: @bg-white;
    box-shadow: 1px 2px 8px rgb(0 0 0 / 25%);
    border-radius: @px-5;
    padding: @pxh-20 @px-25;
    margin-bottom: @pxh-15;
    min-height: 70vh;

    .title-div {
        .font-size-2px-bold(8);
        margin-bottom: @pxh-20;
    }

    .search-panel {
        margin-bottom: @pxh-10;
    }

    .item-container {
        max-height: 60vh;
        overflow-y: auto;
        margin-right: @px-20 * -1;
        padding-right: @px-2 * 4;

        .item {
            margin-bottom: @pxh-2 * 4;

            .title {
                .flex-row;
                align-items: baseline;

                span.desc {
                    .font-size-2px-bold(8);
                    color: @main-app-color;
                    margin-bottom: @pxh-2 * 4;
                    margin-right: @px-10;
                }

                .ant-badge {
                    margin-bottom: 0px;
                }
            }
            .sub-item {
                .flex-row-between;
                .padding-2px(1.5, 0);
                font-size: @font-size-14;

                & > span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                & > span:nth-child(1) {
                    width: @px-100;
                    margin-right: @px-20;
                    border-right: 1px solid @main-border-color;
                }
                & > span:nth-child(2) {
                    .font-bold;
                    width: @px-100 * 1.2;
                }
                & > span:nth-child(3) {
                    width: calc(100% - (@px-100 * 2.2));
                    text-align: right;
                }
            }
        }
    }
}

.activity-logs-container {
    .filter-form-div {
        .padding-2px(10);
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;

        .filter-form-buttons {
            .padding-2px(8, 0);
            padding-bottom: 0;
            margin-top: @pxh-2 * 9;
            border-top: 1px solid #d9d9d9;
        }
    }
    .activity-list-component {
        .padding-2px(5);
        margin: 0;
        margin-top: @pxh-20;
        margin-right: @px-30;
    }
    .activity-list-item {
        border-bottom: none !important;
        margin-left: @px-25 !important;
        margin-right: @px-25 !important;
    }
    .item-title {
        margin-bottom: @pxh-2 * 1.5;
        span {
            .font-bold;
        }
    }
    .item-date {
        margin-bottom: @pxh-10;
        color: #bcbcbc;
        margin-left: @px-30;
    }
    .item-log {
        .main-border;
        .padding-2px(5, 10);
        .margin-2px(0, 15);
        border-radius: 5px;
        background: @bg-white;
        word-wrap: break-word;

        .item-log-name {
            .font-bold;
        }
        .before {
            color: #808080;
        }
    }
}

.monitor-profile-create-div {
    margin-bottom: @pxh-20;

    .event-rule-container,
    .data-visualization-container {
        border: 1px solid #d0d0d0;

        .title-div {
            .flex-row-between;
            .padding-2px(5, 7.5);
            border-bottom: 1px solid #d0d0d0;
        }

        .list-item-div {
            min-height: @pxh-100 * 2.75;

            .item-container {
                .flex-row-between;
                .padding-2px(5, 7.5);
                align-content: center;
                border-bottom: 1px solid @main-border-color;

                .left-panel {
                    .flex-row;
                    justify-content: center;
                    padding-right: @px-10;

                    .icon-panel {
                        font-size: @font-size-26;
                        padding-right: @px-10;
                    }
                    .title {
                        .font-size-2px-bold(8);
                    }
                    .subtitle {
                        font-size: @font-size-14;
                        color: @subtitle-color;
                    }
                    .config-row {
                        .flex-row;
                    }
                    .interval {
                        & > span.text {
                            font-size: @font-size-12;
                            margin-left: @px-10;
                        }
                    }
                    .notification-channel {
                        margin-left: @px-15;
                        .text {
                            font-size: @font-size-12;
                            margin-left: @px-5;
                        }
                    }
                }

                .right-panel {
                    .flex-row;
                    justify-content: center;

                    .status-desc {
                        .flex-column;
                        justify-content: center;
                        text-align: center;
                        width: @px-100 * 0.7;
                        margin-right: @px-30;

                        span {
                            .padding-2px(0, 5);
                            background: @bg-black !important;
                            color: @color-white;
                            border-radius: 20px;

                            &.active {
                                background: @main-app-color !important;
                            }
                        }
                    }
                }

                .buttons-group {
                    .flex-row;
                    align-items: center;

                    & > button:first-child {
                        border-right: 1px solid @main-border-color;
                        padding-right: @px-15;
                        margin-right: @px-10;
                    }
                }
            }
        }
    }

    .data-visualization-container {
        border: 1px solid #d0d0d0;

        .title-div {
            .flex-row-between;
            .padding-2px(5, 7.5);
            border-bottom: 1px solid #d0d0d0;
        }

        .list-item-div {
            min-height: @pxh-100 * 2.75;
        }
    }
}

.app-config-container {
    .padding-2px(10);
    padding-top: @pxh-10;
    min-height: @pxh-100 * 4;

    .config-group {
        margin-bottom: @pxh-25;

        .title-panel {
            .flex-row;
            padding-bottom: @pxh-2 * 6;
            align-items: center;

            span {
                .font-size-2px-bold(8);
                color: #034b58;
            }

            .divider {
                display: inline-block;
                margin-bottom: @pxh-2;
                border: 1px solid #e9e9e9;
            }
        }
    }

    .config-card {
        .flex-row;
        .padding-2px(7.5);
        margin-right: @px-20;
        margin-bottom: @pxh-20;
        border-radius: 5px;
        background: @bg-white;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 40%);
        cursor: pointer;

        .icon-panel {
            .flex-row;
            align-items: center;
            border-right: 1px solid @main-border-color;
            padding-right: @px-15;

            span {
                &.anticon {
                    font-size: @font-size-36;
                    color: @main-app-color;
                }
            }
        }
        .description-panel {
            .flex-column;
            justify-content: center;
            padding-left: @px-15;

            .title {
                .font-size-2px-bold(8);
                color: @main-app-color;
            }

            .subtitle {
                color: @subtitle-color;
            }
        }
    }
}

.board-container {
    display: flex;
    overflow: auto;

    .group-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .group-inner-container {
            .main-border;
            display: inline-block;
            width: @px-100 * 3;
            min-height: @pxh-100 * 5;
            //margin-right: @px-15;
            //margin-bottom: @pxh-15;
            background: @bg-white;

            .group-title {
                .flex-row-between;
                .padding-2px(4, 7.5);
                align-items: center;
                border-bottom: 1px solid @main-border-color;
            }
        }
    }
    .header-container {
        text-align: right;
    }
}

.multi-col-dnd-outer-container {
    width: 100%;

    .group-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
}

.box-shadow-light {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

//#region metric category
.metric-group-dragging-container {
    .main-border;

    .title-div {
        .padding-2px(4, 7.5);
        .font-size-2px-bold(8);
        border-bottom: 1px solid @main-border-color;
    }
    .items-div {
        .padding-2px(0.5);
        background: #fafafa;

        .sort-item {
            .padding-2px(5, 4);
            border-bottom: 1px solid @main-border-color;
            background: @bg-white;

            span {
                margin-right: @px-10;
            }
        }

        & > div:last-child {
            .sort-item {
                border-bottom: none;
            }
        }
    }
}
//#endregion

//#region metric mapping
.metric-mapping-container {
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;

    .group-container {
        .main-border;
        display: inline-block;
        vertical-align: top;
        margin-right: @px-20;
        margin-bottom: @pxh-20;
        min-width: @px-100 * 3;
        width: 23%;

        .title {
            .padding-2px(4, 7.5);
            .font-size-2px-bold(8);
            .flex-row-between;
            border-bottom: 1px solid @main-border-color;
        }
        .content {
            .padding-2px(0, 7.5);
            min-height: @pxh-100 * 3;
            max-height: 80vh;
            overflow: auto;

            .item {
                .padding-2px(5, 0);
                .flex-row-between;
                border-bottom: 1px solid @main-border-color;
                align-items: center;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
//#endregion

@media screen and (max-width: 1400px) {
    .ant-table {
        font-size: @font-size-12 !important;

        & .ant-descriptions-item-label,
        .ant-descriptions-item-content {
            font-size: @font-size-12 !important;
        }
    }
}

.ant-table {
    .ant-table-tbody {
        .ant-table-column-sort {
            background: transparent;
        }
    }
}

//#region Account 360 - summary metric items in modal
.metric-items-container-modal {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: @pxh-20;

    &.for-print {
        .title {
            margin-bottom: @pxh-5;
        }

        .metric-item-modal {
            border: 1px solid #d6d6d6;
            min-height: @pxh-10 * 3.5;
            margin: 2% 1% !important;

            &:nth-child(odd) {
                margin-left: 1% !important;
            }
            &:nth-child(even) {
                margin-left: 1% !important;
            }

            .summary-icon-tile {
                box-shadow: none !important;
                border-radius: 0px !important;
                min-width: @px-50 !important;
            }
        }
    }

    .metric-item-modal {
        .flex-row;
        width: 98%;
        min-height: @pxh-20;
        margin: 3% 1%;

        &:nth-child(odd) {
            margin-left: 0;
            margin-right: 2%;
        }

        &:nth-child(even) {
            margin-right: 0;
            margin-left: 2%;
        }

        .summary-icon-tile {
            .flex-row;
            background-color: @main-app-color;
            box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.4);
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            min-width: @px-10 * 4.3;

            span {
                .font-size-2px-bold(9);
                color: @color-white;
            }
        }

        .summary-info-modal {
            .flex-column;
            justify-content: center;
            padding-left: @px-10;

            .summary-title-modal {
                font-size: @font-size-14;
                color: @subtitle-color;
                line-height: 1.5;
                width: @px-100 * 1.35;

                &.all-metrics-modal {
                    width: @px-100 * 1.05;
                }
            }
            .summary-title-for-print {
                font-size: @font-size-14;
                color: @subtitle-color;
                line-height: 1.5;
            }
            .summary-value-modal {
                .font-size-2px-bold(6.5);
                line-height: 1.5;
            }
        }
    }
}
//#endregion

// #region client 360 - START
.client-profile-container {
    .flex-row-between;

    .left-panel {
        width: 35%;
        margin-right: @px-20;

        .card-box-container {
            .inner-container {
                .card-box-content {
                    .padding-2px(10, 15);

                    .profile-title {
                        .padding-2px(2.5, 0);
                        color: @subtitle-color;
                        font-size: @font-size-16;
                    }
                    .profile-desc {
                        .padding-2px(2.5, 0);
                        color: @color-black;
                        font-size: @font-size-16;
                    }
                    .profile-bigTitle {
                        .font-size-2px-bold(9);
                        margin-bottom: 0;
                    }
                    .box-header {
                        .flex-row-between;
                        margin-top: @pxh-10 * 5.5;
                    }
                }
            }
        }
        .ant-divider-horizontal {
            .margin-2px(5, 0);
        }

        .client-comments-history-search-panel {
            .margin-2px(7.5, -5);
            margin-top: 0;
            margin-right: 0;
        }

        .client-comments-history {
            overflow: auto;
            min-height: @pxh-100 * 2;

            .list-box {
                .main-border;
                .padding-2px(5, 7.5);
                border-radius: 12px;
                min-width: 98%;
            }

            .list-top-small {
                .font-size-2px-bold(5.5);
                color: @subtitle-color;
            }

            .list-comment {
                .flex-row-between;

                span.anticon {
                    margin-right: @px-10 * -1;
                    font-size: @font-size-12;
                }
            }

            .list-title {
                .margin-2px(2.5, 0);
                font-size: @font-size-14;
                color: @color-black;
            }

            .list-description {
                font-size: @font-size-11;
                color: @subtitle-color;
            }

            .ant-list-split .ant-list-item {
                .padding-2px(2.5, 0);
                border-bottom: none;
            }
        }
    }

    .right-panel {
        width: 65%;

        .ant-list-item {
            .padding-2px(5, 12);
        }
        .item-container {
            .flex-row-between;
            .margin-2px(-2.5, 0);
            align-content: center;

            .item {
                .flex-row;
                justify-content: center;
                align-items: center;
                padding-right: @px-10;
                padding-bottom: 0;

                .title {
                    .padding-2px(0);
                    padding-right: @px-10 * 1.6;
                    color: @subtitle-color;
                    font-size: @font-size-16;
                }
                .subtitle {
                    color: @color-black;
                    font-size: @font-size-16;
                }
            }

            .buttons-group {
                .flex-row;
                align-items: center;
            }
        }
        .ant-list-pagination {
            .margin-2px(5);
        }

        .display-mode-panel {
            margin-top: @pxh-20;
        }
        .padding-10px {
            .padding-2px(5, 0);
        }
    }
}
// #endregion client 360 - END

//#region elastic-search-container - START
.elastic-search-input {
    &.ant-input-affix-wrapper {
        width: @px-100 * 1.4 !important;
        background: transparent;
        border-radius: 5px;
        margin-left: @px-5;

        input {
            background: transparent;
        }

        &.ant-input-affix-wrapper-focused {
            width: @px-100 * 2.8 !important;
        }

        &:hover {
            width: @px-100 * 2.8 !important;
        }
    }
}

.els-account-container {
    & > div {
        .flex-row;

        span {
            font-size: @font-size-12;

            &:not(:nth-child(1)) {
                border-left: 1px solid #d7d7d7;
                margin-left: @px-10 * 0.8;
                padding-left: @px-10 * 0.8;
            }
        }
    }
}
//elastic-search-container - END

// START summary list
.summary-list {
    .metric-name {
        color: @subtitle-color;
        font-size: @font-size-12;
    }
    .total-value {
        font-size: @font-size-22;
    }
    .list-item-header {
        display: grid;
        grid-template-columns: repeat(4, @px-100 * 1.25);
        margin-left: @px-10;

        .title {
            font-weight: normal;
            font-size: @font-size-12;
            color: @subtitle-color;
        }
    }
    .ant-list-item {
        .padding-2px(5);

        .item-container {
            display: grid;
            grid-template-columns: repeat(4, @px-100 * 1.25);

            .item {
                display: block;

                .title {
                    .padding-2px(0);
                    padding-right: @px-10 * 16;
                    color: @subtitle-color;
                    font-size: @font-size-12;
                    font-weight: normal;
                }
                .subtitle {
                    color: @color-black;
                    font-size: @font-size-13;
                }
            }
        }
    }

    .ant-list-pagination {
        .margin-2px(12, 0);
    }
}
// END summary list

.top-bar-align-right {
    .flex-row;
    justify-content: flex-end;
    align-items: center;
}

//permission component - START
.permission-container {
    .main-border;
    width: 100%;

    .module-panel {
        .flex-row-between;
        width: 100%;

        .item {
            width: 80%;
        }
        .actions {
            width: 10%;
            text-align: center;
        }
    }

    .header-panel {
        .padding-2px(4, 7.5);
        border-bottom: 1px solid @main-border-color;
        background: #e2e2e2;

        .module-panel {
            .item {
                .font-bold;
                color: #9e9e9e;
            }
            .actions {
                .font-bold;
            }
        }
    }

    .content {
        .item {
            .group-panel {
                .padding-2px(2.5, 7.5);
                .font-size-2px-bold(7.5);
                border-top: 1px solid @main-border-color;
                border-bottom: 1px solid @main-border-color;
                background-color: @main-border-color;
                color: #3b3b3b;
            }

            .child-panel {
                .padding-2px(2.5, 0);

                .module-panel {
                    .padding-2px(2.5, 7.5);

                    .item {
                        .flex-row;
                        align-items: center;
                        padding-left: @px-10 * 0.8;
                    }
                    .actions {
                        .form-checkbox {
                            margin-bottom: 0px;

                            .ant-col-16.ant-form-item-control {
                                min-width: 100%;
                            }

                            .ant-checkbox-wrapper {
                                .ant-checkbox + span {
                                    padding-left: 0;
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            &:first-child {
                .group-panel {
                    border-top: none;
                }
            }
        }
    }
}
//permission component - END
//Price Outage - START
.price-outage-container,
.mid-price-outage-container {
    .top-panel {
        .flex-row-between;
        margin-top: @pxh-10;
        margin-bottom: @pxh-10;

        .left {
            width: 50%;

            & > .ant-form-item {
                margin-bottom: 0px;
            }

            .sound-mute {
                .margin-2px(0);
                .padding-2px(1.5, 5);
                width: auto;
                box-shadow: 0px 1px 2px rgb(0 0 0 / 30%);
                border: none;
                border-radius: 3px;
                background: @bg-white;
                color: #c1c1c1;

                &.active {
                    background: @main-app-color;
                    color: @color-white;
                }
            }
        }
        .right {
            width: 50%;

            & > .ant-form-item {
                margin-bottom: 0px;
            }
        }
    }
    .content-panel {
        background: @bg-white;
        border-radius: 10px;
        min-height: 70vh;

        .list-panel {
            .flex-row;

            .left {
                width: @px-100 * 1.85;
                border-right: 1px solid @main-border-color;
                padding-top: @pxh-20;
                padding-bottom: @pxh-20;
                min-height: 80vh;
                background-color: @main-border-color;

                .title {
                    .font-size-2px-bold(9);
                    display: flex;
                    align-items: center;
                    background: @main-app-color;
                    color: @color-white;
                    height: @px-25 * 2;
                    margin-left: @px-5 * -1;
                    border-radius: 3px 0px 0px 3px;
                    padding-left: @px-20;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
                }

                .list {
                    max-height: 75vh;
                    overflow: auto;

                    .list-item-container {
                        .list-items {
                            .flex-row-between;
                            align-items: center;
                            height: @px-30 * 1.2;
                            cursor: pointer;

                            .item-title {
                                padding-left: @px-15;
                            }
                            .tagDiv {
                                span {
                                    background-color: @main-app-color;
                                    border-radius: 15px;
                                    min-width: @px-20 * 2;
                                    text-align: center;
                                    color: @color-white;
                                    border: none;
                                }
                            }
                            &.active {
                                background-color: #cccccc9e;
                            }
                        }
                    }
                }
            }
            .right {
                .padding-2px(10, 7.5);
                width: calc(100% - (@px-100 * 1.85));
            }
        }
    }
    .mid-price-expandable-table {
        margin-left: @px-20 * 2;
    }

    .ant-table-row {
        &.hightlight-symbol {
            &--1 {
                background-color: transparent;
            }
            &--2 {
                background-color: #ffa500;

                &.active {
                    background: #ffc04c;
                }
            }
            &--3 {
                background-color: #e50000;
                &.active {
                    background: #ec4c4c;
                }
            }
        }
    }
}
//Price Outage - END
// start Client 360 PNL modal
.summary-metric-items-container-modal {
    display: block;
    margin-bottom: @pxh-20;

    .metric-container {
        display: grid;
        grid-template-columns: repeat(4, 25%) !important;

        .metric-item-modal {
            .flex-row;
            width: 98%;
            min-height: 5vh;
            margin: 3% 1%;

            &:nth-child(odd) {
                margin-left: 0;
                margin-right: 2%;
            }

            &:nth-child(even) {
                margin-right: 0;
                margin-left: 2%;
            }

            .summary-icon-tile {
                .flex-row;
                background-color: @main-app-color;
                box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.4);
                border-radius: 5px;
                justify-content: center;
                align-items: center;
                min-width: @px-10 * 4.3;

                span {
                    .font-size-2px-bold(9);
                    color: @color-white;
                }
            }

            .summary-info-modal {
                .flex-column;
                justify-content: center;
                padding-left: @px-10;

                .summary-title-modal {
                    .font-size-2px(7);
                    color: @subtitle-color;
                    line-height: 1.5;
                    width: @px-100 * 1.35;

                    &.all-metrics-modal {
                        width: @px-100;
                    }
                }
                .summary-title-for-print {
                    .font-size-2px(7);
                    color: @subtitle-color;
                    line-height: 1.5;
                }
                .summary-value-modal {
                    .font-size-2px-bold(6.5);
                    line-height: 1.5;
                }
            }
        }
    }
}
.detail-metric-items-container-modal {
    .ant-divider-horizontal {
        .margin-2px(7.5, 0);
    }
    .summary-list {
        .metric-name {
            color: @subtitle-color;
            font-size: @font-size-11;
        }
        .total-value {
            font-size: @font-size-23;
        }
        .list-item-header {
            display: grid;
            grid-template-columns: repeat(4, @px-100 * 1.25);
            margin-left: @px-10;

            .title {
                font-weight: normal;
                font-size: @font-size-11;
                color: @subtitle-color;
            }
        }
        .ant-list-item {
            .padding-2px(5);

            .item-container {
                display: grid;
                grid-template-columns: repeat(4, @px-100 * 1.25);

                .item {
                    display: block;

                    .title {
                        .padding-2px(0);
                        padding-right: @px-10 * 1.6;
                        color: @subtitle-color;
                        font-size: @font-size-11;
                        font-weight: normal;
                    }
                    .subtitle {
                        color: @color-black;
                        font-size: @font-size-13;
                    }
                }
            }
        }

        .ant-list-pagination {
            .margin-2px(12, 0);
        }
    }
}
// end Client 360 PNL modal

.comment-container {
    .padding-2px(2.5);

    .summary-historical-event-comments-history {
        min-height: 60vh;
        overflow: auto;

        .comment-list {
            max-height: 65vh;
        }

        .list-box {
            .main-border;
            .padding-2px(2.5, 0);
            .flex-column;
            border-radius: 0 5px 5px 5px;
            min-width: 98%;

            .list-comment {
                padding-left: @px-10 * 1.3;
                padding-right: @px-10;

                .list-title {
                    .margin-2px(2.5, 0);
                    margin-bottom: @pxh-2;
                    font-size: @font-size-12;
                    color: @color-black;
                }

                .list-description {
                    font-size: @font-size-10;
                    color: @subtitle-color;
                }

                span.anticon {
                    margin-right: @px-10 * -1;
                    font-size: @font-size-12;
                }
            }

            .icon-box {
                padding-left: @px-10 * 1.3;
                padding-top: @pxh-5;
                font-size: @font-size-11;

                .edit-button {
                    cursor: pointer;
                    color: @color-link;
                    font-size: @font-size-11;
                    margin-right: @px-30;
                }

                .delete-button {
                    cursor: pointer;
                    color: @color-error;
                    font-size: @font-size-11;
                }
            }
        }

        .ant-list-split .ant-list-item {
            .padding-2px(2.5, 0);
            border-bottom: none;
        }
    }

    .footer-button {
        text-align: right;
        margin-top: @pxh-10;

        & > button {
            margin-left: @px-10;
        }
    }
}

.mini-comment-container {
    .comments {
        .margin-2px(2.5, 0);
        margin-bottom: @pxh-2;
        font-size: @font-size-12;
        color: @color-black;
    }
    .info {
        font-size: @font-size-12;
        color: @subtitle-color;

        .user {
            display: flex;
            align-items: center;

            .user-name {
                margin-left: @px-5;
            }
        }
    }
}

.popover-time-container {
    background: @bg-white;

    .clock-panel {
        .padding-2px(5, 0);
        .flex-row;
        padding-bottom: @pxh-15;
        border-bottom: 1px solid @main-border-color;

        .left {
            .flex-column;
            width: @px-100 * 1.2;

            .name {
                .font-size-2px-bold(12.5);
                color: @main-app-color;
            }
            .desc {
                color: #ababab;
                line-height: 1.2;

                span {
                    display: inline-block;
                    width: @px-30 * 2;
                }
            }
        }
        .right {
            .flex-column;
            justify-content: center;

            span {
                .font-size-2px(25);
                display: block;
                font-family: monospace;
                line-height: 1;

                &.date-desc {
                    .font-size-2px(12);
                    text-align: center;
                    color: #ababab;
                }
            }
        }
        &:last-child {
            border-bottom: none;
        }
    }
}

.world-time-container {
    .flex-row;
    justify-content: center;

    .ant-btn {
        margin-left: @px-10;
        border-radius: 5px 0px 0px 5px;
        background: transparent;
    }

    .tinyTime {
        .flex-column-center;
        .padding-2px(0, 5);
        justify-content: center;
        border-radius: 0px 5px 5px 0px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3) inset;
    }
}

/// Menu Management -- START --
.menu-management-container {
    .flex-row-between;
    margin-bottom: @pxh-30;
    margin-top: @pxh-15;

    .left {
        width: 30%;
        padding-right: @px-15;
        margin-right: @px-15;
        border-right: 1px solid @border-color-2;

        .tree-panel {
            .main-border;
            background: @bg-white;
            min-height: 80vh;

            .title {
                .font-size-2px-bold(10);
                .padding-2px(4, 7.5);
                border-bottom: 1px solid @main-border-color;
            }
            .content {
                .padding-2px(4, 7.5);

                span.ant-tree-title {
                    font-size: @font-size-18;
                }
                .ant-tree-switcher {
                    background: transparent;
                    color: @color-black;

                    & .anticon {
                        color: @color-black;
                    }
                }

                .ant-tree-treenode-selected .ant-tree-switcher {
                    color: @color-white;
                    & .anticon {
                        color: @color-white;
                    }
                }
            }
        }
    }
    .right {
        width: 70%;
    }
}
/// Menu Management -- END --

/* start user manual */
.user-manual-config {
    .ant-list-item {
        .padding-2px(4, 24);
    }
}
.user-manual-drawer {
    .ant-drawer-body {
        .padding-2px(12, 25);

        &::-webkit-scrollbar {
            width: @px-10 * 1.3;
        }

        &::-webkit-scrollbar-thumb {
            border: @px-2 solid transparent;
            border-radius: 100px;
            background-color: @main-app-color;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-track {
            background: #cecece;
            border-radius: 30px;
        }
    }
}

.wmde-markdown {
    font-size: @font-size-14 !important;

    h1 {
        font-size: @font-size-18 !important;
    }

    h2 {
        font-size: @font-size-16 !important;
    }

    h3 {
        font-size: @font-size-14 !important;
    }
}

.user-manual-container {
    .flex-row-between;
    margin-bottom: @pxh-30;

    .left {
        flex: 6;
        margin-left: @px-20 * 2;
        margin-right: @px-20;

        .edit-button-box {
            .flex-row;
            justify-content: flex-end;
        }
    }

    .right {
        flex: 1;
        border-left: 1px solid @border-color-2;

        .user-manual-menu {
            .ant-menu-title-content {
                .flex-column;
                align-items: flex-start;
                justify-content: space-between;
            }
            .ant-menu-item {
                margin-bottom: 0;

                &::after {
                    border-right: 0 !important ;
                }
            }
            .ant-menu-item-selected,
            .ant-menu-item:hover {
                border-left: (@px-2 * 1.5) solid @main-app-color !important ;
                background-color: @main-border-color !important;
                color: @main-app-color !important ;
            }
            .ant-menu-submenu-title {
                padding-left: @px-20 !important;
            }
            .ant-menu-sub {
                .ant-menu-item {
                    padding-left: @px-30 !important;
                }
            }
        }
    }
}

/* end user manual */

.coming-soon-container {
    text-align: center;
    width: 100%;
    padding-top: @pxh-50;
    padding-bottom: @pxh-50;
}

.popconfirm-div-btn {
    width: @px-100 * 4;
}

.decay-analysis-container {
    .breadcrumb-panel {
        margin-left: @px-15;
        margin-bottom: @pxh-15;
    }

    .decay-ticket-view-container {
        span {
            .font-size-2px(8);

            &:nth-child(2n + 1) {
                margin-left: @px-20;
            }

            &:nth-child(2n) {
                margin-left: @px-10;
            }
            &:first-child {
                margin-left: 0;
            }

            &.title {
                .font-bold;
            }
        }
    }

    .decay-column-style {
        .padding-2px-important(0);

        .decay-column-container {
            .flex-column;
            align-items: right;

            span {
                .padding-2px(1.5, 2.5);
                min-height: @pxh-10 * 2.28;

                &:nth-child(1) {
                    border-bottom: 1px solid @main-border-color;
                }
                &:nth-child(2) {
                    //background: #dadada;
                    color: #0035ff;
                }
                &.highlight {
                    background-color: #ffdcdc !important;
                }
            }
        }
    }

    .decay-account-desc-container {
        .padding-2px(5);
        padding-top: 0;
        margin-bottom: @pxh-10;
    }
}

.decay-analysis-account-summary {
    .main-border;
    .padding-2px(7, 7);
    background: @bg-white;
    border-radius: 10px;

    .top-div {
        margin-bottom: @pxh-10;

        .title {
            .font-size-2px-bold(9);
            color: @main-app-color;
            margin-bottom: @pxh-10;
        }

        .content {
            .flex-row-between;
            .left-panel {
                width: calc(100% - (@px-100 * 3));
                .flex-row;
                .decay-summary-info-container {
                    .flex-column;
                    justify-content: space-between;
                    flex: 1;
                    padding-right: @px-20;

                    &:nth-child(2) {
                        flex: 1.5;
                    }
                    .info-row {
                        .flex-row-between;
                        .font-size-2px(7);
                        margin-bottom: @pxh-10;

                        .info-label {
                            color: @subtitle-color;
                        }
                    }
                }
            }
            .right-panel {
                width: @px-100 * 3;
                .chart {
                    .main-border;
                    .padding-2px(5);

                    .no-data-panel {
                        .padding-2px(1, 0);
                        text-align: center;
                        color: @subtitle-color;
                    }
                }
            }
        }
    }
}

.hft-biti-container {
    .breadcrumb-panel {
        margin-left: @px-15;
        margin-bottom: @pxh-15;
    }
}

.hft-report-container {
    padding-top: @pxh-10;

    .main-container {
        .padding-2px(5);

        .tab-body {
            padding-top: @pxh-10;

            .ant-collapse {
                .ant-collapse-header {
                    .ant-collapse-header-text {
                        font-weight: bold;
                    }
                }
                .ant-collapse-item {
                    .ant-collapse-content-box {
                        padding: 0;
                    }
                }
            }

            .hft-report-data,
            .hft-report-search {
                display: flex;
                flex-direction: column;
                gap: @px-25;
            }
        }
    }

    .flexi-datatable {
        .flexi-table {
            .ant-table-body {
                table tbody.ant-table-tbody {
                    tr {
                        &.row-red-1 {
                            td.ant-table-cell {
                                background-color: #ef4444 !important;
                                border-color: #d91818 !important;
                                color: white;
                            }
                            &:hover td.ant-table-cell,
                            td.ant-table-cell.ant-table-column-sort {
                                background-color: #ce3939 !important;
                            }
                        }
                        &.row-red-2 {
                            td.ant-table-cell {
                                background-color: #b91c1c !important;
                                border-color: #9b0404 !important;
                                color: white;
                            }
                            &:hover td.ant-table-cell,
                            td.ant-table-cell.ant-table-column-sort {
                                background-color: #9e1919 !important;
                            }
                        }
                        &.row-red-3 {
                            td.ant-table-cell {
                                background-color: #7f1d1d !important;
                                border-color: #7d0101 !important;
                                color: white;
                            }
                            &:hover td.ant-table-cell,
                            td.ant-table-cell.ant-table-column-sort {
                                background-color: #681919 !important;
                            }
                        }
                        &.row-orange {
                            td.ant-table-cell {
                                background-color: #f97316 !important;
                                border-color: #e3611d !important;
                                color: white;
                            }
                            &:hover td.ant-table-cell,
                            td.ant-table-cell.ant-table-column-sort {
                                background-color: #ec6606 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.watch-list-info {
    .title {
        line-height: 1.2;
        color: #666666;
    }
    .value {
        margin-bottom: @pxh-10 * 0.8;
        font-weight: 500;
    }
}

.ant-comment-inner {
    .padding-2px-important(0, 0);

    font-size: @font-size-12;
}

.notification-channel-container {
    .main-border-3;
    margin-bottom: @pxh-10 * 2;

    .uid-box {
        .padding-2px(0, 5);
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        display: inline-block;
        .uidText {
            color: @subtitle-color;
            font-size: @font-size-12;
        }
    }

    .title-div {
        .flex-row-between;
        .padding-2px(5, 7.5);
        background-color: #fafafa;
        border-bottom: 1px solid @border-color-3;

        h1 {
            padding-bottom: 0;
        }
    }

    .channel-list {
        .channel-item {
            .padding-2px(5, 7.5);
            border-bottom: 1px solid @border-color-2;

            .title {
                .flex-row-between;

                .left {
                    .flex-row;
                    .font-bold;
                    align-items: center;
                    margin-bottom: @pxh-5;
                    color: @subtitle-color;

                    .type-def {
                        .font-bold;
                        .main-border-3;
                        .padding-2px(0, 5);
                        margin-right: @px-10;
                        background: #e5e5e5;
                        border-radius: 3px;
                        font-size: @font-size-12;
                    }

                    .status-def {
                        .font-bold;
                        .padding-2px(0, 5);
                        margin-right: @px-10;
                        border-radius: 3px;
                        color: @color-white;
                        font-size: @font-size-12;

                        &.success {
                            background: @color-sucess;
                        }
                        &.error {
                            background: @color-error;
                        }
                    }
                }

                .broadcast-style {
                    .padding-2px(0, 5);
                    padding-left: @pxh-10 * 0.4;
                    color: @color-white;
                    margin-left: 0.651vw;
                    border-radius: 20px;
                    font-size: @font-size-12;
                    background: #00615d;
                }
            }
            .subject,
            .content {
                display: block;
                .margin-2px(5, 0.5);
            }

            .subject,
            .recipient {
                margin-bottom: @pxh-10 * 0.3;
            }
            .content {
                .main-border-2;
                .padding-2px(3, 5);
            }

            .subtitle {
                color: @subtitle-color;
            }
        }
    }
}

// #region Ticket Restoration
.ticket-restoration-div {
    &-navs {
        display: flex;
        justify-content: space-between;
        .margin-2px(0, 7);
        margin-bottom: @pxh-15;

        .ant-segmented {
            border-radius: 5px;

            .ant-segmented-item {
                border-radius: 5px !important;
                min-width: @px-100;
            }
        }
    }
    .flexi-datatable {
        .flexi-filter {
            .ant-collapse-header {
                font-size: @font-size-13 !important;
            }
        }
    }
}

.calculate-stop-out-div {
    .calculate-stop-out-div-table {
        .ant-table {
            .ant-table-content {
                .ant-table-body {
                    .nice-scrollbar;
                }
            }
        }
    }
    .export-preview-button-div {
        display: flex;
        justify-content: flex-end;
        background-color: #fafafa;
        .padding-2px(4, 8);
        border-bottom: 1px solid @main-border-color;
    }
}

.ticket-restoration-historical-tab-div {
    .affected-server-account-column {
        .flex-row;
        .left {
            width: 40%;
            padding-right: @px-5 * 2;
        }
        .right {
            width: 50%;
            border-left: 1px solid @main-border-color;
            padding-left: @px-5 * 2;
        }
        .action {
            width: 10%;
            text-align: center;
            margin: auto;
        }
        .title {
            color: #666666;
            font-size: @font-size-13;
            margin-bottom: 0;
        }
    }

    .modal-table-expanded-row {
        tr.ant-table-expanded-row > td {
            background-color: @bg-white;
            padding-top: @px-10 * 2 !important;
        }
    }
}

.ticket-restoration-container {
    min-height: 70vh;

    .list-panel {
        .flex-column;

        .tab-container {
            .ant-tabs-nav {
                margin-bottom: 0;

                .ant-tabs-tab {
                    border: 1px solid #e7e7e7 !important;
                    border-bottom: none !important;
                }
            }
        }
        .content {
            background: @bg-white;
            margin-bottom: @pxh-20;
        }

        .ticket-restoration-content-navs {
            .padding-2px(5);
            background: @bg-white;
            display: flex;
            justify-content: space-between;
        }

        .flexi-datatable {
            .flexi-filter {
                .ant-collapse-header {
                    font-size: @font-size-13 !important;
                }
            }
        }
    }
}
// #endregion

.ant-picker-ranges {
    .ant-picker-preset > .ant-tag-blue {
        background: #e5fff8 !important;
    }
}

.header-notification {
    .notification-button {
        background: transparent;
        border: 1px solid #dfdfdf;

        .notif-icon {
            font-size: @font-size-20;
            color: #434343;
        }

        &.active {
            background: @bg-white;
            border: none;

            .notif-icon {
                .padding-2px(1);
                animation: swing 0.2s infinite ease-in-out;
            }
        }
    }
}

.notification-view-all-option {
    .font-size-2px-bold(6);
    .text-center;
    color: @main-app-color;
}

.header-notification-list-container {
    .main-bg-white;
    .padding-2px(7.5);
    max-height: (@pxh-100 * 5.5) !important;
    width: (@px-100 * 3.8) !important;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 30%) !important;

    height: @pxh-100 * 4.2;
    overflow: auto;

    .main-title-panel {
        .flex-row-between;

        & > span {
            font-size: @font-size-20;
        }
    }

    .top-panel {
        .flex-row-between;
        align-items: center;

        .text {
            font-size: @font-size-12;
            color: #bababa;
        }

        .ant-btn {
            font-size: @font-size-12;
        }
    }

    .notification-item {
        .flex-row-between;
        .margin-2px(7.5, 0);

        .icon-panel {
            width: @px-10 * 3;

            & > span {
                .flex-row;
                border: 1px solid #ffcd4f;
                border-radius: 40px;
                width: @px-10 * 2;
                height: @pxh-10 * 2;
                justify-content: center;
                align-items: center;

                & > span {
                    color: #ffcd4f;
                }

                &.information {
                    border-color: #005dff;

                    & > span {
                        color: #005dff;
                    }
                }

                &.critical {
                    border-color: #f00f00;

                    & > span {
                        color: #f00f00;
                    }
                }
            }
        }

        .content {
            width: calc(100% - (@px-10 * 3));

            &-header {
                display: flex;
                justify-content: space-between;

                .title {
                    .font-size-2px-bold(7);
                }
                .status-dot {
                    height: 7px;
                    width: 7px;
                    background-color: #126e68;
                    border-radius: 50%;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
            .sub-content {
                .font-size-2px(6);
            }
            .footer-panel {
                .flex-row;
                .font-size-2px(5.5);
                align-items: center;

                .time-desc {
                    color: #a6a6a6;
                }
                .dot-style {
                    .margin-2px(0, 2);
                    display: inline-block;
                    background: #a6a6a6;
                    border-radius: 30px;
                    width: @px-2 * 1.5;
                    height: @pxh-2 * 1.5;
                }
            }
        }
    }

    .notification-item:hover {
        cursor: pointer;
        // background-color: #f4f3f3;
    }

    .ant-tabs-nav .ant-tabs-nav-list {
        .ant-tabs-tab {
            & > div {
                font-size: @font-size-16;
                color: @color-black !important;
            }
            &-active {
                background: transparent !important;

                & > div {
                    color: #00615d !important;
                }
            }
        }
    }
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    margin-right: @px-10 * 0.6;
}

.hubswitcher-historical-container {
    .details-panel {
        .flex-row-between;

        .desc {
            width: calc(100% - 32px);
            .mode {
                color: #666666;

                span {
                    font-size: @font-size-14;
                    color: @color-black;
                }
            }

            .sub-panel {
                .flex-row;
                width: 100%;

                & > div {
                    width: 50%;

                    &:nth-child(2) {
                        padding-left: @px-10;
                        border-left: 1px solid @border-color-2;
                    }

                    & > div {
                        font-size: @font-size-14;

                        &:nth-child(1) {
                            font-size: @font-size-12;
                            color: #666666;
                        }
                    }
                }
            }
        }
    }

    .in-progress {
        .flex-row;
        align-items: center;
        font-size: @font-size-14;
    }

    .complete {
        & > div {
            .margin-2px(0.5);
            font-size: @font-size-14;

            & > .anticon {
                margin-right: @px-5;
                font-size: @font-size-16;
            }
        }

        .status {
            .padding-2px(0.5, 5);
            padding-left: @px-10 * 0.4;
            padding-bottom: 0;
            border-radius: 30px;
            display: inline-block;
            background: #c3e6cd;
            color: #24663b;
        }
        .time-desc {
            color: #6d6d6d;
        }
        .error-panel {
            color: @color-error;
        }
    }

    .dot {
        background: #1890ff;
    }
}

.hubswitcher-modal-container {
    .ant-modal-body {
        .padding-2px(5);

        .flexi-table {
            .ant-table-tbody {
                .success {
                    .font-size-2px(7);
                    color: #52c41a;
                }

                .pending,
                .in-progress {
                    .flex-row;
                    font-size: @font-size-14;
                    align-items: center;
                }

                .in-progress .dot {
                    background: #1890ff;
                }
                .pending .dot {
                    background: #a5a5a5;
                }

                .error-panel {
                    .flex-column;

                    .error {
                        font-size: @font-size-14;
                        .anticon {
                            margin-right: @px-5;
                        }
                    }
                }

                .status-desc-panel {
                    .flex-row;
                    font-size: @font-size-14;

                    .anticon {
                        .margin-2px(0, 5);
                        color: #6d6d6d;
                    }
                }
            }
        }
    }
}

.ant-table-thead > tr > th.ant-table-cell.bonus {
    background-color: #f3854e !important;
    .text-center;
}
.ant-table-thead > tr > th.ant-table-cell.weightage {
    background-color: #32807d !important;
    color: @bg-white;
}

// #region Toxic Account
.toxic-account-div {
    .top-info-div {
        padding-right: @px-15;
        margin-bottom: @pxh-10;
        display: flex;
        justify-content: flex-end;
    }
    .flexi-datatable-title-div {
        display: flex;

        .circle {
            height: @pxh-15;
            width: @pxh-15;
            border-radius: 10px;
            .main-border;
            margin: 10px 5px 10px 10px;

            &-weightage {
                background-color: #32807d;
            }
            &-bonus {
                background-color: #f3854e;
            }
        }
        .legends {
            display: flex;

            &-text {
                margin-top: @px-5 + @px-2;
                font-size: @font-size-14;
            }
        }
    }

    .status-col {
        .flex-row-between;

        .val {
            .font-size-2px-bold(7);

            &-0 {
                color: #32807d;
            }
            &-1 {
                color: #3e87f4;
            }
            &-2 {
                color: @color-error;
            }
        }
    }

    .latest-activity-log-container {
        .flex-row-between;

        & > .anticon {
            .font-size-2px(8);
            cursor: pointer;
        }
    }
}

.toxic-account-modal-container {
    .historical-item-container {
        & > div {
            margin-bottom: @pxh-10 * 0.4;
        }
        .remarks {
            .inner-div {
                .main-border;
                .padding-2px(1, 5);
                display: inline-block;
                min-width: @px-100 * 3.5;
            }
        }
        .clients-desc {
            .latest-activity-log-client-desc {
                & > span {
                    .main-border;
                    .padding-2px(0.5, 3);
                    .font-size-2px-bold(6);
                    padding-right: @px-10;
                    display: inline-block;
                    margin-right: @px-2 * 1.5;
                    color: @color-white;
                    border-radius: 5px;
                    min-width: @px-100 * 0.6;

                    &.status {
                        &-0 {
                            background: @color-error;

                            &::before {
                                content: "\2715";
                                margin-right: @px-5;
                            }
                        }
                        &-1 {
                            background: #32807d;

                            &::before {
                                content: "\2713";
                                margin-right: @px-5;
                            }
                        }
                    }
                }
            }
        }
        .error-text-container {
            .padding-2px(1, 2.5);
            border: 1px solid #f55247;
            border-radius: 3px;
            background: #f9eae9;

            & > div {
                .font-size-2px(5.5);
                line-height: 1.2;
            }
        }
        .time-desc {
            .font-size-2px(5.5);
            color: #808080;
        }
    }
}

.latest-activity-log-client-desc {
    & > div {
        margin-bottom: @pxh-10 * 0.4;
    }
    .remarks {
        .inner-div {
            .main-border;
            .padding-2px(1, 5);
            display: inline-block;
            min-width: 18vw;
        }
    }
    .clients-desc {
        & > span {
            .main-border;
            .padding-2px(0.5, 3);
            .font-size-2px-bold(6);
            padding-right: @px-10;
            display: inline-block;
            margin-right: @px-2 * 1.5;
            color: @color-white;
            border-radius: 5px;
            min-width: @px-100 * 0.6;

            &.status {
                &-0 {
                    background: @color-error;

                    &::before {
                        content: "\2715";
                        margin-right: @px-5;
                    }
                }
                &-1 {
                    background: #32807d;

                    &::before {
                        content: "\2713";
                        margin-right: @px-5;
                    }
                }
                &-2 {
                    background: #3e87f4;
                    & > span {
                        margin-right: @px-5;
                    }
                }
            }
        }
    }
}
// #endregion

.toxic-account-snapshot-container {
    .main-title {
        .font-size-2px-bold(9);
        .padding-2px(5, 0);
        color: @main-app-color;
    }
    .event-details-panel {
        .flex-row-between;
        background: #f6f6f6;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);

        .event-details {
            flex: 1;
            .padding-2px(6, 8);
            background: #ffffff;
            border-right: 1px solid #e0e0e0;
            border-radius: 10px 0 0 10px;

            .tag {
                .padding-2px(2, 7.5);
                display: inline-block;
                background: #f6f6f6;
                border-radius: 0px 5px 5px 0px;
                box-shadow: inset 0px 1px 4px rgb(0 0 0 / 35%);
                position: relative;
                top: @pxh-2 * -0.5;
                left: @px-2 * -8;

                .tag-div {
                    .font-size-2px(8);
                }
            }

            .details {
                & > div {
                    .flex-row;
                    padding-top: @pxh-10 * 0.8;

                    span {
                        display: inline-block;
                        &.label {
                            color: @subtitle-color;
                            min-width: @px-100 * 1.2;
                        }
                    }
                }
            }
        }

        .right-panel {
            flex: 4;
            .flex-row-between;
            .padding-2px(5, 0);
            .score-panel,
            .final-score-panel {
                .padding-2px(3, 9);

                .title {
                    .font-size-2px-bold(8);
                    margin-bottom: @pxh-10 * 0.3;
                }
            }
            .score-panel {
                flex: 1;
                border-right: 1px dashed #d1d1d1;
                .details {
                    & > div {
                        .flex-row-between;
                        padding-top: @pxh-10 * 0.8;
                        span {
                            display: inline-block;
                            text-align: right;

                            &.label {
                                color: @subtitle-color;
                                text-align: left;
                                min-width: @px-100 * 1.2;
                            }
                        }
                    }
                }
            }
            .final-score-panel {
                .flex-column;
                align-items: center;
                flex: 1;

                .details {
                    .score {
                        .font-size-2px-bold(32);
                        padding-left: @px-10 * 3.3;
                        color: @main-app-color;
                    }
                    .total-score {
                        position: relative;
                        top: @pxh-2;
                    }
                }
            }
        }
    }
    .account-view-panel {
        margin-top: @pxh-10;
        border-radius: 10px;
        box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
    }
    .ticket-view-panel {
        .padding-2px(8);
        padding-bottom: 0;
        margin-top: @pxh-10;
        margin-bottom: @pxh-10;
        border-radius: 10px;
        background: @bg-white;
        box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);

        .title-panel {
            .flex-row-between;
            margin-bottom: @pxh-10;

            .left {
                .flex-column;
                .title {
                    .font-size-2px-bold(9);
                    color: @main-app-color;
                }

                & > span {
                    .anticon {
                        margin-right: @px-5;
                        margin-left: 0;
                    }
                }
            }
            .right {
                span {
                    .font-size-2px-bold(9);
                }
            }
        }
    }
}
.triggerMode-custom {
    max-width: @px-100 * 5 !important;
    .triggerMode-container {
        .flex-column;
        min-width: @px-100 * 2.9 !important;

        & > div {
            .flex-row-between;
        }
    }
}

.mtserverhub-config-container {
    .page-title {
        .font-size-2px-bold(9);
        .flex-row;
        justify-content: flex-end;
        margin-bottom: @pxh-10;
        color: @main-app-color;

        .search-input {
            width: @px-100 * 3;
            background: transparent;
            border-radius: 5px;

            input {
                background: transparent;
            }
        }
    }
    .items-container {
        float: left;

        .item {
            display: inline-block;
            margin-bottom: @pxh-20;
            margin-right: @px-20;
            border-radius: 0 10px 0 10px;
            background: @bg-white;

            & > div {
                .flex-column;
                justify-content: space-between;
                min-width: @px-100 * 1.7;
                min-height: @pxh-100 * 1.68;

                .title {
                    .flex-row;
                    width: 80%;
                    height: @pxh-20 * 1.25;
                    align-items: center;
                    margin-top: @pxh-20;
                    background: #c4edc1;
                    border-radius: 0 3px 3px 0;
                    box-shadow: inset 0px 1px 3px rgb(0 0 0 / 25%) !important;

                    & > div {
                        width: @px-5;
                        height: 100%;
                        background: #31bd00;
                        margin-right: @px-5;
                        -webkit-animation: blinking 0.3s infinite linear;
                        animation: blinking 0.3s infinite linear;
                    }
                }
                .content {
                    padding-left: @px-10;
                    .check-item {
                        margin-bottom: @pxh-5;
                        & > span {
                            &:first-child {
                                margin-right: @px-5;
                                color: #aaaaaa;
                            }
                            &.active {
                                color: #31bd00 !important;
                            }
                        }
                    }
                }
                .footer {
                    text-align: center;
                    border-top: 1px solid @main-border-color;
                }
            }

            &.inactive {
                & > div {
                    .title {
                        background: #eaeaea;

                        & > div {
                            background: #aaaaaa;
                            -webkit-animation: none;
                            animation: none;
                        }
                    }
                    .content {
                        .check-item {
                            & > span {
                                :first-child {
                                    color: #aaaaaa;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mtserverhub-config-modal-container {
    width: 650px;

    .main-details {
        .flex-row-between;
        padding-right: @px-10;
        margin-bottom: @pxh-10;

        & > div {
            &:last-child {
                .form-checkbox {
                    margin-bottom: 0;
                }
            }

            span {
                font-size: @font-size-16;
                font-weight: bold;
            }
        }
    }

    .config-container {
        .item {
            .padding-2px(8);
            margin-top: @pxh-10;
            padding-bottom: @pxh-5;
            border: 1px solid #e0e0e0;
            box-shadow: 0px 1px 3px rgb(0 0 0 / 25%) !important;
            border-radius: 5px;
            background: @color-white;

            .title {
                .font-size-2px-bold(8);
                color: @main-app-color;
                margin-bottom: @pxh-10;
            }
            .config-panel {
                .ant-form-item {
                    margin-bottom: @pxh-10;
                }
            }
        }
    }
}

.problematic-clients-div {
    .top-div {
        margin-bottom: @pxh-10;
        display: flex;
        justify-content: flex-end;
    }

    .title-value-box {
        .title {
            font-size: @font-size-14;
        }
        .value {
            color: #8e8e8e;
            font-size: @font-size-12;
        }
        .updated-by {
            font-size: @font-size-11;
            margin-top: @pxh-5;
        }
    }

    .ant-segmented {
        .margin-2px(5);
    }

    .ant-table.ant-table-small .ant-table-expanded-row-fixed {
        padding: 0 8px !important;
    }
}

.prb-statusTag {
    border: none;
    border-radius: 3px !important;
    width: @px-100 * 0.9;
    color: @color-white !important;
    font-weight: bold;
    text-align: center;
}

.prb-sts {
    &-1 {
        background-color: #cf0000 !important;

        &-text {
            background-color: @color-white !important;
            color: #cf0000;
        }
    }
    &-3 {
        background-color: @color-black !important;

        &-text {
            background-color: @color-white !important;
            color: @color-black;
        }
    }
    &-4 {
        background-color: @color-black !important;

        &-text {
            background-color: @color-white !important;
            color: @color-black;
        }
    }
    &-2 {
        background-color: #52c41a !important;

        &-text {
            background-color: @color-white !important;
            color: #52c41a;
        }
    }
    &-5 {
        background-color: #0533ff !important;

        &-text {
            background-color: @color-white !important;
            color: #0533ff;
        }
    }
}
.prb-blockTypeColor {
    width: auto;
    border-radius: 3px !important;
    border: none !important;
    text-align: center;
    font-weight: bold;
}
.prb-blockColor-active {
    background-color: rgba(240, 103, 35, 0.2) !important;
    color: #f06723 !important;
}
.prb-blockColor-inactive {
    background-color: rgba(115, 115, 115, 0.2) !important;
    color: #737373 !important;
}
.prb-team {
    background-color: transparent !important;
    border: 1px solid @color-black !important;
    border-radius: 3px;
}
.problematic {
    &-client-blockType-container {
        .sm-title {
            color: #708090;
            font-weight: bold;
            font-size: @font-size-11;
        }
        .sm-desc {
            font-size: @font-size-12;
            color: @color-black;
            white-space: nowrap; /* Prevent text from wrapping */
            overflow: hidden; /* Hide overflowing text */
            text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
        }
    }

    &-client-blockType-container,
    &-team-expandable-table {
        .modified-panel {
            .flex-row-between;
            .modified {
                font-size: @font-size-12;

                .modifiedByName {
                    color: @subtitle-color;
                }
            }
        }
    }

    &-audit-log-modal {
        .timeline-box {
            .timeline-head {
                span.anticon {
                    margin-right: @px-10 * -1;
                    font-size: @font-size-12;
                }
            }
        }

        .timeline-head {
            .flex-row-between;
        }

        .timeline-title {
            font-size: @font-size-14;
            margin-bottom: @pxh-10 * 0.7;
        }

        .timeline-comment {
            .padding-2px(4, 8);
            .margin-2px(2, 0);
            .main-border;
            border-radius: 0 5px 5px 5px;
        }

        .timeline-description {
            font-size: @font-size-11;
            color: #474747;
            .margin-2px(0, 5);
        }
    }
    &-accounts-modal {
        .acc-list-container {
            background-color: rgba(50, 128, 125, 0.05);
            border-radius: 5px;
            margin: 5px;
            .padding-2px(4, 2);
        }
    }
    &-crm-comment-modal {
        .box {
            .padding-2px(4, 8);
            .label {
                padding: 0 16px 0 0;
                text-align: right;
                span {
                    color: #ff0000;
                }
            }
            .value {
                font-size: @font-size-14;
                font-weight: 600;
            }
        }
    }
}

.ant-table-thead > tr > th.ant-table-cell.special-rules {
    background-color: #c7c7c7 !important;
}
.ant-table-thead > tr > th.ant-table-cell.has-comment {
    background-color: #dedede !important;
}
.ant-table-thead > tr > th.ant-table-cell.scores {
    background-color: #9c9c9c !important;
}

.symbol-list {
    .segment-div {
        .padding-2px(5);
        padding-bottom: @pxh-15;

        .ant-segmented {
            border-radius: 5px;

            .ant-segmented-item {
                border-radius: 5px;

                .segment-container {
                    .flex-row;
                    .padding-2px(1, 1);
                    align-items: center;

                    .ant-badge {
                        .margin-2px(0, 3);
                        .padding-2px(2, 4);
                        .font-size-2px(6);
                        background-color: @main-app-color;
                        color: @color-white;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

.upload-error-div {
    margin-bottom: @pxh-10;
}

.basic-symbol-info-div {
    margin-bottom: @pxh-20;

    .title {
        .flex-row-between;
        border-bottom: 3px solid @main-app-color;
        padding-bottom: @pxh-10 * 0.7;
        margin-bottom: @pxh-10 * 1.4;
        line-height: 2;

        & > span {
            font-weight: 700;
        }
    }

    .details-container {
        .ant-row {
            line-height: 2;
        }
    }

    .created-by-container {
        margin-top: @pxh-100;
        color: #909090;
    }

    .label-value-div {
        display: flex;
        width: 100%;

        .label-div {
            width: 40%;

            &.height-32 {
                & > p {
                    height: 34px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                }
            }
        }

        .value-div {
            width: 60%;
        }
    }
}

.symbol-mapping-div {
    & > .title {
        .flex-row-between;
    }

    .details-container {
        max-height: 33vh;
        overflow: auto;

        &.popover-view {
            max-height: 70vh;
            overflow: auto;
        }

        .mapping-container {
            .main-border;
            .padding-2px(4, 5);
            margin-bottom: @pxh-5;
            border-radius: 5px;
            box-shadow: 0px 1px 2px rgb(0 0 0 / 30%) !important;

            .top-container {
                .flex-row-between;
                align-items: center;
            }
            .settings-container {
                margin-top: @pxh-5;

                .settings-item {
                    line-height: 2.5;
                }
            }
            span.label {
                .font-size-2px-bold(7);
                margin-right: @px-10;
            }
        }
    }
}

.symbol-info-page-container {
    background: @bg-white;
    border-radius: 10px;
    .top-header-div {
        .flex-row-between;
        .padding-2px(4, 7);
        border-bottom: 2px solid @main-border-color;

        .filter-div {
            display: inline-block;

            & > .ant-form-item {
                margin-bottom: 0;
            }
        }

        .buttons-div {
            .flex-row;
        }
    }

    .main-container {
        .flex-row-between;
        min-height: 70vh;
        margin-bottom: @pxh-30;

        .left {
            .flex-row-between;
            width: @px-100 * 3.6;

            & > div {
                &:nth-child(2) {
                    border-left: 1px solid @border-color-2;
                }
            }

            .group {
                &-1 {
                    width: @px-100 * 1.8;
                }
                &-2 {
                    width: @px-100 * 1.8;
                }
            }

            .title {
                .padding-2px(2, 5);
                .margin-2px(7.5, 10);
                margin-left: 0;
                font-size: @font-size-16;
                background: #f6f6f6;
                box-shadow: inset -1px 1px 3px #00000066;
                border-radius: 0px 5px 5px 0px;
            }
            .content {
                .search-panel {
                    .padding-2px(0, 2.5);
                    margin-bottom: @pxh-10;

                    .search-input {
                        width: 100%;
                    }
                }
                .item-container {
                    min-height: 30vh;
                    max-height: 90vh;
                    margin-bottom: @pxh-10;
                    overflow: auto;

                    & > div {
                        &.ant-spin-spinning {
                            display: flex;
                            justify-content: center;
                            margin-top: @pxh-30;
                        }

                        &:not(.ant-spin-spinning) {
                            .padding-2px(2.5, 5);
                            .flex-row-between;
                            align-items: center;
                            padding-right: @px-5;
                            border: 1px solid @main-border-color;
                            cursor: pointer;
                        }

                        &.active {
                            background: #f9fccc;
                        }

                        .count-text {
                            .padding-2px(0, 4);
                            .font-size-2px-bold(6);
                            color: @color-white;
                            background: @main-app-color;
                            border-radius: 30px;
                            line-height: 1.8;
                        }

                        .mini-tag {
                            .font-size-2px(5);
                            background: #bdbdbd;
                            border-radius: 30px;
                            padding: 1px 6px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .right {
            .padding-2px(7.5);
            width: calc(100% - (@px-100 * 3.6));
            border-left: 1px solid @border-color-2;

            .top-header-container {
                .flex-row-between;

                .left-title {
                    .font-size-2px(9);
                    .padding-2px(2, 10);
                    display: inline;
                    border-radius: 5px;
                    background: #f6f6f6;
                    box-shadow: inset 0px 1px 4px #00000066;
                }

                .buttons-group {
                    .anticon {
                        border-radius: 3px;
                    }
                }
            }

            .symbol-details-main-container {
                .flex-row-between;
                margin-top: @px-20;

                .main-panel {
                    width: calc(100% - (@px-100 * 4));
                    padding-top: @px-10;

                    &.main-only {
                        width: calc(100%);

                        .details-container {
                            padding-right: calc(@px-100 * 4);
                        }
                    }
                }
                .sub-panel {
                    width: @px-100 * 4;
                    padding-left: @px-20;
                    padding-top: @px-10;
                    margin-left: @px-20;
                    margin-bottom: @pxh-20;
                    border-left: 1px solid @main-border-color;
                }

                .basic-symbol-info-div;
            }
        }
    }
}

.filter-component {
    &-panel {
        .flex-row-between;
        min-height: @pxh-10 * 2.5;

        .left {
            .flex-column-center;
            justify-content: center;
            padding-right: @px-10;

            .anticon {
                .font-size-2px(12);
                color: @main-app-color;
                cursor: pointer;
            }
        }
        .right {
            .flex-row;
            .padding-2px(2, 5);
            justify-content: flex-start;
            min-width: @px-100 * 3;
            background: #f6f6f6;
            box-shadow: inset -1px 1px 3px #00000066;
            border-radius: 5px;

            .filter-selection-panel {
                .flex-row;
                margin-right: @px-15;

                & > span {
                    margin-right: @px-5;
                }

                &:last-child {
                    margin-right: 0;
                }

                .tag-div {
                    .main-border-2;
                    .padding-2px(0, 5);
                    .margin-2px(0, 0.5);
                    background: @bg-white;
                    line-height: 1.5;
                    border-radius: 3px;
                }
            }
        }
    }
    &-popover-css {
        min-width: @px-100 * 3;

        .filter-header-panel {
            .flex-row-between;
            .padding-2px(2, 0);

            .text-panel {
                .font-size-2px(9);
            }

            .buttons {
                .ant-btn {
                    margin-left: @px-10;

                    &.reset-style {
                        color: @color-error;
                    }
                    &.save-style {
                        color: @main-app-color;
                    }
                }
            }
        }

        .ant-popover-inner-content {
            width: 100%;

            .ant-form-item {
                margin-bottom: @pxh-10;
            }
        }
    }
}

.sipc-mdl-c-div {
    .flex-row-between;

    .left {
        width: 48%;

        &.main-only {
            width: 100%;
        }
    }
    .right {
        width: 48%;
    }

    .basic-symbol-info-div {
        .title {
            .flex-row-between;
            border-bottom: 3px solid @main-app-color;
            padding-bottom: @pxh-10 * 0.7;
            margin-bottom: @pxh-10 * 1.4;
            line-height: 2;

            & > span {
                font-size: medium;
                font-weight: bold;
            }
        }

        .label-value-div {
            align-items: center;

            &.create-view {
                border-bottom: 1px solid @main-border-color;
            }

            .value-div {
                width: 100%;

                .ant-form-item {
                    margin-bottom: @pxh-10;
                }

                .symbol-holiday-time-div {
                    .flex-row-between;
                    align-items: center;

                    .date-div {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.multiple-time-range-component {
    .ant-form-item {
        margin-bottom: @pxh-5;
    }
    .add-new-btn {
        .padding-2px(1.5);
        border: 1px dashed @border-color-4;
        text-align: center;
        cursor: pointer;

        .anticon {
            margin-right: @px-5;
        }
    }
    .input-div {
        .ant-picker {
            width: calc(100% - 32px);
            margin-bottom: @pxh-5;
        }
    }
}

.time-range-tag-div {
    & > .item {
        .main-border-2;
        .padding-2px(0, 5);
        margin-right: @px-5;
        border-radius: 4px;
        background: #dddddd;
        color: #202020;
    }
}

.account-premarket-model-container {
    .top-info-div {
        padding-right: @px-15;
        margin-bottom: @pxh-10;
        display: flex;
        justify-content: flex-end;
    }
}
// LP START
.tiny-summary-chart-container {
    .padding-2px(6);
    display: inline-block;
    background: @main-app-color;
    border-radius: 5px;
    color: @color-white;
    box-shadow: 1px 2px 3px #0000006d;
    min-width: @px-100 * 2.6;

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left {
            .main-title {
                .font-size-2px-bold(9);
                text-shadow: 1px 1px 1px @color-black;
            }
            .value-container {
                .main-value {
                    .font-size-2px-bold(17);
                    text-shadow: 1px 1px 1px @color-black;
                }
                .sub-value {
                    .font-size-2px(7);

                    &.val {
                        &-positive {
                            color: @color-sucess;
                        }
                        &-negative {
                            color: @color-error;
                        }
                    }
                }
            }
        }
        .right {
            margin-left: @px-10;
            display: flex;
            align-items: end;
        }
    }
}

.tiny-summary-chart2-container {
    .main-border-3;
    .padding-2px(5);
    display: inline-block;
    background: @bg-white;
    border-radius: 5px;
    box-shadow: 0px 1px 2px #0000006d;
    margin-bottom: 10px;

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: end;
        }
        .right {
            display: flex;
            flex-direction: column;
            align-items: end;
            margin-left: @px-5;

            .value-container {
                .main-value {
                    .font-size-2px-bold(17);
                }

                .sub-value {
                    .font-size-2px(7);

                    &.val {
                        &-positive {
                            color: @color-sucess;
                        }
                        &-negative {
                            color: @color-error;
                        }
                    }
                }
            }
            .main-title {
                .font-size-2px-bold(9);
                color: #949494;
                text-align: end;
            }
        }
    }
}

.lp-info-popover-container {
    .title {
        .flex-row-between;
        background: #ededed;

        & > div {
            .padding-2px(1, 3);

            &.default-style {
                width: @px-100;
            }
        }
    }

    .content {
        .item {
            .flex-row-between;
            border-bottom: 1px solid @main-border-color;

            & > div {
                .padding-2px(1, 3);

                &.default-style {
                    width: @px-100 * 1.1;
                }
            }
        }
    }
}

.lp-monitor-summary-container {
    .big-title {
        .font-size-2px-bold(9);
        color: @primary-color;
        margin-bottom: @pxh-10;
    }

    .second-bar {
        .flex-row-between;
        background: #ffffff;
        border-radius: 3px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: @pxh-20;

        .left {
            margin: @pxh-10;
        }

        .right {
            display: flex;
            .resync-lp-data-btn {
                padding-top: @pxh-10;
                padding-bottom: @pxh-10;
                padding-right: @px-10;
            }

            .hourly-report-container {
                width: auto;
                height: 100%;
                display: flex;

                .sending-text-title {
                    .font-size-2px-bold(7);
                }

                .sending-text-desc {
                    .font-size-2px(6);
                }
            }

            .send-hourly-report {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: @main-app-color;
                color: @color-white;
                height: 100%;
                width: 100%;
                cursor: pointer;
                padding-left: @px-10;
                padding-right: @px-10;

                .sending-text-title {
                    .font-size-2px-bold(7);
                }

                .sending-text-desc {
                    .font-size-2px(6);
                }
            }
        }
    }

    & > .main-content {
        background: @bg-white;
        border-radius: 3px;
        padding-bottom: @pxh-5;
        margin-bottom: @pxh-10;

        .header-row {
            .flex-row-between;

            .left {
                margin-top: -5px;
                .tiny-summary-chart-container {
                    margin-left: @px-10;
                    margin-bottom: @pxh-10;
                }
            }
            .right {
                .flex-column;
                align-items: end;

                .timer-container {
                    .padding-2px(5);
                    .flex-row;
                    justify-content: flex-end;

                    & > .anticon {
                        font-size: @font-size-20;
                        color: #828282;
                    }
                }

                .last-refresh-time {
                    .padding-2px(0, 5);
                    padding-bottom: @pxh-5;
                }
            }
        }

        .sub-row {
            .padding-2px(5);
            .flex-row-between;
            flex: 1;

            .half-view-1 {
                width: 50%;
                .padding-2px(0, 5);

                .flexi-datatable {
                    .ant-collapse-header-text {
                        flex: auto;

                        & > span {
                            word-wrap: break-word;
                            word-break: break-word;

                            & > span {
                                color: #004b57;
                            }
                        }
                    }
                }
            }
            .right-panel {
                width: 50%;
                display: flex;
                flex-direction: column;
                .padding-2px(0, 5);
            }
        }
    }

    .diff-dect-col {
        .flex-row-between;
        .diff-dect-popover {
            z-index: 3;
        }
        .anticon {
            &.anticon-file-search {
                .font-size-2px(7);
                cursor: pointer;
            }
        }
    }

    .flexi-table {
        .row-currency {
            &:has(.margin-level-container.lvl-30-50) {
                background: #d4f4c4;
            }
            &:has(.margin-level-container.lvl-51-70) {
                background: #f9fac4;
            }
            &:has(.margin-level-container.lvl-71) {
                background: #ffdcdc;
            }
        }
    }
}

.lp-monitor-summary-container-report-menu {
    .ant-dropdown-menu-item {
        .ant-dropdown-menu-title-content {
            .report-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                width: 100%;
                cursor: pointer;
                padding: @px-10 @pxh-10;
                border-bottom: 1px solid @main-border-color;

                .sending-text-title {
                    .font-size-2px-bold(7);
                }

                .sending-text-desc {
                    .font-size-2px(6);
                }
            }
        }
    }
}

.lp-symbol-render-container {
    .flex-row;

    span {
        &.anticon {
            .font-size-2px(6);
            margin-left: @px-5;
            color: #ec1b1b;
        }
    }
}

.tiny-chart-tooltips-custom-container {
    .flex-column;
    font-size: @font-size-12;

    & > span {
        .margin-2px(1.5, 0);

        &.title {
            font-size: @font-size-14;
            font-weight: bold;
        }

        &:not(.title) {
            .flex-row;
            align-items: center;

            &:before {
                content: " ";
                border: 1px solid #237d8b;
                border-radius: 50%;
                height: 8px;
                width: 8px;
                display: inline-block;
                background: #6cff57;
                margin-right: @px-5;
            }
        }
    }
}

.last-refresh-time {
    color: #333333;
}

.diff-dect-popover {
    &-title {
        .font-size-2px-bold(8);
    }
    &-content {
        min-width: @px-100 * 2.5;

        & > .item-container {
            max-height: @pxh-100 * 2.5;
            overflow: auto;
        }

        .row {
            .flex-row-between;
            .padding-2px(2, 4);

            &-title {
                background: #ededed;
            }

            &-item {
                border-bottom: 1px solid @main-border-color;
            }
        }
    }
}

.alarm-container {
    .padding-2px(3, 5);
    background: #ffbbbb;
    border-radius: 5px 0px 0px 5px;
    box-shadow: 0px 1px 3px #0000006d;

    .alarm-btn {
        cursor: pointer;
        .anticon-alert,
        .ant-badge {
            & > .ant-badge-count {
                box-shadow: none;
            }
        }

        .anticon-alert {
            margin-right: @px-10;
        }
        .anticon-arrow-right {
            margin-left: @px-10;
        }
        & > span:nth-child(2) {
            margin-right: @px-2;
        }
    }
}
// LP END
// LP 360 start
.lp-monitor-360-container {
    & > .main-title {
        .font-size-2px-bold(9);
        color: @primary-color;
        margin-bottom: @pxh-10;
    }

    .content-panel {
        margin-top: @pxh-10;
        background: @bg-white;
        border-radius: 10px;
        min-height: 70vh;
        padding: @px-15;

        .flex-row;

        .left-panel {
            width: 28%;
            padding: @pxh-10;
            padding-top: 0;

            border-right: 1px solid @main-border-color;

            .top-panel {
                font-weight: bold;

                & > .ant-form-item {
                    margin-bottom: @pxh-10;
                }
            }

            .last-refresh-time {
                margin-left: -35px;
                padding-left: 35px;
                padding-top: @pxh-5;
                padding-bottom: @pxh-5;
                background: #eaeaea;
                border-radius: 3px 0px 0px 3px;
                border: 1px solid #e4e4e4;
            }

            .list-item-container {
                display: flex;
                flex-direction: column;
                margin-left: -35px;
                padding-top: @pxh-10;
                border-top: 1px solid @main-border-color;
            }
        }
        .right-panel {
            width: 72%;
            margin-left: 10px;
        }
    }
}
// LP 360 end;

// this is for zoom screen 150%
@media screen and (max-width: 1550px) {
    .lp-monitor-360-container {
        .content-panel {
            .left-panel {
                width: @px-100 * 4.2;
            }
            .right-panel {
                width: calc(100% - (@px-100 * 4.2));
            }
        }
    }
}

//start lp-alarm-monitor-container
.lp-alarm-monitor-container {
    .breadcrumb-panel {
        margin-left: @px-15;
        margin-bottom: @pxh-15;
    }

    .top-info-div {
        padding-right: @px-15;
        margin-bottom: @pxh-10;
        display: flex;
        justify-content: flex-end;
    }
}

.lp-alarm-listing-container {
    background: @bg-white;

    .alarm-info-container {
        .title {
            .font-size-2px-bold(7);
        }

        .desc {
            color: #7b7b7b;
        }
    }
}

.lpmonitor-create-edit-form {
    .main-panel-left {
        padding-right: @px-30;

        .filter-panel {
            .main-border-2;
            .title-panel {
                .padding-2px(2.5, 2.5);
                .font-size-2px-bold(8);
                .main-border-2-bottom;
                .flex-row-between;
                align-items: center;

                .left {
                    padding-left: @px-5;
                    color: @main-app-color;
                }
            }

            & > .content {
                .padding-2px(4);
                background: #f9f9f9;
            }
        }

        .filter-group-panel {
            .main-border-2;
            .padding-2px(5);
            padding-top: @pxh-5;
            margin-bottom: @pxh-10 * 0.8;
            background: @bg-white;
            border-radius: 3px;

            &:last-child {
                margin-bottom: 0;
            }

            & > .btn-group {
                .flex-row;
                justify-content: flex-end;
                padding-bottom: @pxh-5;

                .btn-delete {
                    margin-right: -5px;
                }
            }

            .level-group {
                .ant-form-item.form-select {
                    margin-bottom: @pxh-10 * 0.8;
                }
            }

            .orcondition-card-header {
                .flex-row-between;

                .title {
                    .font-size-2px-bold(8);
                    color: @main-app-color;
                }
            }

            .orcondition-group-panel {
                .ant-card-head {
                    .padding-2px(0);
                    border-bottom: none;

                    .ant-card-head-title {
                        border-bottom: 3px solid @main-app-color;
                        margin-bottom: @pxh-10 * 0.8;
                    }
                }
                .ant-card-body {
                    .padding-2px(0);

                    .orcondition-item {
                        .padding-2px(5);
                        padding-top: @pxh-5;
                        background: @bg-white;
                        border-radius: 3px;

                        .btn-group {
                            .flex-row;
                            justify-content: flex-end;

                            .btn-delete {
                                margin-right: -5px;
                            }
                        }

                        .content {
                            .and-item {
                                .padding-2px(1.5, 2);
                                .flex-row-between;
                                align-items: flex-start;

                                .ant-form-item {
                                    flex: 1;
                                    margin-bottom: 0;
                                }
                            }

                            .add-btn-panel {
                                .margin-2px(0, 1.5);
                                .padding-2px(1.5, 10);
                                .flex-row;
                                justify-content: center;
                                border: 1px dashed #b7b7b7;
                                background: #f1f1f1;
                                color: #7d7d7d;
                                cursor: pointer;
                            }
                        }
                    }

                    & > .orcondition-wrapper {
                        .main-border-2;
                        border-radius: 5px;
                        margin-bottom: @pxh-10 * 0.8;

                        .orcondition-item {
                            &::after {
                                .font-size-2px-bold(7);
                                .padding-2px(1, 10);
                                position: absolute;
                                right: calc(50% - (@px-30));
                                content: " OR ";
                                color: @color-white;
                                background: @main-app-color;
                                margin-top: @pxh-10 * 0.4;
                                border-radius: 30px;
                                z-index: 1;
                            }
                        }

                        &:last-child {
                            .orcondition-item {
                                margin-bottom: 0;

                                &::after {
                                    display: none;
                                }
                            }
                        }

                        &:nth-last-child(2) {
                            .orcondition-item {
                                margin-bottom: 0;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .orcondition-add-button-container {
                        .padding-2px(1.5, 10);
                        .flex-row;
                        justify-content: center;
                        border: 1px dashed #b7b7b7;
                        background: #f1f1f1;
                        color: #7d7d7d;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .main-panel-right {
        padding-left: @px-20;
        border-left: 1px solid @border-color-4;

        .filter-panel {
            padding-bottom: @pxh-15;

            .title-panel {
                padding-bottom: @pxh-5;
                .font-size-2px-bold(8);
                .flex-row-between;
                align-items: center;
                border-bottom: 3px solid @main-app-color;
                color: @main-app-color;
            }

            .content {
                .item {
                    .main-border-4-bottom;
                    .padding-2px(5, 0);

                    &.view {
                        .flex-row-between;
                        align-items: center;
                    }

                    .desc-panel {
                        margin-top: @pxh-5;
                        .desc-text {
                            .text {
                                margin-right: @px-15;
                                &::after {
                                    content: ":";
                                }
                            }
                        }
                        .details {
                            margin-top: @pxh-5;
                            max-height: @pxh-100 * 3;
                            overflow-y: auto;
                        }
                    }

                    .features-div {
                        .flex-row-between;
                        align-items: center;
                        margin-top: @pxh-5;

                        .left {
                            & > button {
                                margin-right: @px-10;
                            }
                        }

                        .right {
                            flex: 1;
                            margin-left: @px-10;

                            .search-input {
                                background: transparent;
                                border-radius: 3px;
                            }
                        }
                    }

                    .form-checkboxgroup {
                        .ant-checkbox-group {
                            & > label {
                                min-width: 22%;
                            }
                        }
                    }
                }
            }
        }
    }
}

//end lp-alarm-monitor-container

//start mr monitor group
.mrBrands-container {
    border: 1px solid #d0d0d0;

    .title-div {
        .flex-row-between;
        .padding-2px(5, 7.5);
        border-bottom: 1px solid #d0d0d0;
    }

    .list-item-div {
        min-height: @pxh-100 * 2.75;

        .item-container {
            .padding-2px(5, 7.5);
            align-content: center;
            border-bottom: 1px solid @main-border-color;

            .content-panel {
                .display-content,
                .editable-content {
                    .flex-row-between;
                    .left {
                        flex: 5;
                        padding-right: @px-10;

                        .title {
                            .font-size-2px-bold(8);
                            margin-left: 5px;
                        }
                        .subtitle {
                            .pkServerColor {
                                color: @main-app-color;
                                border: none;
                                border-radius: 3px;
                                background-color: rgba(0, 97, 93, 20%);
                                margin: 5px;
                            }
                        }
                    }
                    .right {
                        flex: 1;
                        text-align: right;
                    }
                }
            }
        }
    }
}
//end mr monitor group

//Hub Status Dashboard - START -
.hs-status-dashboard-container {
    .title-container {
        .flex-row-between;
        .font-size-2px-bold(9);
        margin-bottom: @pxh-5;

        .title-text {
            .font-size-2px(11);
            color: @main-app-color;
            margin-right: @px-10;
        }
    }
    .top-panel {
        .flex-row-between;
        .padding-2px(2.5);
        border-radius: 5px;
        background: @bg-white;

        .left {
            .flex-row;
            justify-content: flex-start;

            .ant-input-group-compact {
                .flex-row;
                justify-content: flex-start;

                .sorting-element {
                    .ant-select-selector {
                        height: 34px;
                    }
                }
            }

            .btn-groups {
                .flex-row;
                justify-content: flex-start;
                margin-left: @px-10;
                padding-left: @px-10;
                border-left: 1px dashed #9e9e9e;

                .ant-btn {
                    margin-left: @px-10;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        .right {
            .resync-div {
                .main-border-2;
                border-radius: 10px 0px 0px 10px;

                .time-panel {
                    margin-left: @px-10;
                    margin-right: @px-10;
                }
            }
        }
    }
    .item-container {
        display: flex;
        flex-wrap: wrap;
        gap: 0px;
        margin-top: @pxh-20;

        .hs-group-container {
            .padding-2px(0, 4);
            padding-bottom: @pxh-10 * 0.8;

            width: @px-100 * 3;
            background: @color-white;
            margin-bottom: @pxh-10;
            border-radius: 5px;

            .top {
                .padding-2px(0.5, 5);
                .font-size-2px-bold(7);
                padding-left: @px-10 * 0.4;
                display: inline-block;
                background: #00615d;
                border-radius: 5px;
                color: @color-white;
                box-shadow: 0px 1px 2px #00000066;
                position: relative;
                top: -3px;
                min-width: @px-100;

                & > .ant-checkbox-wrapper {
                    margin-right: @px-10 * 0.4;

                    & > .ant-checkbox {
                        border: 1px solid #c0c0c0;
                        border-radius: 3px;

                        & + span {
                            padding-left: 0;
                            padding-right: 4px;
                        }
                    }
                }
            }

            .content {
                .group-item {
                    margin-top: @pxh-5;
                    border: 1px solid #eeeeee;
                    border-radius: 3px;
                    box-shadow: 0px 1px 2px #00000066;

                    .group-container {
                        .flex-row-between;
                        .padding-2px(1, 2.5);
                        .font-size-2px-bold(7);

                        .g-name {
                            color: #00615d;
                        }

                        .anticon {
                            font-size: 0.9375rem;
                            border: 1px solid #c4c4c4;
                            border-radius: 3px;
                            width: @px-15;
                            height: @pxh-15;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .ant-checkbox-wrapper {
                            margin-right: @px-10 * 0.4;

                            & > .ant-checkbox {
                                & + span {
                                    padding-left: 0;
                                    padding-right: 4px;
                                }
                            }
                        }
                    }
                    .tbl-header {
                        .font-size-2px(6);
                        .padding-2px(1, 0);
                        font-weight: 500;
                        color: #737373;
                        background: #f0f2f5;
                        box-shadow: inset 0px 1px 3px #0000004d;

                        & > .ant-col {
                            &:first-child {
                                padding-left: @px-5;
                            }
                            &.center {
                                text-align: center;
                            }
                        }
                    }
                    .item {
                        .padding-2px(0.5, 0);
                        border-top: 1px solid @main-border-color;

                        & > .ant-col {
                            .font-size-2px(6);

                            &:first-child {
                                padding-left: @px-5;
                            }
                            &.center {
                                text-align: center;
                            }
                            .status {
                                .font-size-2px-bold(6);
                                .margin-2px(0, 1);
                                border-radius: 3px;

                                &.on {
                                    background: #c3e6cd;
                                    color: #24663b;
                                }
                                &.off {
                                    background: #f0bfbf;
                                    color: #ff0000;
                                }
                            }
                        }
                    }
                }
            }
        }

        .multi-col-dnd-outer-container {
            .group-container {
                &.col {
                    &-4 {
                        .group-inner-container {
                            min-width: @px-100 * 3;
                        }
                    }
                }
            }
        }
    }
}
//Hub Status Dashboard - END -

//Hub Configuration Page - START -

.hub-config-main-content {
    .padding-2px(5);
    display: inline-block;
    background: @color-white;
    min-height: 70vh;
    width: 100%;
    margin-bottom: @pxh-15;
    border-radius: 5px;
}
//Hub Configuration Page - END -

// #region start intraday summary
.intraday-summary-container {
    & > .main-title {
        .font-size-2px-bold(9);
        color: @primary-color;
        margin-bottom: @pxh-10;
    }

    .header-row {
        .flex-row-between;

        .left {
            & > .main-title {
                .font-size-2px-bold(9);
                color: @primary-color;
            }
        }
        .right {
            .flex-column;
            align-items: end;

            .timer-container {
                .padding-2px(5);
                .flex-row;
                justify-content: flex-end;

                & > .anticon {
                    font-size: @font-size-20;
                    color: #828282;
                }
            }

            .last-refresh-time {
                .padding-2px(0, 5);
            }
        }
    }

    & > .main-content {
        padding-bottom: @pxh-10;

        .row-sum {
            display: flex;
            flex-direction: row;
            gap: @pxh-10;
            padding: @pxh-10 0 0 0;

            .left-panel {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: @pxh-10;

                .top-row {
                    display: flex;
                    flex-direction: row;

                    .main-bg-white;
                    border: 1px solid @border-color-4;
                    border-radius: 5px;
                    box-shadow: 0 0 5px 0 #0000001a;

                    .pnl-chart-summary-panel {
                        flex: 1;
                        .title {
                            margin: @pxh-5 @pxh-10 @pxh-15 @pxh-5;
                            .flex-row-between;

                            &-text {
                                font-size: @font-size-18;
                                font-weight: bold;
                            }
                        }
                        .graph-chart-div {
                            .main-bg-white;
                            .padding-2px(5);
                            border-radius: 5px 0 0 5px;
                            width: @px-100 * 5.7;
                        }
                    }

                    .summary-stats-panel {
                        .main-bg-white;
                        .padding-2px(0, 5);
                        flex: 1;
                        width: calc(100% - (@px-100 * 5.7));
                        border-radius: 0 5px 5px 0;
                        justify-content: center;
                        border-left: 1px solid @border-color-4;

                        .summary-blocks-info-row {
                            border-bottom: 1px dashed #b9b9b9;

                            &:last-child {
                                border-bottom: none;
                            }
                            .summary-block-info-div {
                                .flex-column-center;
                                .padding-2px(5);
                                justify-content: center;

                                // bottom 4 lines of code is for when did not use Row and Col - Xin
                                // border-right: 1px dashed #b9b9b9;
                                // width: 50%;

                                // &:last-child {
                                //     border-right: none;
                                // }

                                .summary-value {
                                    font-size: @font-size-24;
                                    font-weight: bold;

                                    &.negative {
                                        color: @main-app-color;
                                    }
                                    &.positive {
                                        color: @color-error;
                                    }
                                }
                                .summary-desc {
                                    font-size: @font-size-13;
                                    color: #b4b4b4;
                                    font-weight: 600;
                                    word-break: break-word;
                                    text-align: center;
                                }
                                .summary-extra {
                                    font-size: @font-size-12;
                                }
                            }
                        }
                    }
                }

                .alarm-summary-map-panel {
                    .padding-2px(5);
                    .flex-row;

                    .main-bg-white;
                    border: 1px solid @border-color-4;
                    border-radius: 5px;
                    box-shadow: 0 0 5px 0 #0000001a;

                    .left-section {
                        // width: 60%;
                        width: 100%;

                        .world-map-container {
                            .flex-column;
                            padding-right: @px-10;
                        }
                    }

                    .right-section {
                        // .padding-2px(0, 5);
                        // padding-left: @px-10;
                        // border-left: 1px solid #dadada;
                        // margin-left: @px-20;
                        width: 40%;
                        .flex-column;
                        // flex: 1;
                        border-left: 1px solid @main-border-color;

                        .main-content {
                            & > div {
                                .flex-column;
                                // width: @px-100 * 2;
                                flex: 1;

                                .desc-tags {
                                    .flex-row;
                                    justify-content: end;

                                    .sorter-button {
                                        margin-right: @px-10;
                                    }
                                    .tag-div {
                                        .padding-2px(2, 6);
                                        display: inline;
                                        background: #f6f6f6;
                                        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.35);
                                        border-radius: 5px 0px 0px 5px;
                                        margin-right: @px-10 * -1;
                                        width: 100%;

                                        span {
                                            font-size: @font-size-16;
                                        }

                                        .select-filter-div {
                                            .ant-select {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }

                                .title-box {
                                    align-self: flex-end;
                                    margin-bottom: @pxh-10;
                                }

                                .filter-div {
                                    display: flex;
                                    justify-content: flex-end;
                                    margin-top: @px-10;

                                    .search-input-filter {
                                        margin-right: @px-5;
                                        margin-left: @px-10;
                                        width: 100%;
                                    }
                                }

                                .content {
                                    .flex-row;
                                    margin-top: @pxh-10;
                                    padding-bottom: @pxh-10;
                                    // padding-right: @px-10;
                                    height: 415px;

                                    .country-values {
                                        .flex-column;
                                        flex: 1;
                                        overflow: auto;

                                        .title-value-div {
                                            .flex-row-between;
                                            flex: 1;
                                            .padding-2px(2.5, 5);
                                            padding-left: @px-15;
                                            width: 100%;

                                            .title {
                                                width: 80%;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // .title {
                        //     margin-bottom: @pxh-10;

                        //     &-icon {
                        //         color: @color-error;
                        //         margin-right: 7px;
                        //         font-size: @font-size-18;
                        //     }
                        //     &-text {
                        //         color: @main-app-color;
                        //         font-weight: bold;
                        //         font-size: @font-size-18;
                        //     }
                        // }
                        .alarm-summary-list-div {
                            .alarm-summary-list {
                                .ant-list-item {
                                    &:first-child {
                                        border-top: 1px solid #e0e0e0;
                                    }
                                    &:not(:last-child) {
                                        border-bottom: 1px solid #e0e0e0 !important;
                                    }

                                    &.alarm-summary-list-item {
                                        .flex-row-between;

                                        .level-counter {
                                            .flex-row;
                                            .alarm-level-item {
                                                .flex-column;
                                                width: 40px;
                                                height: 45px;
                                                text-align: center;
                                                margin-right: @px-5;
                                                border-radius: 3px;
                                                margin-top: -16px;
                                                line-height: normal;
                                                justify-content: center;

                                                &.low {
                                                    background-color: #9d9d9d;
                                                }
                                                &.mid {
                                                    background-color: #ff7e00;
                                                }
                                                &.high {
                                                    background-color: @color-error;
                                                }

                                                .count-value {
                                                    font-size: @font-size-14;
                                                    color: @bg-white;
                                                }
                                                .level-text {
                                                    font-size: @font-size-12;
                                                    color: @bg-white;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right-panel {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: @pxh-10;

                .users-by-pnl-panel,
                .alarm-summary-list-container,
                .symbols-by-exposure-panel {
                    .padding-2px(7);
                    .main-bg-white;
                    flex: 1;
                    box-shadow: 0 0 5px 0 #0000001a;
                    border-radius: 5px;

                    .title {
                        &-text {
                            font-size: @font-size-18;
                            color: @main-app-color;
                            font-weight: bold;
                        }

                        .pgNav {
                            margin: @pxh-5 0;
                            .flex-row-between;

                            .select-container {
                                display: flex;
                                flex-direction: row;

                                .ant-select {
                                    margin-right: @pxh-5;
                                    min-width: 60%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .intraday-summary-container {
        .summary-stats-panel {
            .summary-block-info-div {
                .summary-value {
                    font-size: @font-size-22 !important;
                }
                .summary-desc {
                    font-size: @font-size-12 !important;
                }
                .summary-extra {
                    font-size: @font-size-11 !important;
                }
            }
        }
    }
}
// #endregion end intraday summary

// for SEARCH and SUMMARY intraday
.intraday-brand-summary-panel,
.intraday-symbols-summary-panel {
    .flex-row;
    .main-bg-white;
    .padding-2px(8);
    padding-right: @px-5;
    box-shadow: 0 0 5px 0 #0000001a;
    border-radius: 5px;
    flex: 1;

    .c-left {
        .flex-column;
        flex: 2;

        .title-box {
            margin-bottom: @pxh-10;
            color: @main-app-color;

            &-text {
                .font-size-2px-bold(9);

                &.summary {
                    color: @main-app-color;
                }
            }

            .pgNav {
                display: block;
                margin: @pxh-5 0;
            }
        }
        .chart {
            flex: 1;
        }
    }

    .c-right {
        .flex-column;
        flex: 1;
        border-left: 1px solid @main-border-color;

        .main-content {
            & > div {
                .flex-column;
                flex: 1;

                .desc-tags {
                    .flex-row;
                    justify-content: end;

                    .sorter-button {
                        margin-right: @px-10;
                    }

                    .tag-div {
                        .padding-2px(2, 6);
                        display: inline;
                        background: #f6f6f6;
                        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.35);
                        border-radius: 5px 0px 0px 5px;
                        margin-right: @px-5 * -1;
                        width: 100%;

                        span {
                            font-size: @font-size-16;
                        }

                        .select-filter-div {
                            .ant-select {
                                width: 100%;
                            }
                        }
                    }
                }

                .filter-div {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: @px-10;

                    .search-input-filter {
                        margin-right: @px-5;
                        margin-left: @px-10;
                        width: 100%;
                    }
                }

                .content {
                    .flex-row;
                    margin-top: @pxh-10;
                    padding-bottom: @pxh-10;
                    padding-right: @px-10;
                    height: @pxh-100 * 3.3;

                    .tag-summary-desc-panel {
                        .flex-column;
                        flex: 1;
                        overflow: auto;

                        .title-value-div {
                            .flex-row-between;
                            .padding-2px(2.5, 5);
                            // flex: 1;
                            padding-left: @px-15;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .chart-panel {
        padding-left: 0;
        padding-top: 0;
    }
    .intraday-chart-panel {
        border-radius: 5px;
        box-shadow: none;
        border: none;

        .c-left {
            .title {
                .title-desc-panel {
                    .title-text {
                        font-size: @font-size-18;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

// #region start intraday search
.intraday-search-container {
    .header-row {
        .flex-row-between;

        .left {
            & > .main-title {
                .font-size-2px-bold(9);
                color: @primary-color;
            }
        }
        .right {
            .flex-column;
            align-items: end;

            .timer-container {
                .padding-2px(5);
                .flex-row;
                justify-content: flex-end;

                & > .anticon {
                    .font-size-2px(10);
                    color: #828282;
                }
            }

            .last-refresh-time {
                .padding-2px(0, 5);
            }
        }
    }
    & > .main-content {
        padding-bottom: @pxh-5;
        margin-bottom: @pxh-10;
        border: 1px solid #d7d7d7;
        margin-top: @pxh-10;
        border-radius: 10px 10px 0 0;

        .filter {
            &-panel {
                .flex-row;
                .padding-2px(3);
                min-height: @pxh-10 * 2.5;
                background: @bg-white;
                border-radius: 10px 10px 0 0;
                // background-color: #fafafa;
                border: none;
                border-bottom: 1px solid #d9d9d9;

                .anticon-filter {
                    align-items: flex-start;
                    padding: @pxh-5 @px-15 @pxh-5 @px-10;
                    color: @main-app-color;
                    font-weight: bold;
                }

                .value {
                    border-radius: 5px;
                    font-weight: bold;
                    margin-top: @pxh-5;
                }

                .filter-selection-panel {
                    .label {
                        .font-size-2px(8);
                        color: #959595;
                        margin-right: @px-15;
                    }
                }
            }
        }

        .row-class {
            .flex-row;
            padding: @pxh-10 @px-10 @pxh-5 @px-10;
            gap: 10px;

            .left-panel {
                .flex-column;
                flex: 1;
                gap: 10px;

                .statistic-container {
                    .flex-column;
                    justify-content: center;
                    .main-bg-white;
                    .padding-2px(8);
                    height: @pxh-100 * 1.8;
                    box-shadow: 0 0 5px 0 #0000001a;
                    border-radius: 5px;

                    & > div.ant-row {
                        flex: 1;
                    }

                    .title {
                        .font-size-2px(8);
                        color: #8e8e8e;
                        text-align: center;
                    }

                    .value {
                        .font-size-2px-bold(13);
                        margin-top: @pxh-5;
                        text-align: center;
                    }
                }

                .alarm-summary-for-search-container {
                    .main-bg-white;
                    .padding-2px(8);
                    flex: 1;
                    box-shadow: 0 0 5px 0 #0000001a;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;

                    .title {
                        margin-bottom: @pxh-10;
                        &-text {
                            .font-size-2px-bold(9);
                        }
                    }

                    .alarm-det-container {
                        height: @pxh-100 * 5;
                        overflow-y: auto;
                        flex-grow: 1;
                        .padding-2px(2, 3);

                        .ant-timeline {
                            .ant-timeline-item {
                                padding-bottom: 10px;
                                .alarm-detail-box {
                                    .content {
                                        .flex-column;
                                        border: 1px solid @main-border-color;
                                        margin-top: @pxh-5;

                                        .title-cont {
                                            .flex-row-between;
                                            border-bottom: 1px solid @main-border-color;
                                            .ant-btn-link {
                                                .font-size-2px(7);
                                            }
                                            .count-container {
                                                .flex-column-center;
                                                .padding-2px(2, 0);
                                                min-width: @px-100 * 0.7;
                                                border-radius: 3px;
                                                margin-left: @px-5 * 0.8;
                                                box-shadow: 1px 1px 3px #ccc;
                                                margin-top: -@pxh-5 * 2;
                                            }

                                            .ant-space-item {
                                                .anticon {
                                                    .font-size-2px(8);
                                                    margin-right: @px-10;
                                                }
                                            }
                                        }
                                        .columns {
                                            .flex-row;

                                            .first-column {
                                                flex: 1;
                                                .padding-2px(2, 3);
                                                .margin-2px(2.5, 0);
                                                .alarm-di-me {
                                                    .item {
                                                        .flex-row-between;
                                                        .font-size-2px(6);

                                                        span {
                                                            &:first-child {
                                                                .font-size-2px-bold(6);
                                                                color: #9a9a9a;

                                                                &::after {
                                                                    content: "\003A";
                                                                    .padding-2px(0, 2.5);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .second-column {
                                                flex: 1;
                                                border-left: 2px dotted #cccccc;
                                                .padding-2px(2, 3);
                                                .margin-2px(2.5, 0);
                                                .alarm-di-me {
                                                    .item {
                                                        .flex-row-between;

                                                        span {
                                                            &:first-child {
                                                                .font-size-2px-bold(6);
                                                                color: #9a9a9a;

                                                                &::after {
                                                                    content: "\003A";
                                                                    .padding-2px(0, 2.5);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .time {
                                        .font-size-2px(6);
                                        margin-top: @pxh-5;
                                    }
                                }
                            }
                        }
                    }
                }

                .company-pnl-summary-panel {
                    .flex-row;
                    .main-bg-white;
                    .padding-2px(8);
                    padding-right: @px-5;
                    box-shadow: 0 0 5px 0 #0000001a;
                    border-radius: 5px;

                    .custom-skeleton {
                        .padding-2px(7.3);
                    }

                    .c-left {
                        width: 100%;
                        .title {
                            height: @pxh-5;
                            color: @main-app-color;
                            &-text {
                                .font-size-2px-bold(9);
                            }
                        }

                        .world-map-container {
                            .flex-column;
                            padding-right: @px-10;
                        }
                    }

                    .c-right {
                        width: 40%;
                        .flex-column;
                        border-left: 1px solid @main-border-color;

                        .main-content {
                            & > div {
                                .flex-column;
                                flex: 1;

                                .desc-tags {
                                    .flex-row;
                                    justify-content: end;

                                    .sorter-button {
                                        margin-right: @px-10;
                                    }
                                    .tag-div {
                                        .padding-2px(2, 6);
                                        display: inline;
                                        background: #f6f6f6;
                                        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.35);
                                        border-radius: 5px 0px 0px 5px;
                                        margin-right: @px-5 * -1;
                                        width: 100%;

                                        span {
                                            .font-size-2px(8);
                                        }

                                        .select-filter-div {
                                            .ant-select {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }

                                .title-box {
                                    align-self: flex-end;
                                    margin-bottom: @pxh-10;
                                }

                                .filter-div {
                                    display: flex;
                                    justify-content: flex-end;
                                    margin-top: @pxh-10;

                                    .search-input-filter {
                                        margin-right: @px-5;
                                        margin-left: @px-10;
                                        width: 100%;
                                    }
                                }

                                .content {
                                    .flex-row;
                                    margin-top: @pxh-10;
                                    padding-bottom: @pxh-10;
                                    height: @pxh-100 * 3.3;

                                    .country-values {
                                        .flex-column;
                                        flex: 1;
                                        overflow: auto;

                                        .title-value-div {
                                            .flex-row-between;
                                            .padding-2px(2.5, 5);
                                            // flex: 1;
                                            padding-left: @px-15;
                                            width: 100%;

                                            .title {
                                                width: 80%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right-panel {
                .flex-column;
                flex: 1;
                gap: 10px;

                .users-by-pnl-panel.search {
                    .main-bg-white;
                    .padding-2px(8);
                    flex: 2;
                    box-shadow: 0 0 5px 0 #0000001a;
                    border-radius: 5px;

                    .title-box {
                        &-text {
                            .font-size-2px-bold(9);
                            color: @main-app-color;
                        }

                        .pgNav {
                            margin: @pxh-5 0;
                            .flex-row-between;

                            .select-container {
                                .flex-row;

                                .ant-select {
                                    margin-right: @px-5;
                                    min-width: 75%;
                                }
                            }
                        }
                    }
                }

                .chart-summary-container {
                    .main-bg-white;
                    .padding-2px(8);
                    box-shadow: 0 0 5px 0 #0000001a;
                    border-radius: 5px;
                    flex: 1;

                    .pnl-chart-summary-panel.search {
                        .title {
                            margin-bottom: @pxh-10;
                            .title-text {
                                .font-size-2px-bold(9);
                                color: @main-app-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .filter-dropdown-header-panel {
        background-color: #fafafa;
        .outer-main-container {
            display: grid;
            grid-template-columns: 30% 30% 30%;

            .options-container {
                .padding-2px(5);

                .title {
                    .font-size-2px(7);
                    cursor: pointer;

                    span {
                        margin-right: @px-5;
                    }
                }
                .content {
                    .padding-2px(5, 0);
                    overflow: hidden auto;
                    max-height: @pxh-100;

                    &.hide {
                        display: none;
                    }

                    .filter-keyword-search {
                        .flex-column;
                        margin-right: @pxh-5;

                        .ant-checkbox-group {
                            display: grid;
                            grid-template-columns: auto auto;
                        }

                        .keyword-search-box {
                            .keyword-search-input {
                                .margin-2px(2.5, 0);
                                flex: 9;
                            }
                            .ant-checkbox-wrapper {
                                flex: 1;
                            }
                            .select-all {
                                margin-bottom: @pxh-5;
                            }
                        }
                    }

                    .intraday-filter-ID-search {
                        .filter-input.ant-input-affix-wrapper {
                            margin-left: 0;
                        }
                        .intraday-filter-ID-search-result {
                            .ant-tag {
                                margin-top: @pxh-5;
                            }
                        }
                    }
                }
            }
        }
        .buttons-panel {
            display: block;
            border-top: 1px solid @main-border-color;

            .buttons {
                .padding-2px(7.5);
            }
        }
    }
}

// #endregion end intraday search;

.rebate-account-container {
    width: 100%;

    & + .accounts-container {
        margin-top: @pxh-2;
    }
}

.intraday-monitor-create-edit-form {
    .rebate-account-container {
        .rebate-account-input {
            background: transparent;
        }
    }

    .accounts-container {
        margin-top: @pxh-10;

        .account-tag {
            background: @main-app-color;
            color: @color-white;

            .anticon-close {
                color: @color-white;
            }
        }
    }

    .main-panel-left {
        padding-right: @px-30;

        .filter-panel {
            .main-border-2;
            .title-panel {
                .padding-2px(2.5, 2.5);
                .font-size-2px-bold(8);
                .main-border-2-bottom;
                .flex-row-between;
                align-items: center;

                .left {
                    padding-left: @px-5;
                    color: @main-app-color;
                }
            }

            & > .content {
                .padding-2px(4);
                background: #f9f9f9;
            }
        }

        .filter-group-panel {
            .main-border-2;
            .padding-2px(5);
            padding-top: @pxh-5;
            margin-bottom: @pxh-10 * 0.8;
            background: @bg-white;
            border-radius: 3px;

            &:last-child {
                margin-bottom: 0;
            }

            & > .btn-group {
                .flex-row;
                justify-content: flex-end;
                padding-bottom: @pxh-5;

                .btn-delete {
                    margin-right: -5px;
                }
            }

            .level-group {
                .ant-form-item.form-select {
                    margin-bottom: @pxh-10 * 0.8;
                }
            }

            .orcondition-card-header {
                .flex-row-between;

                .title {
                    .font-size-2px-bold(8);
                    color: @main-app-color;
                }
            }

            .orcondition-group-panel {
                .ant-card-head {
                    .padding-2px(0);
                    border-bottom: none;

                    .ant-card-head-title {
                        border-bottom: 3px solid @main-app-color;
                        margin-bottom: @pxh-10 * 0.8;
                    }
                }

                .ant-card-body {
                    .padding-2px(0);

                    .orcondition-item {
                        .padding-2px(5);
                        padding-top: @pxh-5;
                        background: @bg-white;
                        border-radius: 3px;

                        .btn-group {
                            .flex-row;
                            justify-content: flex-end;

                            .btn-delete {
                                margin-right: -5px;
                            }
                        }

                        .content {
                            .and-item {
                                .padding-2px(1.5, 2);
                                .flex-row-between;
                                align-items: flex-start;

                                .ant-form-item {
                                    flex: 1;
                                    margin-bottom: 0;
                                }
                            }

                            .add-btn-panel {
                                .margin-2px(0, 1.5);
                                .padding-2px(1.5, 10);
                                .flex-row;
                                justify-content: center;
                                border: 1px dashed #b7b7b7;
                                background: #f1f1f1;
                                color: #7d7d7d;
                                cursor: pointer;
                            }
                        }
                    }

                    & > .orcondition-wrapper {
                        .main-border-2;
                        border-radius: 5px;
                        margin-bottom: @pxh-10 * 0.8;

                        .orcondition-item {
                            &::after {
                                .font-size-2px-bold(7);
                                .padding-2px(1, 10);
                                position: absolute;
                                right: calc(50% - (@px-30));
                                content: " OR ";
                                color: @color-white;
                                background: @main-app-color;
                                margin-top: @pxh-10 * 0.4;
                                border-radius: 30px;
                                z-index: 1;
                            }
                        }

                        &:last-child {
                            .orcondition-item {
                                margin-bottom: 0;

                                &::after {
                                    display: none;
                                }
                            }
                        }

                        &:nth-last-child(2) {
                            .orcondition-item {
                                margin-bottom: 0;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .orcondition-add-button-container {
                        .padding-2px(1.5, 10);
                        .flex-row;
                        justify-content: center;
                        border: 1px dashed #b7b7b7;
                        background: #f1f1f1;
                        color: #7d7d7d;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .main-panel-right {
        padding-left: @px-20;
        border-left: 1px solid @border-color-4;

        .filter-panel {
            padding-bottom: @pxh-15;

            .title-panel {
                padding-bottom: @pxh-5;
                .font-size-2px-bold(8);
                .flex-row-between;
                align-items: center;
                border-bottom: 3px solid @main-app-color;
                color: @main-app-color;
            }

            .content {
                .item {
                    .main-border-4-bottom;
                    .padding-2px(5, 0);

                    &.view {
                        .flex-row-between;
                        align-items: center;
                    }

                    .desc-panel {
                        margin-top: @pxh-5;
                        .desc-text {
                            .text {
                                margin-right: @px-15;
                                &::after {
                                    content: ":";
                                }
                            }
                        }
                        .details {
                            margin-top: @pxh-5;
                            max-height: @pxh-100 * 3;
                            overflow-y: auto;
                        }
                    }

                    .features-div {
                        .flex-row-between;
                        align-items: center;
                        margin-top: @pxh-5;

                        .left {
                            & > button {
                                margin-right: @px-10;
                            }
                        }

                        .right {
                            flex: 1;
                            margin-left: @px-10;

                            .search-input {
                                background: transparent;
                                border-radius: 3px;
                            }
                        }
                    }

                    .form-checkboxgroup {
                        .ant-checkbox-group {
                            & > label {
                                min-width: 22%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.intraday-alarm-listing-container {
    background: @bg-white;

    .alarm-info-container {
        .title {
            .font-size-2px-bold(7);
        }

        .desc {
            color: #7b7b7b;
        }
    }

    .trigger-rules {
        margin-top: @pxh-5;
        padding-top: @pxh-5;
        border-top: 1px dashed @border-color-2;
        //text-align: right;

        .item-group {
            //display: flex;

            .item {
                display: inline-block;

                &::before {
                    padding-left: @px-5;
                }
            }
        }
    }
}

@priority-color-0: #989898;
@priority-color-1: #096dd9;
@priority-color-2: #ff7e00;
@priority-color-3: #f00f00;
@priority-color-4: #970000;
.priority-tag-based {
    .font-size-2px-bold(7);
    .padding-2px(0, 2);
    border-radius: 5px;
}

.priority {
    &-tag {
        .padding-2px(1, 2.5);
        display: inline-block;
        min-width: @px-100 * 0.5;
        border-radius: 3px;
        text-align: center;
    }
    &-color {
        &-0 {
            color: @color-white;
            background: @priority-color-0;

            &-text {
                color: @priority-color-0;
                background: transparent;
            }
            &-border-left {
                border-left: 5px solid @priority-color-0;
            }
            &-tag {
                .priority-tag-based;
                background: #f5f5f5;
                border: 1px solid @priority-color-0;
                color: @priority-color-0;
            }
        }
        &-1 {
            color: @color-white;
            background: @priority-color-1;

            &-text {
                color: @priority-color-1;
                background: transparent;
            }
            &-border-left {
                border-left: 5px solid @priority-color-1;
            }
            &-tag {
                .priority-tag-based;
                background: #d8e8fa;
                border: 1px solid @priority-color-1;
                color: @priority-color-1;
            }
        }
        &-2 {
            color: @color-white;
            background: @priority-color-2;

            &-text {
                color: @priority-color-2;
                background: transparent;
            }
            &-border-left {
                border-left: 5px solid @priority-color-2;
            }
            &-tag {
                .priority-tag-based;
                background: #ffecda;
                border: 1px solid @priority-color-2;
                color: @priority-color-2;
            }
        }
        &-3 {
            color: @color-white;
            background: @priority-color-3;

            &-text {
                color: @priority-color-3;
                background: transparent;
            }
            &-border-left {
                border-left: 5px solid @priority-color-3;
            }
            &-tag {
                .priority-tag-based;
                background: #ffe4e4;
                border: 1px solid @priority-color-3;
                color: @priority-color-3;
            }
        }
        &-4 {
            color: @color-white;
            background: @priority-color-4;

            &-text {
                color: @priority-color-4;
                background: transparent;
            }
            &-border-left {
                border-left: 5px solid @priority-color-4;
            }
            &-tag {
                .priority-tag-based;
                background: #fabcbc;
                border: 1px solid @priority-color-4;
                color: @priority-color-4;
            }
        }
    }
}

.event-info-container {
    .item {
        .flex-row-between;

        span {
            &:first-child {
                .font-size-2px-bold(6);
                color: #9a9a9a;

                &::after {
                    content: "\003A";
                    .padding-2px(0, 2.5);
                }
            }
            &.dim {
                min-width: 7.5vw;
                text-align: right;
            }
        }

        &.kena-liao {
            span {
                &:last-child {
                    .font-size-2px-bold(6);
                    color: @color-error;
                    text-shadow: 1px 1px 1px #d2d2d2;
                }
            }
        }
    }
}

.metric-info-container {
    .item-group {
        .padding-2px(1.5, 1.5);
        .main-border-2;
        border-radius: 3px;
        background: #fcfcfc;

        &:not(:first-child) {
            padding-top: @pxh-2 * 1.5;
            margin-top: @pxh-2 * 1.5;
            border-top: 1px solid #e4e4e4;
        }

        .item {
            &:not(:first-child) {
                &::before {
                    content: "\0026";
                    display: inline-block;
                    padding-right: @px-5;
                }
            }
            & > div {
                .main-border-2;
                .padding-2px(0.5, 3.5);
                display: inline-block;
                border-radius: 3px;
                & > span {
                    &:first-child {
                        .font-size-2px-bold(6);
                    }
                    &:last-child {
                        .font-size-2px-bold(6);
                    }
                    &.operator {
                        color: #9a9a9a;
                    }
                }
            }
        }

        &.kena-liao {
            border-color: #f2a7a2;
            background: #fff6f6;

            &::before {
                content: "\2713";
                position: absolute;
                left: 0;
                font-size: 10px;
                color: #ffffff;
                background: #fc5e54;
                border-radius: 30px;
                padding: 1px 5px;
            }
        }
    }
}

.alarm-historical-popover-title {
    .font-size-2px-bold(8);
}

.alarm-historical-popover-content {
    .ant-popover-inner-content {
        .padding-2px(5, 2.5);
        display: flex;
        flex-direction: column;

        .alarm-historical-popover-timeline-container {
            .padding-2px(5, 2.5);
            max-height: 50vh;
            overflow-y: auto;
            flex-grow: 1;
        }
    }
}

.alarm-popover-detail-container {
    .main-border-2;
    .padding-2px(4);
    border-radius: 5px;
    min-width: @px-100 * 5.5;

    .title-container {
        .flex-row-between;
        margin-top: @pxh-10 * -0.8;
        margin-left: @px-10 * -0.8;

        .left {
            .priority-div {
                .padding-2px(2, 4);
                display: inline-block;
                min-width: @px-100 * 0.7;
                text-align: center;
                border-radius: 5px 0px 5px 0px;
                box-shadow: inset -1px -1px 3px #8a6161;
            }
            .comment-by {
                font-size: @font-size-14;
                color: @subtitle-color;
                display: flex;
                align-items: center;
                .padding-2px(3.5, 7);

                .user-name {
                    margin-left: @px-5;
                }
            }
        }

        .right {
            .font-size-2px-bold(7);
            color: #686868;
            padding-top: @pxh-10 * 0.6;
        }
    }

    .comment-content {
        .padding-2px(2.5, 4);
        width: @px-100 * 6;
        font-size: @font-size-12;
    }

    .content-container {
        .main-border-2;
        margin-top: @pxh-10 * 0.8;

        .flex-row-between;

        .left {
            flex: 1;
            position: relative;
        }
        .right {
            flex: 1.4;
            position: relative;

            .content {
                text-align: right;
            }
        }

        .theader {
            .padding-2px(2.5, 4);
            background: #fafafa;
            border-bottom: 1px solid @border-color-2;
        }

        .content {
            .padding-2px(2.5, 4);

            .alarm-di-me {
                .item {
                    .flex-row-between;

                    span {
                        &:first-child {
                            .font-size-2px-bold(6);
                            color: #9a9a9a;

                            &::after {
                                content: "\003A";
                                .padding-2px(0, 2.5);
                            }
                        }
                    }
                }
            }
        }
    }
}

.alarm-summary-container {
    .padding-2px(7);
    background: @bg-white;
    border-radius: 5px;

    .title {
        .flex-row-between;
        align-items: center;
        margin-bottom: @pxh-10;

        .left {
            .flex-row;

            .anticon {
                .font-size-2px(9);
            }

            .title-text {
                .font-size-2px-bold(9);
                color: @main-app-color;
                margin-left: @px-5;
            }
        }
        .right {
            .total-container {
                display: flex;
                align-items: center;
                .padding-2px(2.5, 5);
                .main-border-2;
                border-radius: 5px;
                background: #f6f6f6;
                box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.35);

                & > div {
                    padding-left: 7px;

                    & > span:not(.breachSeverity-text) {
                        display: inline-block;
                        min-width: @px-20;
                    }
                }

                .total-text {
                    padding-right: @px-10;
                    margin-right: @px-10;
                    border-right: 1px solid @border-color-2;
                }

                .breachSeverity-text {
                    .font-size-2px-bold(7);
                }
            }
        }
    }

    .alarm-summary-list {
        height: @pxh-100 * 2.05;
        overflow-y: auto;

        .item {
            .padding-2px(0, 5);
            .flex-row-between;
            align-items: center;
            margin-top: @pxh-10 * 0.6;
            border-top: 1px solid #e0e0e0;

            .right {
                .flex-row;
                justify-content: flex-end;
                margin-top: -3px;

                .count-container {
                    .flex-column-center;
                    .padding-2px(3, 0);
                    min-width: @px-10 * 3.5;
                    border-radius: 3px;
                    margin-left: @px-5 * 0.8;
                    box-shadow: 1px 1px 3px #ccc;
                    cursor: pointer;

                    & > span {
                        .font-size-2px(6);
                        line-height: 1.1;

                        &:first-child {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

// #region Toxic client check
.toxic-client-check-historical-restriction-audit-log-tab,
.toxic-client-check-create-import-audit-log-tab,
.toxic-client-check-list-tab {
    .toxic-client-check-tab-content-nav-bar {
        .flex-row-between;
        margin-bottom: @pxh-10;

        .excel-related-btn-group {
            .ver-icon {
                font-size: @font-size-18;
            }

            & > button {
                margin-left: @px-10;
            }
        }
    }
    .account-info-col {
        .account-item {
            .flex-column;

            & > span:first-child {
                color: #8b8b8b;
            }
            & > span:nth-child(2) {
                font-weight: bold;
            }
        }
    }

    .tccl-grouped-info-col {
        .deductions-grouped-item {
            .flex-row-between;

            & > span:nth-child(2) {
                padding-left: @px-10;
            }
        }

        .label-attr-grouped-item {
            .flex-row-between;

            & > span:first-child {
                width: 40%;
                text-align: left;
                overflow-wrap: anywhere;
                word-break: normal;
            }

            & > span:last-child {
                width: 60%;
                padding-left: @px-5;
                text-align: right;
                overflow-wrap: anywhere;
                word-break: normal;
                display: flex;
                justify-content: right;
            }
        }
    }

    .tccl-expandable-table {
        .flexi-datatable {
            .flexi-table {
                .ant-table.ant-table-small.ant-table-layout-fixed.ant-table-scroll-horizontal {
                    margin: 0px !important;
                }
            }
            .ant-table-pagination {
                margin-bottom: @pxh-10 !important;
            }
        }

        &.more-than-one {
            margin-top: @pxh-15;
        }

        .triggered-by-accounts-col {
            ul {
                margin-bottom: 0px !important;
            }
        }
    }

    .tccl-expanded-row-container {
        .margin-2px(3);
    }

    .status-col {
        &-statusTag {
            .padding-2px-important(1.5, 3);
            border-radius: 5px !important;
            width: @px-100 * 0.7;
            background-color: @bg-white !important;
            font-weight: bold;
            text-align: center;
            margin-top: @pxh-10 * 0.8;
        }

        // TOXIC CLIENT CHECK ENTRY STATUS
        &-sts {
            &-0 {
                // 0: Following
                color: #ff7400 !important;
                border: 1px solid #ff7400;

                &-text {
                    background-color: transparent !important;
                    color: #ff7400;
                    font-weight: bold;
                }
            }
            &-1 {
                // 1: Done
                color: #00897b !important;
                border: 1px solid #00897b;

                &-text {
                    background-color: transparent !important;
                    color: #00897b;
                    font-weight: bold;
                }
            }
            &-2 {
                // 2: Holding
                color: red !important;
                border: 1px solid #ff4d4f;

                &-text {
                    background-color: transparent !important;
                    color: #ff4d4f;
                    font-weight: bold;
                }
            }
            &-null {
                // null: non-existant
                color: grey !important;
                border: 1px solid grey;

                &-text {
                    background-color: transparent !important;
                    color: grey;
                    font-weight: bold;
                }
            }
        }
    }

    .inspector-col {
        .title {
            line-height: 1.2;
            color: #666666;
        }
        .value {
            margin-bottom: @pxh-10 * 0.4;
            font-weight: 500;
        }
        .extra {
            margin-bottom: @pxh-10 * 0.4;
            // color: #8b8b8b;
            font-weight: bold;
        }
        .source-tag {
            margin-bottom: @pxh-10 * 0.4;
            display: inline-block;
            word-break: break-all;
            color: @color-white;
            background-color: #444;
            border: 1px solid #d9d9d9;
            border-radius: 3px;
            line-height: 20px;
            font-size: @font-size-12;
            padding: 0 7px;
            max-width: 100%;
        }
    }

    .grouped-dates-col {
        .created-datetime,
        .last-updated-datetime {
            .by-user {
                line-height: 1.2;
                color: #8b8b8b;

                &-title {
                    font-weight: bold;
                    color: #8b8b8b;
                }
            }
            .value {
                margin-bottom: @pxh-10 * 0.8;
                font-weight: 500;
            }
        }
    }

    .account-client-info-col {
        .account-client-id {
            font-weight: bold;
        }
    }

    .header-title-container {
        .flex-column;
    }
    .header-title-text {
        min-height: 40px;
    }

    .vertical-actions-col {
        .flex-column;
        align-items: center;
    }

    .all-restrictions-col {
        .unordered-list-style {
            padding-left: @px-20;
        }
    }
}

.tccl-mode-tag {
    .padding-2px-important(1.5, 2);
    border-radius: 13px;
    width: @px-100 * 0.6;
    margin-top: @pxh-5;
    text-align: center;

    &-Manual {
        background-color: #d3d3d3;

        span {
            margin-left: @px-2;
        }
    }

    &-Auto {
        background-color: #c3e6cd;
        color: #24663b;

        span {
            margin-left: @px-2;
        }
    }
}

.toxic-client-check-upload-modal-container {
    .total-count-info {
        display: flex;

        p {
            .font-size-2px-bold(8);
            margin-right: @px-20;
            width: @px-100 * 2.5;
        }
        .success {
            color: @color-sucess;
        }
        .failed {
            color: @color-error;
        }
        .partial {
            color: #ec942c;
        }
    }

    .preview-upload-remarks-col {
        color: @color-error;
    }

    .preview-upload-unconfirmed-brands-tab {
        .ant-form-item {
            margin-bottom: 0 !important;
        }

        .header-title-container {
            .flex-row;

            .header-title-text {
                margin-right: @px-20;
            }
        }
    }

    .footer-buttons {
        .flex-row;
        justify-content: flex-end;

        .download-err-checkbox {
            margin-top: @pxh-2 * 2.1;
            margin-right: @px-10;
        }
    }

    .update-changes-table {
        .before {
            color: #808080;
        }

        .display-name {
            .font-bold;
        }
    }
}

.toxic-client-check-audit-log-container {
    .timeline-box {
        .timeline-title {
            font-size: @font-size-14;
            margin-bottom: @pxh-10 * 0.7;
        }

        .timeline-changes {
            .padding-2px(4, 8);
            .margin-2px(2, 0);
            .main-border;
            border-radius: 0 5px 5px 5px;

            .timeline-items-title-div {
                span {
                    font-size: @font-size-18;
                    font-weight: 800;
                    display: inline-block;
                    position: relative;
                    color: #248f98;

                    &.attr-title {
                        margin-top: @pxh-5 !important;
                    }
                    &.restrict-title {
                        color: #ff6f4b !important;
                    }
                }
            }

            .item-log-name {
                .font-bold;
            }

            .before {
                color: #808080;
            }

            .restriction-table-div {
                width: 70%;
                margin-bottom: @pxh-10;

                .client-restriction-footer {
                    font-weight: 650;
                    font-size: @font-size-11;
                }
            }

            .entry-table-div {
                margin-bottom: @pxh-10;
                width: 80%;
            }
        }

        .timeline-datetime-info {
            font-size: @font-size-11;
            color: #474747;
            .margin-2px(0, 5);
        }
    }
}

.toxic-client-check-create-import-audit-log-tab {
    .import-audit-log-remarks-col {
        color: @color-error;
    }

    .import-overall-status-col {
        &-0 {
            color: @color-error;
        }
        &-1 {
            color: @color-sucess;
        }
    }
}

.toxic-client-check-acc-restriction-modal-form-container {
    .tcc-restriction-title {
        margin-bottom: @pxh-10;

        span {
            .font-size-2px-bold(9);
        }
    }
}

.toxic-client-restriction-modal-form-container {
    .client-restriction-notes {
        margin-bottom: @pxh-5;
    }
    .section-title {
        .font-size-2px-bold(8);
    }
}

.toxic-client-check-entry-create-edit-form {
    .top {
        .unedittable-container {
            .flex-row;
            padding-bottom: @pxh-20;

            .left {
                .item {
                    .padding-2px(0, 7.5);
                    display: inline-block;
                    min-width: @px-100 * 1.5;
                    border-right: 1px solid @main-border-color;

                    & > span {
                        display: block;
                        font-size: @font-size-14;
                        text-align: center;
                        color: #6e6e6e;

                        &:first-child {
                            .font-size-2px-bold(10);
                            color: @main-app-color;
                        }
                    }
                }
            }

            .right {
                width: @px-100 * 3;
            }
        }
    }
    .form-container {
        .flex-row-between;

        .left-panel,
        .right-panel {
            width: 50%;

            .basic-info-config,
            .deductions-info-config,
            .label-info-config {
                .padding-2px(10);
                margin-right: @px-30;
                padding-bottom: @pxh-20;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;

                .config-title-div {
                    margin-bottom: @pxh-15;

                    span {
                        .font-size-2px-bold(8);
                        display: inline-block;
                        padding-bottom: @pxh-10;
                        margin-bottom: @pxh-20;
                        position: relative;
                    }
                    span::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-bottom: 5px solid @main-app-color;
                        width: @px-100 * 0.7;
                    }
                }
            }
            .basic-info-config {
                margin-left: @pxh-20;
            }
            .deductions-info-config {
                margin-top: @pxh-20;
            }
        }
    }
}

.toxic-client-check-custom-groupedfilter-component {
    border: 1px solid #f0f0f0;

    .fixed-size-grouped-filter-container {
        max-height: @pxh-100 * 2.8;
        overflow: auto;
        margin-bottom: @pxh-10;
    }

    .tcc-custom-filter-group-title {
        .font-size-2px-bold(8);
        margin-bottom: @pxh-5;
    }

    .custom-form-filter-row {
        margin-bottom: @pxh-10;
    }

    .custom-flexi-filter {
        .ant-collapse-header {
            align-items: center !important;
            flex-wrap: wrap !important;
            font-size: @font-size-10;
            .ant-collapse-extra {
                .flex-row;
                justify-content: center;
            }
        }

        .ant-collapse-content-box {
            .padding-2px-important(5, 8);
            background-color: @bg-white;
        }

        .ant-divider-horizontal {
            margin-bottom: @pxh-10 !important;
            margin-top: 0 !important;
        }
    }

    .filter-by-category-collapsible {
        .ant-collapse-header {
            font-size: @font-size-13;
            font-weight: 600;
        }
    }
}

.tcc-custom-filter-tags-container {
    border-right: 1px solid @main-border-color;
    border-left: 1px solid @main-border-color;
    border-bottom: none;

    .filter-tags-div {
        .padding-2px(2.5, 8.5);

        .ant-tag {
            display: inline-flex;
            margin-bottom: 8px;

            .text-wrap {
                max-width: @px-100 * 6;
                display: inline-block;
                text-wrap: wrap;
            }
        }
    }
}

// Toxic Client Check Batch Update Restrictions
.batch-update-restrictions-container {
    .editable-cell {
        position: relative;
    }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
    }

    .editable-row:hover .editable-cell-value-wrap {
        padding: 4px 11px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    .ant-form-item {
        &.form-switch {
            margin-bottom: 0 !important;
        }
        &.form-text {
            margin-bottom: 0 !important;
        }
    }
}

// Toxic Client Label Configuration
.toxic-client-label-create-edit-form {
    .main-panel-right {
        padding-left: @px-20;
        border-left: 1px solid @border-color-4;

        .toxic-label-attribute-container {
            border: 1px solid #d0d0d0;

            .title-div {
                .flex-row-between;
                .padding-2px(5, 7.5);
                border-bottom: 1px solid #d0d0d0;
            }

            .list-item-div {
                min-height: @pxh-100 * 2.75;

                .item-container {
                    .flex-row-between;
                    .padding-2px(5, 7.5);
                    align-content: center;
                    border-bottom: 1px solid @main-border-color;

                    .left-panel {
                        .flex-row;
                        justify-content: center;
                        padding-right: @px-10;

                        .icon-panel {
                            font-size: @font-size-26;
                            padding-right: @px-10;
                        }
                        .title {
                            .font-size-2px-bold(8);
                            .flex-row;

                            .required-mark {
                                color: @color-error;
                                margin-left: 5px;
                            }
                        }
                        .subtitle {
                            font-size: @font-size-14;
                            color: @subtitle-color;
                        }
                    }

                    .right-panel {
                        .flex-row;
                        justify-content: center;

                        .status-desc {
                            .flex-column;
                            justify-content: center;
                            text-align: center;
                            width: @px-100 * 0.7;
                            margin-right: @px-30;

                            span {
                                .padding-2px(0, 5);
                                background: @bg-black !important;
                                color: @color-white;
                                border-radius: 20px;

                                &.active {
                                    background: @main-app-color !important;
                                }
                            }
                        }

                        .buttons-group {
                            .flex-row;
                            align-items: center;
                            font-size: @font-size-14;

                            & > button:first-child {
                                border-right: 1px solid @main-border-color;
                                padding-right: @px-15;
                                margin-right: @px-10;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Toxic Client Check Report Dashboard
.toxic-client-check-report-dashboard-tab {
    .header-title-container {
        text-align: center;

        .header-title-text {
            &.marked {
                color: @color-error;
            }
        }
    }
    .summary-row {
        .ant-table-cell {
            .bold-title;
            font-size: @font-size-13;

            &:nth-child(2) {
                .text-center;
            }
            &:not(:nth-child(-n + 2)) {
                .text-right;
            }
        }
    }
}

// #endregion

// Linked Account Record
.linked-account-record-container {
    padding-top: @pxh-10;

    .main-container {
        .padding-2px(5);

        .account-record,
        .group-settings,
        .equity-monitor {
            .flex-column;
            margin-top: @pxh-10 * 1.5;
            gap: @px-25;

            .flexi-datatable {
                .flexi-filter {
                    .ant-collapse-header {
                        .font-bold;
                        font-size: @font-size-15 !important;
                        color: black;
                        cursor: default;
                    }
                }
                .ant-table-body {
                    table tbody.ant-table-tbody {
                        tr {
                            &.today-row {
                                td.ant-table-cell {
                                    background-color: #f39f46 !important;
                                    border-color: #f77e3f !important;
                                    color: white;
                                }
                                &:hover td.ant-table-cell,
                                td.ant-table-cell.ant-table-column-sort {
                                    background-color: #ea863e !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .account-record {
            .lar-extra-buttons {
                .flex-row;
                margin-left: @px-10;

                .lar-s {
                    .flex-column;
                    justify-content: center;
                }
            }
        }
    }
}

//Alarm Dashboard - START
.alarm-dashboard-main-container {
    .top-header {
        margin-bottom: @pxh-5;
        .title-label {
            .font-size-2px-bold(9);
            color: @main-app-color;
            margin-bottom: @pxh-15;
        }
    }

    .card-container {
        background: @color-white;
        border-radius: 5px;
        margin-bottom: @pxh-10;

        & > .header-container {
            .flex-row-between;
            .padding-2px(4, 8);

            & > .left {
                .flex-row;
                justify-content: flex-start;
                align-items: center;

                .btn-filter {
                    border-radius: 5px;
                    margin-right: @px-20;

                    .anticon {
                        .font-size-2px(9);
                    }
                }

                .sound-switcher-container {
                    .flex-row;
                    align-items: center;

                    .anticon {
                        .font-size-2px(9);
                        margin-right: @px-10;
                    }
                }
            }

            & > .right {
                .flex-row;
                justify-content: flex-end;
                align-items: center;

                .extra-item-container {
                    .flex-row;
                    column-gap: @px-5;
                    align-items: center;

                    .ant-segmented {
                        &.padding-size- {
                            &1 {
                                margin-right: calc((70px * 1) + 10px);
                            }
                            &2 {
                                margin-right: calc((70px * 2) + 10px);
                            }
                            &3 {
                                margin-right: calc((70px * 3) + 10px);
                            }
                            &4 {
                                margin-right: calc((70px * 4) + 10px);
                            }
                            &5 {
                                margin-right: calc((70px * 5) + 10px);
                            }
                        }
                    }
                }

                .counter-container {
                    position: absolute;
                    border-radius: 5px 5px 15px 15px;
                    margin-top: 5px;

                    &:nth-child(2) {
                        margin-right: calc(70px * 3);
                    }
                    &:nth-child(3) {
                        margin-right: calc(70px * 2);
                    }
                    &:nth-child(4) {
                        margin-right: calc(70px * 1);
                    }
                    &:nth-child(5) {
                        margin-right: 0px;
                    }

                    .flag-container {
                        .flex-column-center;
                        justify-content: center;
                        width: 65px;
                        height: 70px;

                        & > span {
                            .font-size-2px-bold(10);
                            display: block;

                            &.label {
                                .font-size-2px-bold(8);
                            }
                        }
                    }
                }
            }
        }

        .extra-filter-panel {
            .padding-2px(2.5, 0);
            background: #f0f0f0;
            box-shadow: inset 0px 1px 3px rgb(0 0 0 / 35%);

            .options-item {
                .padding-2px(1, 8);
                .flex-row;
                justify-content: flex-start;

                .label {
                    .font-size-2px-bold(7);
                    min-width: 100px;
                }
                .options-container {
                    .flex-row;
                    justify-content: flex-start;

                    .item {
                        .font-size-2px(7);
                        .padding-2px(0, 5);
                        min-width: 120px;
                        background: @color-white;
                        cursor: pointer;
                        border-radius: 5px;
                        color: @color-black;
                        text-align: center;
                        margin-right: @px-10 * 0.5;

                        &.active {
                            background: @main-app-color;
                            color: @color-white;
                        }
                    }
                }
            }
        }

        .main-content-container {
            .flex-row;
            .padding-2px(6, 8);

            & > .left {
                width: 50%;
                padding-right: @px-15;
                border-right: 1px solid @main-border-color;

                & > .title {
                    .font-size-2px-bold(8);
                    color: @main-app-color;
                    margin-bottom: @pxh-10;
                }

                .timeline-container {
                    max-height: 70vh;
                    overflow: auto;
                    padding-top: 15px;
                    padding-right: 5px;
                }
            }
            & > .right {
                width: 50%;
                padding-left: @px-15;

                .assigned-event-panel {
                    .header-container {
                        .flex-row-between;
                        margin-bottom: @pxh-10;

                        .user-selection {
                            min-width: @px-100 * 2.5;
                        }
                    }

                    .content-container {
                        .padding-2px(2.5);
                        border-radius: 5px;
                        background: #f9f9f9;
                        box-shadow: inset 1px 1px 4px rgb(0 0 0 / 35%);
                        height: 70vh;
                        overflow: auto;

                        .event-timelineitem {
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            .event-timelineitem {
                .padding-2px(1.5, 3);
                border-radius: 5px;
                box-shadow: 1px 1px 4px rgb(0 0 0 / 40%);

                .title {
                    .flex-row-between;
                    align-items: center;

                    .left {
                        display: flex;
                        align-items: center;
                        .title-label {
                            .font-size-2px-bold(8);
                            .padding-2px(0, 5);
                        }
                        .anticon {
                            .font-size-2px(7.5);
                        }
                    }
                    .right {
                        .anticon {
                            .margin-2px(0, 2);
                        }
                    }

                    .anticon {
                        .font-size-2px(9.5);
                        color: #7d7d7d;
                        cursor: pointer;
                    }
                }

                .details {
                    .sub-info {
                        .flex-row-between;
                        padding-top: @pxh-10 * 0.3;

                        .left {
                            .flex-column;
                            padding-left: @px-5;
                        }
                        .right {
                            .assign-tag {
                                .font-size-2px-bold(6.5);
                                .padding-2px(0, 2);
                                display: flex;
                                align-items: center;
                                border-radius: 5px;
                                box-shadow: inset 1px 1px 2px #88888899;

                                &:not(.unauth) {
                                    transition: transform 0.3s ease;
                                    cursor: pointer;

                                    &:hover {
                                        transform: scale(1.1);
                                    }
                                }

                                & > .anticon {
                                    margin-right: @px-5;
                                }

                                &.assigned {
                                    background: #f7c32d;
                                    color: @color-white;
                                }

                                &.unassign {
                                    background: #ababab;
                                    color: @color-white;
                                }
                            }
                        }
                    }

                    .remarks {
                        .margin-2px(2.5, 0);
                        .padding-2px(3.5, 4.5);
                        display: block;
                        background: #f2f2f2;
                        border: 1px solid #dfdfdf;
                        border-radius: 5px;
                        min-height: @pxh-10 * 2;

                        .template {
                            &-MIM {
                                .flex-row-between;

                                .left {
                                    width: 50%;
                                    border-right: 1px dashed #b6b6b6;
                                    padding-right: @px-15;
                                }
                                .right {
                                    width: 50%;
                                    padding-left: @px-15;
                                }

                                .item {
                                    & > span {
                                        .font-size-2px(6);

                                        &:first-child {
                                            font-weight: bold;
                                            color: #9a9a9a;
                                        }
                                    }
                                }
                            }
                            &-MR {
                                .flex-row-between;

                                .left {
                                    width: 50%;
                                    border-right: 1px dashed #b6b6b6;
                                    padding-right: @px-15;
                                }
                                .right {
                                    width: 50%;
                                    padding-left: @px-15;
                                }

                                .item {
                                    & > span {
                                        .font-size-2px(6);

                                        &:first-child {
                                            font-weight: bold;
                                            color: #9a9a9a;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .footer-container {
                    .flex-row-between;
                    align-items: center;

                    .left {
                        .anticon {
                            .font-size-2px(6);
                            margin-right: @px-5;
                            color: #7d7d7d;
                        }
                        .date-container {
                            .font-size-2px(6);
                        }
                        .overdue-container {
                            .font-size-2px(7);
                            margin-left: @px-5;
                        }
                    }

                    .right {
                        .btn-container {
                            .padding-2px(2, 1);
                            background: @main-app-color;
                            border-radius: 10px 10px 5px 5px;
                            margin-bottom: -10px;

                            .ant-btn {
                                height: @pxh-10 * 2;
                                width: @px-50 * 0.8;
                                border-right: 1px solid #c6c6c6;
                                padding-top: 0px;
                                padding-bottom: 0px;

                                &:last-child {
                                    border-right: none;
                                }

                                .anticon {
                                    color: @color-white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.alarm-dashboard-comment-popover-item {
    .flex-column;
    .main-border;
    .padding-2px(3.5, 5);
    border-radius: 0 10px 10px 10px;

    .message {
        &-content {
            .font-size-2px(7);
            margin-bottom: @pxh-10;
        }
        &-footer {
            .font-size-2px(6);
            color: #575757;
        }
    }
}

.alarmdashboard-unassigned-item-modal-popover {
    .ant-modal-body {
        .padding-2px(5);

        .view-mode-selection {
            margin-bottom: @pxh-10;

            .alarm-dashboard-inline-comment-container {
                .ant-collapse-header {
                    .padding-2px(2.5, 0);
                }

                .enable-edit-btns {
                    .flex-row;
                    justify-content: flex-end;
                    gap: @px-10 * 0.7;

                    &.with-border {
                        .main-border;
                        .padding-2px(2.5);
                    }
                }

                .form-components-container {
                    .padding-2px(4);
                    .main-border;
                    border-top: none;
                    background: #f9f9f9;
                    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 25%);

                    .ant-form-item {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .modal-wrapper-container {
            .padding-2px(5, 2.5);
            max-height: 75vh;
            overflow: auto;

            .ant-timeline {
                .ant-timeline-item {
                    padding-bottom: 10px;
                }
            }

            .comment-history {
                .ant-timeline-item-content {
                    .item {
                        .alarm-dashboard-comment-popover-item;
                    }
                }
            }

            .group-progress-container {
                .ant-timeline-item-content {
                    .status-itl-container {
                        .ant-spin-dot {
                            display: inline;
                            width: @px-100 * 1.4;
                            height: 44px;
                            margin-left: @px-100 * -0.6;
                            transform: rotate(345deg);
                            -webkit-transform: rotate(345deg);
                            -moz-transform: rotate(345deg);
                            -ms-transform: rotate(345deg);
                            -o-transform: rotate(345deg);
                            background: #0000000d;
                            line-height: 2;
                            border-radius: 30px;
                            font-weight: bold;
                            margin-top: -32px;
                        }
                    }

                    .grouped-item {
                        .main-border;
                        border-radius: 5px;

                        .top-header-container {
                            .flex-row-between;

                            .left {
                                .severity-container {
                                    .font-size-2px-bold(7);
                                    .padding-2px(3);
                                    display: inline-block;
                                    min-width: 90px;
                                    border-radius: 5px 0 5px 0;
                                    text-align: center;
                                    box-shadow: inset -1px -1px 3px rgb(0 0 0 / 40%);
                                }
                            }
                            .right {
                                .padding-2px(3, 4);
                                .flex-row;
                                justify-content: flex-end;

                                & > span {
                                    color: #575757;
                                }

                                .date-text {
                                    .font-size-2px-bold(7);
                                    margin-left: @px-5;
                                }
                            }
                        }

                        .content {
                            .margin-2px(4);

                            &.MIM {
                                .main-border-2;

                                & > div {
                                    .flex-row-between;

                                    & > div {
                                        .padding-2px(3, 4);

                                        &:nth-child(1) {
                                            width: 35%;
                                        }
                                        &:nth-child(2) {
                                            width: 65%;
                                        }
                                    }
                                }

                                .title-header {
                                    border-bottom: 1px solid @border-color-2;
                                    background: #fafafa;

                                    .right {
                                        text-align: left;
                                    }
                                }
                            }
                            &.MR {
                                .main-border-2;

                                & > div {
                                    .flex-row-between;

                                    & > div {
                                        .padding-2px(3, 4);

                                        &:nth-child(1) {
                                            width: 35%;
                                        }
                                        &:nth-child(2) {
                                            width: 65%;
                                        }
                                    }
                                }

                                .title-header {
                                    border-bottom: 1px solid @border-color-2;
                                    background: #fafafa;

                                    .right {
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
//Alarm Dashboard - END

//Alarm Historical - START
.alarm-historical-main-container {
    .top-header {
        margin-bottom: @pxh-5;
        .title-label {
            .font-size-2px-bold(9);
            color: @main-app-color;
            margin-bottom: @pxh-15;
        }
    }

    .card-container {
        background: @color-white;
        border-radius: 5px;
        margin-bottom: @pxh-10;

        & > .header-container {
            .flex-row-between;
            .padding-2px(4, 8);

            & > .left {
                .flex-row;
                justify-content: flex-start;
                align-items: center;

                .btn-filter {
                    border-radius: 5px;
                    margin-right: @px-20;

                    .anticon {
                        .font-size-2px(9);
                    }
                }
            }

            & > .right {
                .flex-row;
                justify-content: flex-end;
                align-items: center;

                .extra-item-container {
                    & > .ant-form {
                        .flex-row;
                        column-gap: @px-5;
                        align-items: center;

                        & > .ant-form-item {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .extra-filter-panel {
            .padding-2px(2.5, 0);
            background: #f0f0f0;
            box-shadow: inset 0px 1px 3px rgb(0 0 0 / 35%);

            .options-item {
                .padding-2px(1, 8);
                .flex-row;
                justify-content: flex-start;

                .label {
                    .font-size-2px-bold(7);
                    min-width: 100px;
                }
                .options-container {
                    .flex-row;
                    justify-content: flex-start;

                    .item {
                        .font-size-2px(7);
                        .padding-2px(0, 5);
                        min-width: 120px;
                        background: @color-white;
                        cursor: pointer;
                        border-radius: 5px;
                        color: @color-black;
                        text-align: center;
                        margin-right: @px-10 * 0.5;

                        &.active {
                            background: @main-app-color;
                            color: @color-white;
                        }
                    }
                }
            }
        }

        .main-content-container {
            .padding-2px(5);
            .flex-row-between;

            & > .left {
                width: calc(100% - (@px-100 * 4));

                .alarm-historical-list-information-container {
                    .top-div {
                        .flex-row-between;
                        align-items: center;

                        .left {
                            .font-size-2px-bold(8);
                            color: #353535;
                        }
                        .right {
                            .status {
                                .font-size-2px(6);
                                .padding-2px(0.5, 2.5);
                                border-radius: 30px;

                                &-URSLV {
                                    background: #d8e8fa;
                                    color: #096dd9;
                                    //border: 1px solid #096dd9;
                                }
                                &-RSLV {
                                    background: #c3e6cd;
                                    color: #24663b;
                                    //border: 1px solid #24663b;
                                }
                                &-ABDN {
                                    background: #dddddd;
                                    color: #000000;
                                    //border: 1px solid #000000;
                                }
                            }
                        }
                    }

                    .content {
                        & > div {
                            .subtitle {
                                .font-size-2px(7);
                                color: #868686;
                            }
                            .btn-redirect {
                                .anticon {
                                    .font-size-2px(7);
                                }
                            }

                            &.date-container {
                                display: flex;
                                align-items: center;

                                .anticon {
                                    .font-size-2px(8);
                                }
                                .time-consumed {
                                    .font-size-2px(7);
                                    color: #6d6d6d;
                                    margin-left: @px-5;

                                    &.error {
                                        color: @color-error;
                                    }
                                }
                            }
                        }
                    }
                }

                .alarm-historical-list-details-container {
                    .details-content {
                        &-MIM {
                            .event-info-container {
                                border-bottom: 1px dashed #b6b6b6;
                                padding-bottom: @pxh-5;
                                margin-bottom: @pxh-5;

                                &:last-child {
                                    border-bottom: none;
                                    padding-bottom: 0;
                                    margin-bottom: 0;
                                }
                            }
                        }
                        &-MR {
                            .event-info-container {
                                border-bottom: 1px dashed #b6b6b6;
                                padding-bottom: @pxh-5;
                                margin-bottom: @pxh-5;

                                &:last-child {
                                    border-bottom: none;
                                    padding-bottom: 0;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            & > .right {
                width: @px-100 * 4;
                border-left: 1px solid @main-border-color;
                padding-left: @px-10;

                .summary-item-container {
                    .summary-item {
                        .flex-row-between;
                        .padding-2px(3.5, 5);
                        .main-border;
                        align-items: center;
                        border-left: 5px solid @main-app-color;
                        border-radius: 5px;
                        margin-bottom: @pxh-10 * 0.7;

                        & > .left {
                            .font-size-2px-bold(10);

                            color: @main-app-color;
                        }
                        & > .right {
                            .flex-column;
                            align-items: flex-end;
                            .font-size-2px-bold(9);
                            color: @main-app-color;

                            .subtitle {
                                .font-size-2px(6.5);
                                font-weight: normal;
                                color: #a8a8a8;
                            }
                        }

                        &.error {
                            border-left-color: @color-error;

                            & > .left {
                                color: @color-error;
                            }

                            & > .right {
                                color: @color-error;
                            }
                        }
                    }
                }

                .chart-container {
                    .alarm-historical-summary-by-module-container {
                        .padding-2px(3.5, 7.5);
                        .main-border;
                        border-radius: 5px;

                        .title-container {
                            .font-size-2px-bold(9);
                            color: @main-app-color;
                        }
                    }
                }
            }
        }
    }
}
//Alarm Historical - END

// MR Monitor Alarm Summary - START
.alarm-summary-for-MR-Monitor {
    .main-bg-white;
    .padding-2px(8);
    flex: 1;
    box-shadow: 0 0 5px 0 #0000001a;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: @pxh-10;

    .title {
        margin-bottom: @pxh-10;
        &-text {
            .font-size-2px-bold(9);
        }
    }

    .alarm-det-container {
        height: @pxh-100 * 2;
        overflow-y: auto;
        flex-grow: 1;
        .padding-2px(2, 3);

        .ant-timeline {
            .ant-timeline-item {
                padding-bottom: 10px;
                .alarm-detail-box {
                    .content {
                        .flex-column;
                        border: 1px solid @main-border-color;
                        margin-top: @pxh-5;

                        .title-cont {
                            .flex-row-between;
                            border-bottom: 1px solid @main-border-color;
                            .event-title {
                                .font-size-2px(7);
                                .padding-2px(2, 5);
                                font-weight: bold;
                            }
                            .count-container {
                                .flex-column-center;
                                .padding-2px(2, 0);
                                min-width: @px-100 * 0.7;
                                border-radius: 3px;
                                margin-left: @px-5 * 0.8;
                                box-shadow: 1px 1px 3px #ccc;
                                margin-top: -@pxh-5 * 2;
                            }

                            .ant-space-item {
                                .anticon {
                                    .font-size-2px(8);
                                    margin-right: @px-10;
                                }
                            }
                        }
                        .columns {
                            .flex-row;

                            .first-column {
                                flex: 1;
                                .padding-2px(2, 3);
                                .margin-2px(2.5, 0);
                                .alarm-di-me {
                                    .item {
                                        .flex-row-between;
                                        .font-size-2px(6);

                                        span {
                                            &:first-child {
                                                .font-size-2px-bold(6);
                                                color: #9a9a9a;

                                                &::after {
                                                    content: "\003A";
                                                    .padding-2px(0, 2.5);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .second-column {
                                flex: 1;
                                border-left: 2px dotted #cccccc;
                                .padding-2px(2, 3);
                                .margin-2px(2.5, 0);
                                .alarm-di-me {
                                    .item {
                                        .flex-row-between;

                                        span {
                                            &:first-child {
                                                .font-size-2px-bold(6);
                                                color: #9a9a9a;

                                                &::after {
                                                    content: "\003A";
                                                    .padding-2px(0, 2.5);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .time {
                        .font-size-2px(6);
                        margin-top: @pxh-5;
                    }
                }
            }
        }
    }
}
// MR Monitor Alarm Summary - END

.LPAlarmCommentModal {
    .box {
        .padding-2px(4, 8);
        .label {
            padding: 0 16px 0 0;
            text-align: right;
            span {
                color: #ff0000;
            }
        }
        .value {
            font-size: @font-size-14;
            font-weight: 600;
        }
    }
}

.pricemonitor-spread-alarm-container {
    padding-top: @pxh-10;

    .alarm-30mins-container,
    .historical-alarm-container {
        .main-border;
        .padding-2px(5);
        background: @bg-white;
        min-height: 50vh;
    }

    .alarm-30mins-container {
        .flex-column;

        .top-panel {
            .flex-row;
            justify-content: flex-end;
            align-items: center;
            gap: @px-10;
            margin-bottom: @pxh-10;

            .resync-div {
                .main-border-2;
                .flex-row;
                align-items: center;
                border-radius: 10px;

                .time-panel {
                    .font-size-2px(7);
                    padding-left: @px-10;
                    padding-right: @px-10;
                }
            }
        }

        .general-panel {
            margin-bottom: @pxh-10;
        }

        .expandable-row-container {
            background: @bg-white !important;

            .flexi-datatable {
                .flexi-table {
                    .ant-table {
                        margin: 0 !important;
                    }

                    .ant-table-pagination {
                        .margin-2px-important(6);
                    }
                }
            }
        }
    }
    .historical-alarm-container {
        .unresolved-spread-alarm-container {
            margin-top: @pxh-10;
        }
    }
}

.pricemonitor-lp-price-compare-container {
    .main-content {
        .padding-2px(5);
        border-top: 1px solid @main-border-color;
        border-bottom: 1px solid @main-border-color;

        .compare-items-row {
            .left {
                background: #fafafa;
                border: 1px solid rgba(5, 5, 5, 0.06);
                padding-right: @px-10;

                .extra-item-btn {
                    position: absolute;
                    top: @pxh-5;
                    right: @px-5;
                }
            }
            .right {
                padding-left: @px-10;

                .compare-items-overview-container {
                    margin-top: @pxh-10;
                }
            }

            .compare-items-overview {
                margin-bottom: @pxh-20;

                .title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span {
                        .font-size-2px-bold(8);
                        display: inline-block;
                        position: relative;
                    }
                }

                .wrapper {
                    min-height: 150px;
                    max-height: 250px;
                    overflow: auto;
                    display: flex;
                    align-content: flex-start;
                    flex-wrap: wrap;
                    row-gap: 8px;

                    .item {
                        box-sizing: border-box;
                        margin: 0 8px 0 0;
                        display: inline-flex;
                        align-items: center;
                        gap: 4px;
                        height: auto;
                        padding: 0 7px;
                        font-size: 12px;
                        line-height: 20px;
                        white-space: nowrap;
                        background: #fafafa;
                        border: 1px solid #d9d9d9;
                        border-radius: 2px;

                        .anticon {
                            width: 10px;
                            height: 10px;
                            opacity: 0.7;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .symbol-select-filters {
                .padding-2px(2.5);
                padding-right: 0;

                .filter-content-panel {
                    .padding-2px(0, 2.5);
                    margin-top: @pxh-20;

                    .symbol-container {
                        .flex-row;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: @pxh-10;

                        .ant-form-item {
                            margin-bottom: 0 !important;
                            margin-right: @px-10;
                        }
                    }

                    .radio-group-container {
                        .padding-2px(5, 0);
                        border-top: 1px solid @main-border-color;
                        border-bottom: 1px solid @main-border-color;
                        margin-bottom: @pxh-10;

                        & > .form-radio {
                            margin-bottom: 0;
                        }
                    }

                    .servers-checkbox-container {
                        min-height: 130px;

                        .ant-checkbox-group {
                            display: grid;
                            grid-template-columns: auto auto auto auto;

                            .ant-checkbox-group-item {
                                box-sizing: border-box;
                            }
                        }
                    }
                }

                .symbols-select {
                    .ant-select-selector {
                        max-height: 200px;
                        overflow: auto;
                    }

                    .ant-select-clear {
                        right: 20px;
                    }

                    span.ant-select-selection-item {
                        font-size: 13px;
                    }
                }

                .symbol-input-wrapper {
                    display: flex;
                    align-items: flex-end;
                    gap: @px-10;
                    margin-bottom: @pxh-10;
                }

                .ant-checkbox-group {
                    margin-bottom: @pxh-15;
                }

                .servers-checkbox {
                    min-height: 130px;
                }
            }

            .saved-compare-sets {
                .content {
                    display: flex;
                    gap: @px-10;
                    flex-wrap: wrap;
                }

                .saved-compare-set-wrapper {
                    // flex: 0 1 250px;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    border-radius: 3px;
                    border: 1px #ddd;
                    background: #f3f3f3;
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
                    cursor: pointer;
                    transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.15s background-color;

                    &:not(:has(.delete-btn:hover)):hover {
                        background: #eaeaea;
                        & .content .anticon {
                            transform: scale(1.1);
                            opacity: 1;
                        }
                    }

                    & .content {
                        width: 100%;
                        height: 100%;

                        .anticon {
                            opacity: 0.8;
                            transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.15s all;
                        }
                    }

                    & .content,
                    & .actions {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 8px;
                    }

                    .actions {
                        // position: absolute;
                        // top: 0;
                        // right: 0;
                        .delete-btn {
                            background-color: #f3f3f3;
                            padding: 0 10px;
                            &:hover {
                                color: white;
                                background-color: #ff4d4f;
                            }
                        }
                        .ant-badge-count {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 32px;
                            height: 24px;
                            border-radius: 24px;
                        }
                    }
                }
            }
        }

        .ant-form-item-explain.ant-form-item-explain-connected {
            position: absolute;
            top: 100%;
            left: 0;
        }
    }

    .form-btns {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }
    .btns-fwbw {
        .padding-2px(5);
        .flex-row;
        justify-content: center;
        align-items: center;
        background: #dddddd;
        box-shadow: inset 1px 1px 3px #b8b8b8;
        gap: @px-10;

        & > span {
            .font-size-2px-bold(9);
        }
    }

    .line-chart-container {
        .padding-2px(10);
        display: block;
        width: 100%;
    }
}

.swap-charging-tool-container {
    padding-top: @pxh-10;

    .main-container {
        .padding-2px(5);

        .swap-charging-tool-symbol-swap-container {
            margin-top: @pxh-10 * 1.5;
        }

        .swap-charging-tool-login-container {
            margin-top: @pxh-10 * 1.5;
        }
    }
}

.mt-client-security-container {
    .header-row {
        .flex-row-between;

        .left {
            & > .main-title {
                .font-size-2px-bold(9);
                color: @primary-color;
                padding-bottom: @pxh-5;
            }
        }
    }
    .security-list-container {
        padding: @pxh-10 0;
        background: @bg-white;
    }

    .change-security-history-container {
        padding: @pxh-10;
        background: @bg-white;

        .search-form {
            background: #fafafa;
            border: 1px solid #e1e1e1;
            border-radius: 3px;
            box-shadow: inset 1px 1px 3px 0 #e0e0e0;
            margin-bottom: @pxh-10;
            padding: @px-10;
            padding-bottom: 0;
        }
    }

    .uploader {
        margin-bottom: @pxh-10;
        .resign-common {
            background-color: rgba(0, 255, 242, 0.15);
        }
        .resign-personal {
            background-color: rgba(255, 208, 0, 0.35);
        }
    }
}

// #region swap-charging-tool-process-container
.swap-charging-tool-process-container {
    padding-top: @pxh-10;
}
// #endregion

// #region REPORT - Commission Record
.commission-record-container {
    .nav-bar {
        .margin-2px(0, 7);
        display: flex;
        margin-bottom: @pxh-15;

        .ant-segmented {
            border-radius: 5px;

            .ant-segmented-item {
                border-radius: 5px !important;
                min-width: @px-100;
            }
        }
    }
}
// #endregion

// #region REPORT - Daily Tick Report
.daily-tick-report-container {
    padding-top: @pxh-10;

    .main-container {
        .padding-2px(8);

        .content {
            padding-top: @pxh-10;
            display: flex;
            flex-direction: column;
            gap: @px-20;
        }
    }
}
// #endregion

// #region REPORT - Average Spread
.avg-spread-container {
    padding-top: @pxh-10;

    .main-container {
        .padding-2px(8);
        display: flex;
        flex-direction: column;
        gap: @px-20;

        .download-button {
            display: flex;
            flex-wrap: wrap;
            gap: @px-10;
        }

        .spreadOptions-col {
            margin-bottom: @pxh-10;
            .ant-checkbox-group {
                .ant-checkbox-wrapper,
                .ant-checkbox {
                    font-size: @font-size-13;
                }
            }
        }
    }
}
// #endregion

// #region USER MANUAL
// if did not set table width, the page will stretch according to the longest sentence in the table
.user-manual-md-container table {
    width: 100% !important;
    border-collapse: collapse !important;
}
// #endregion

// #region Start Price Analysis Alarm
.tab-alarm-monitor-wall-container {
    .padding-2px(5);
    background: @bg-white;

    .container-content {
        .padding-2px(5);

        .title-bar {
            .flex-row-between;
            .padding-2px(5);
        }
    }
}
.tab-alarm-history-container {
    .padding-2px(5);
    background: @bg-white;

    .container-content {
        .padding-2px(5);

        .title-bar {
            .flex-row-between;
            .padding-2px(5);
        }
    }
}

.solved-info {
    .title {
        line-height: 1.2;
        color: #666666;
    }
    .value {
        margin-bottom: @pxh-10 * 0.8;
        font-weight: 500;
    }
}
// #endregion

// #region Risk tool - Group Transfer Tool
.risk-tool-group-transfer-tool-container {
    .main-container {
        .padding-2px(5);

        .top-container {
            .flex-row-between;
            margin-bottom: @pxh-10;

            .right {
                .flex-row;
                justify-content: flex-end;

                & > .ant-select {
                    width: @px-100 * 1.8;
                }
            }
        }

        .group-transfer-container {
            .table-header-panel {
                .flex-row-between;
                align-items: center;

                & > span {
                    .font-size-2px-bold(7);
                    margin-left: @px-20;
                    margin-right: @px-10;
                }
            }
        }

        .historical-container {
            .historical-statistics-container {
                .top-search-panel {
                    .padding-2px(4, 5);
                    .main-border-2;
                    margin-bottom: @pxh-10;
                    border-radius: 5px;
                    background: #f7f7f7;
                }
            }
        }
    }
}
// #endregion

// #region Risk tool - Hub to Hub Tool
.risk-tool-hub-to-hub-container {
    .main-container {
        .padding-2px(5);

        .top-container {
            .flex-row-between;
            margin-bottom: @pxh-10;
        }
    }
}
// #endregion

// #region RC - Negative Equity Record
.negative-equity-record-container {
    .nav-bar {
        .flex-row-between;
        .margin-2px(0, 7);
        margin-bottom: @pxh-15;

        .ant-segmented {
            border-radius: 5px;

            .ant-segmented-item {
                border-radius: 5px !important;
                min-width: @px-100;
            }
        }

        .ant-input-search-with-button {
            width: 45%;
        }
    }

    .ner-process-tab {
        .flexi-datatable {
            .flexi-table {
                .ant-table-body {
                    table tbody.ant-table-tbody {
                        tr {
                            &.row-yellow {
                                td.ant-table-cell {
                                    background-color: #ffeeb7 !important;
                                    border-color: #ffeaa7;
                                }
                                &:hover td.ant-table-cell,
                                td.ant-table-cell.ant-table-column-sort {
                                    background-color: #ffe48d !important;
                                    border-color: #ffeaa7;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ner-extra-buttons {
            .flex-row;
            margin-left: @px-10;

            .ner-s {
                .flex-column;
                justify-content: center;
            }
        }
    }
}
// #endregion

// #region Start Order Routing Record
.order-routing-record-container {
    padding-top: @pxh-10;

    .main-container {
        .padding-2px(5);

        .order-routing-record-reject-container {
            margin-top: @pxh-10 * 1.5;

            .flexi-datatable {
                .flexi-table {
                    .ant-table-body {
                        table tbody.ant-table-tbody {
                            tr {
                                &.alert-bg-error {
                                    td.ant-table-cell {
                                        background-color: rgb(255, 220, 220) !important;
                                    }
                                    &:hover td.ant-table-cell,
                                    td.ant-table-cell.ant-table-column-sort {
                                        background-color: rgb(255, 220, 220) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .order-routing-record-history-container {
            margin-top: @pxh-10 * 1.5;
        }
    }
}
// #endregion

// #region RC - Config Diff
.config-diff-container {
    .download-cd-form-div,
    .generate-cd-form-div {
        .padding-2px(7);
    }

    .download-cd-form-div {
        padding-top: @pxh-20;
        padding-bottom: 0 !important;

        .ant-form-horizontal {
            .ant-form-item-with-help {
                margin-bottom: 0 !important;
            }
        }
    }
}
// #endregion

// #region Risk tool - Config Diff Record
.config-diff-record-container {
    .main-container {
        .padding-2px(5);

        .top-container {
            .flex-row-between;
            margin-bottom: @pxh-10;

            .right {
                .flex-row;
                justify-content: flex-end;

                & > .ant-select {
                    width: @px-100 * 1.8;
                }
            }
        }

        .download-whole-container {
            .main-border-2;
            .padding-2px(5, 7);
            border-radius: 3px;
            background: #fafafa;
            box-shadow: inset 1px 1px 3px 0px #e0e0e0;
            margin-bottom: @pxh-10;
        }

        .table-component-container {
            .search-panel {
                .main-border-2;
                .padding-2px(5, 7);
                border-radius: 3px;
                background: #fafafa;
                box-shadow: inset 1px 1px 3px 0px #e0e0e0;
                margin-bottom: @pxh-10;

                .labels {
                    .flex-row-between;
                }

                .main-panel {
                    .flex-row-between;

                    & > .ant-form-inline {
                        .flex-row;
                        justify-content: flex-end;
                    }

                    .right {
                        .flex-row;
                        justify-content: flex-end;

                        & > .ant-select {
                            width: @px-100 * 1.8;
                        }
                    }
                }
            }
            .table-panel {
                .value-changed {
                    background: #f7f7f7;
                }

                tr td.compare-color {
                    & > .diff-lah {
                        .flex-column;
                        & > div:first-child {
                            border-bottom: 1px solid #ffffff;
                        }
                    }
                }

                .row {
                    &-add {
                        & > td.compare-color:has(.diff-lah) {
                            background: #388e3c !important;
                        }

                        &:hover {
                            & > td.compare-color:has(.diff-lah) {
                                background: #388e3cbd !important;
                            }
                        }
                        &:nth-child(odd):not(.ant-table-expanded-row-level-1):hover {
                            & > td.compare-color:has(.diff-lah) {
                                background: #388e3cbd !important;
                            }
                        }
                    }
                    &-update {
                        & > td.compare-color:has(.diff-lah) {
                            background: #2196f3 !important;
                        }

                        &:hover {
                            & > td.compare-color:has(.diff-lah) {
                                background: #2196f3bd !important;
                            }
                        }
                        &:nth-child(odd):not(.ant-table-expanded-row-level-1):hover {
                            & > td.compare-color:has(.diff-lah) {
                                background: #2196f3bd !important;
                            }
                        }
                    }
                    &-delete {
                        & > td.compare-color {
                            background: #f44336 !important;
                        }

                        &:hover {
                            & > td.compare-color {
                                background: #f44336bd !important;
                            }
                        }
                        &:nth-child(odd):not(.ant-table-expanded-row-level-1):hover {
                            & > td.compare-color {
                                background: #f44336bd !important;
                            }
                        }
                    }
                    &-create {
                        & > td.compare-color:has(.diff-lah) {
                            background: #ff6f00 !important;
                        }

                        &:hover {
                            & > td.compare-color:has(.diff-lah) {
                                background: #ff6f00bd !important;
                            }
                        }
                        &:nth-child(odd):not(.ant-table-expanded-row-level-1):hover {
                            & > td.compare-color:has(.diff-lah) {
                                background: #ff6f00bd !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
// #endregion

// #region RC - Syndicate Trading
.syndicate-trading-container {
    .padding-2px(5);

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
        font-weight: bold !important;
    }

    .set-read-only-form {
        margin-bottom: @pxh-15;
    }

    .syndicate-trading-collapse-item-div {
        .flexi-datatable {
            .flexi-table {
                .ant-table-body {
                    table tbody.ant-table-tbody {
                        tr {
                            &.row-yellow {
                                td.ant-table-cell {
                                    background-color: #ffeeb7 !important;
                                    border-color: #ffeaa7;
                                }
                                &:hover td.ant-table-cell,
                                td.ant-table-cell.ant-table-column-sort {
                                    background-color: #ffe48d !important;
                                    border-color: #ffeaa7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// #endregion

// #region RC - CA Dividend Separate Process
.cadividend-separate-process-container {
    .mid-segment {
        .padding-2px(5);
    }
}
// #endregion

// Start Symbol Leverage Step Modal
.system-leverage-step-modal-content {
    margin-top: @pxh-10 * 1.5;
}
.system-leverage-step-modal-content-steps-action {
    text-align: right;
    padding-top: @pxh-10 * 1.5;
}
// End Symbol Leverage Step Modal

/// #region Server Page v2
.server-page-v2-createedit-container {
    .title-container {
        margin-bottom: @pxh-10 * 2.5;

        & > span {
            .font-size-2px-bold(8);
            .padding-2px(2.5, 0);
            display: inline-block;
            border-bottom: 5px solid #004b57;
        }
    }

    & > .left {
        border-right: 1px solid @main-border-color;
        padding-right: @px-10;
    }

    & > .right {
        padding-left: @px-10;
    }

    .input-row-container {
        .flex-row;
        align-items: baseline;
        margin-bottom: @pxh-10;

        .btn-remove {
            margin-left: @px-5;

            .anticon {
                color: @color-error;
            }
        }

        &.brand-div {
            .form-select {
                margin-bottom: 0;
            }

            & > .form-text {
                margin-bottom: 0;

                &:not(.ant-form-item-hidden) {
                    width: calc(75% - 20px);
                }
            }
        }

        &.bridge-div {
            .form-select {
                margin-bottom: 0;
            }

            & > .form-text:not(.ant-form-item-hidden) {
                margin-bottom: 0;
                width: calc(60% - 20px);
            }
        }

        &.datacenter-div {
            .form-select {
                margin-bottom: 0;
            }

            & > .form-text:not(.ant-form-item-hidden) {
                margin-bottom: 0;
                width: calc(48% - 40px);

                &:nth-child(2) {
                    width: calc(30% - 40px);
                }
            }

            & > .form-checkbox {
                margin-bottom: 0;
                margin-left: @px-15;
                margin-right: @px-15;
            }
        }
    }

    .btn-add-new {
        display: block;
        width: 100%;
    }

    .footer-container {
        .margin-2px(5, 0);
    }
}
/// #endregion

// Start Roll Over Tool
.roll-over-tool-step-content {
    margin-top: @pxh-10 * 1.5;
    .execute-status {
        margin-bottom: @pxh-10 * 1.5;
        text-align: center;
    }
}
.roll-over-tool-step-content-steps-action {
    text-align: right;
    padding-top: @pxh-10 * 1.5;
}

// End Roll Over Tool

// #region RC - Balance Update
.risk-tool-balance-update-container {
    padding-top: @pxh-10;

    .main-container {
        .padding-2px(5);

        .upload-container {
            margin-top: @pxh-10 * 1.5;
        }

        .historical-container {
            margin-top: @pxh-10 * 1.5;
        }
    }
}
// #endregion

// #region RC - CID
.rc-cid-container {
    .top-nav {
        .flex-row-between;
        .margin-2px(0, 7);
        margin-bottom: @pxh-15;

        .ant-input-search-with-button {
            width: 45%;
        }
    }

    .rc-cid-tabs-content {
        .daily-report-tab {
            .table-title {
                .font-size-2px-bold(8);
                color: @bg-black;
            }

            .actions-col {
                .row-dropdown-panel {
                    text-align: center;
                }
            }
        }
        .history-tab,
        .label-setting-tab,
        .whitelist-setting-tab {
            .extra-header-buttons {
                button {
                    margin-left: @px-10;
                }
            }
        }
    }
}
// #endregion

// #region Price Setting
.price-settings-page-container {
    .ant-tabs-nav {
        &::before {
            border-bottom: 0 !important;
        }
    }
    .ant-tabs-tabpane {
        .padding-2px(2.5);
        .main-border-2;
        background: @bg-white;
        border-radius: 0px 5px 5px 5px;
    }
}
// #endregion

// Start Toxic CLient Check Notification Configuration
.noti-config {
    .top-bar {
        .flex-row;
        justify-content: flex-end;
        border-bottom: 1px solid @main-border-color;
        padding-bottom: @pxh-10;
    }
    .left-cont {
        .padding-2px(5, 5);
        .left-top-bar {
            .flex-row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: @pxh-30;
        }
        .align-configs-items-tab-create {
            margin-top: @pxh-5;
            margin-bottom: @pxh-5;
        }
        .ant-tabs-tab {
            display: block;

            .align-configs-items-tab-style {
                .flex-row;
                justify-content: space-between;
            }
        }
    }
    .right-cont {
        .padding-2px(8, 0);
        background-color: #f0f2f5;
        .right-top-bar {
            .flex-row;
            justify-content: space-between;
            align-items: center;
            .padding-2px(4, 8);
        }
    }
}
.toxic-client-alarm-config-form {
    .filter-panel {
        .main-border-2;
        .title-panel {
            .padding-2px(2.5, 2.5);
            .font-size-2px-bold(8);
            .main-border-2-bottom;
            .flex-row-between;
            align-items: center;

            .left {
                padding-left: @px-5;
                color: @main-app-color;
            }
        }
    }
    .big-card-container .ant-card-body {
        max-height: 420px; /* Set the height you need */
        overflow-y: auto; /* Enable vertical scrolling */
        .nice-scrollbar;
        background-color: #f0f2f5;
    }

    /* Exclude the inner small card's body from being affected */
    .big-card-container .ant-card-body .ant-card .ant-card-body {
        max-height: unset;
        overflow-y: unset;
        background-color: unset;
    }
}

.toxic-client-assign-user-container {
    max-height: @pxh-100 * 4.5; /* Set the height you need */
    overflow-y: auto; /* Enable vertical scrolling */
    .nice-scrollbar;
    .padding-2px(0, 8);

    .list-item-div {
        .item-container {
            .padding-2px(2, 0);
        }
    }
}
// End Toxic CLient Check Notification Configuration

// #region RC - Price Setting - Mid Price Setting
.mid-price-setting-container {
    .top-nav {
        .flex-row-between;
        .margin-2px(5, 0);

        button {
            margin-left: @px-10;
        }
    }

    .table-title {
        .font-size-2px-bold(8);
        color: @bg-black;
    }

    .flexi-datatable {
        .flexi-table {
            .ant-table-body {
                table tbody.ant-table-tbody {
                    tr {
                        &.new-row {
                            td.ant-table-cell {
                                background-color: #d8f2d8 !important;
                                border-color: #c1ebc1;
                            }
                            &:hover td.ant-table-cell,
                            td.ant-table-cell.ant-table-column-sort {
                                background-color: #c5ecc5 !important;
                                border-color: #c1ebc1;
                            }
                        }
                        &.modified-row {
                            td.ant-table-cell {
                                background-color: #ffedcd !important;
                                border-color: #ffe4b4;
                            }
                            &:hover td.ant-table-cell,
                            td.ant-table-cell.ant-table-column-sort {
                                background-color: #ffe4b3 !important;
                                border-color: #ffe4b4;
                            }
                        }
                    }
                }
            }
        }
    }
}

.extra-header-buttons {
    button {
        margin-left: @px-10;
    }
}
// #endregion

.extra-table-header-buttons {
    button {
        margin-left: @px-10;
    }
}

.status-config-restart-container {
    .table-title-status-tag {
        .font-size-2px(7);
        .padding-2px(2, 7.5);
    }
}

// #region price analysis setting
.price-backend-opt-setting-v2-container,
.price-analysis-setting-container,
.toxic-client-setting-container,
.CID-setting-container,
.adminfee-settings-container,
.global-setting-container {
    .top-panel {
        .padding-2px(5);
        padding-bottom: @pxh-15;
    }
}
.price-analysis-servers-olli {
    .flex-row;
    .padding-2px(0, 2.5);
    max-height: @pxh-100 * 3;
    overflow-y: auto;
    justify-content: center;
    min-width: @px-100 * 2;

    ul {
        list-style-type: none;
        padding-left: 0;
        width: 100%;

        li {
            .flex-row-between;
            .font-size-2px-bold(8);
            padding-top: @pxh-5;
            padding-bottom: @pxh-5;
            border-bottom: 1px solid @main-border-color;

            &:last-child {
                border-bottom: none;
            }

            & > span {
                .padding-2px(0, 2.5);
                .font-size-2px-bold(7);
                border-radius: 3px;
                border: 1px solid #828282;
                background: #d4d4d4;
            }
        }
    }
}
.price-analysis-symbol-settings-container {
    .flexi-table {
        .ant-table-tbody {
            tr {
                td.ant-table-cell {
                    &:has(.highlight-col) {
                        background: #56a7a3;
                    }
                }
            }
        }
    }
}
// #endregion

// #region Setting - Spread Google Sheet
.spread-google-sheet-pages {
    .top-panel {
        .padding-2px(2.5, 5);
        margin-bottom: @pxh-10;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
// #endregion

// #region RC - PE Order Compare
.pe-order-compare-container {
    .nav-bar {
        .flex-row-between;
        .margin-2px(0, 7);
        margin-bottom: @pxh-15;
    }

    .peoc-data-tab {
        .peoc-d-extra-buttons {
            .flex-row;
            .alarm-btn {
                margin-left: @px-10;
            }
        }
    }

    .peoc-report-tab {
        .padding-2px(7);
    }
}
// #endregion

// #region Mid Bias Alarm
// History tab
.mid-bias-alarm-history-tab {
    background-color: @bg-white;
    margin-bottom: 2rem;
    padding-bottom: 1rem;

    .top-search-panel {
        .padding-2px(4, 5);
        .main-border-2;
        margin-bottom: @pxh-10;
        border-radius: 5px;
        background: #f7f7f7;

        .filter-buttons {
            .flex-row;
        }

        button {
            width: @px-100;
            margin-left: @px-10;
        }

        .ant-form-inline .ant-form-item-with-help {
            margin-bottom: 0;
        }
    }

    .flexi-datatable {
        .flexi-table {
            .ant-table-body {
                table tbody.ant-table-tbody {
                    tr {
                        &.row-yellow {
                            td.ant-table-cell {
                                background-color: #ffeeb7 !important;
                                border-color: #ffeaa7;
                            }
                            &:hover td.ant-table-cell,
                            td.ant-table-cell.ant-table-column-sort {
                                background-color: #ffe48d !important;
                                border-color: #ffeaa7;
                            }
                        }

                        &.row-red {
                            td.ant-table-cell {
                                background-color: #fac6c6 !important;
                                border-color: #f8b4b4;
                            }
                            &:hover td.ant-table-cell,
                            td.ant-table-cell.ant-table-column-sort {
                                background-color: #f8bbbb !important;
                                border-color: #f8b4b4;
                            }
                        }
                    }
                }
            }
        }
    }
}
// #endregion

.mim-account-render-panel {
    .flex-row-between;

    .anticon.anticon-alert {
        color: #f00f00;
        cursor: pointer;
    }
}

.client-analysis-container {
    .big-title {
        .font-size-2px-bold(9);
        color: @primary-color;
        margin-bottom: @pxh-10;
    }

    .filter-bar {
        .flex-row;
        background: @bg-white;
        border-radius: 3px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: @pxh-20;
        padding: @px-10;
    }

    .main-content {
        .kline-tab-container,
        .association-tab-container,
        .pnl-tab-container {
            .padding-2px(5);
            background: @bg-white;

            .tab-title {
                .font-size-2px-bold(8);
                margin-bottom: @pxh-10;
            }

            .client-analysis-chart-panel {
                .padding-2px(5);

                .title-box {
                    margin-bottom: @pxh-5;

                    .title-box-text {
                        .font-size-2px-bold(9);
                        color: @primary-color;
                    }
                }

                .summary-row {
                    .ant-table-cell {
                        .bold-title;
                        font-size: @font-size-13;
                    }
                }
            }
        }

        .kline-tab-container {
            .top-search-panel {
                .padding-2px-important(5, 8);
                .main-border-2;
                margin-bottom: @pxh-10;
                border-radius: 5px;
                background: #f7f7f7;

                .filter-btns {
                    margin-left: @px-10;

                    button {
                        width: @px-100;
                        margin-right: @px-10;
                    }
                }
                .divider-nogap {
                    .margin-2px-important(6, 0);
                }
            }

            // KLineChart Style
            .k-line-chart-row {
                margin-top: @pxh-20;

                .k-line-chart-container {
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                    background-color: #172331;
                    width: 100%;
                    height: 830px;
                    padding: 0px 0px 0px 0px;
                    .k-line-chart-title {
                        margin: 0;
                        color: #252525;
                        padding-bottom: 10px;
                    }
                    .k-line-chart {
                        display: flex;
                        flex: 1;
                    }
                }

                .k-line-loading {
                    position: absolute;
                    display: flex;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-align-items: center;
                    align-items: center;
                    z-index: 10;
                }

                .bottom-extra-buttons {
                    .view-chart-btn {
                        margin-left: @px-20;
                    }
                }

                .right-panel {
                    padding-left: @px-10;
                }
            }
        }
    }
}

.centralized-settings-panel-container {
    .ant-breadcrumb {
        ol {
            li {
                .flex-row;
                align-items: center;
            }
        }
    }

    .settings-container {
        .title-panel {
            .padding-2px(5, 0);
            border-bottom: 1px solid @border-color-2;

            span {
                .font-size-2px-bold(11);
                color: @main-app-color;
            }
        }

        .content-panel {
            .padding-2px(7.5, 0);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;

            .setting-panel {
                .main-border-2;
                .flex-column;
                justify-content: flex-start;
                border-radius: 5px;

                .setting-panel-title {
                    .padding-2px(5, 10);
                    background: @main-app-color;
                    border-radius: 5px 5px 0px 0px;
                    box-shadow: 0px 1px 3px #7b7b7b;

                    span {
                        .font-size-2px-bold(11);
                        color: @color-white;
                    }
                }
                .setting-panel-content {
                    .padding-2px(10);
                    flex: 1;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;
                    background: @bg-white;
                    border-radius: 0px 0px 5px 5px;

                    .setting-item-panel {
                        .flex-row-between;
                        min-width: @px-100 * 1.8;

                        .icon-panel {
                            width: @px-10 * 4.2;

                            .anticon {
                                .font-size-2px-bold(19);
                                color: @main-app-color;
                            }
                        }
                        .list-panel {
                            width: calc(100% - (@px-10 * 4.2));

                            .list-panel-title {
                                .font-size-2px-bold(9);
                                color: @main-app-color;
                                margin-bottom: @pxh-10;
                            }
                            .list-panel-content {
                                ul {
                                    list-style-type: none;
                                    padding-left: 0px;

                                    li {
                                        .padding-2px(1, 0);
                                        .font-size-2px(7);
                                        cursor: pointer;

                                        &:hover {
                                            color: #138992;
                                        }

                                        &::before {
                                            .font-size-2px(7);
                                            content: "\27A4";
                                            margin-right: @px-5;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .setting-main-container {
        .padding-2px(5);
        background: @bg-white;
        margin-bottom: @pxh-10;
    }
}

.flexi-table-small-title-style {
    .font-size-2px-bold(8);
    color: @color-black;
}

.settings-panel-main-title-container {
    .title {
        .flex-row-between;
        padding-bottom: @pxh-10;
        border-bottom: 1px solid @border-color-2;

        & > span {
            .font-size-2px-bold(11);
            color: @main-app-color;
        }
    }
}

.settings-panel-main-segment-panel-container {
    .top-panel {
        .padding-2px(5);
        padding-bottom: @pxh-15;
    }
}

.fclean-symbol-and-midbias-setting-container {
    .alert-container {
        .margin-2px(5, 0);
        .padding-2px(5);
        border: 1px solid #ffb7b2;
        background: #ffe9e9;

        span {
            .font-size-2px-bold(8);
            color: #e24242;
        }
    }

    .main-container {
        .main-border-2;
        .padding-2px(5);

        .panel {
            .title {
                .font-size-2px-bold(9);
                color: @primary-color;
                margin-bottom: @pxh-5;
            }

            &:nth-child(2) {
                margin-top: @pxh-20 * 1.5;
            }
        }
        .footer-container {
            margin-top: @pxh-15;
            padding-top: @pxh-10;
            border-top: 1px solid @border-color-2;
        }
    }
}

.system-monitor-reject-order-container {
    .padding-2px(5);
    background: @bg-white;

    .flexi-table {
        .error-row {
            & > td {
                background: #edb0b0 !important;
            }
        }
        .warning-row {
            & > td {
                background: #fad1b1 !important;
            }
        }
    }
}

.common-issues-log-table-container {
    .search-panel {
        .flex-row-between;

        .left {
            flex: 1;
        }
        .right {
            & > .ant-btn {
                margin-left: @px-10;
            }
        }
    }
}

.rollover-tool-edit-component-container {
    .padding-2px(10);
    background: @bg-white;

    .step-header {
        margin-top: @pxh-10;
        margin-bottom: @pxh-10 * 4;
    }

    .step-content {
        .padding-2px(4);
        .margin-2px(10, 0);
        box-shadow: inset 0px 0px 5px #8d8d8d;
    }

    .step-footer {
        .flex-row;
        justify-content: flex-end;
        gap: 15px;
    }

    .rollover-tool-step5-container {
        .step-5-search-panel {
            .flex-row;
        }

        .table-panel {
            .margin-2px(5, 0);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.price-connector-container {
    .title-panel {
        margin-bottom: @pxh-10;
        .title {
            .font-size-2px-bold(10);
            color: @main-app-color;
        }

        .multiple-selection-header-panel {
            .flex-row;
            justify-content: flex-start;

            .ant-btn {
                margin-right: @px-10;
            }
        }
    }

    .main-content-panel {
        .item-panel {
            display: inline-block;
            width: calc(16.6% - 10px);
            height: 12vh;
            margin-right: 10px;
            margin-bottom: 10px;

            &.multiple-selection {
                cursor: pointer;
            }

            .outer {
                .flex-column;
                justify-content: space-between;
                width: 100%;
                height: 12vh;
                box-shadow: 1px 1px 3px #d5d5d5;
                border: 1px solid #efefef;
                border-radius: 5px;

                .name-panel {
                    .flex-column-center;
                    .font-size-2px-bold(8);
                    justify-content: center;
                    flex: 3;
                    color: #525252;
                }

                .footer {
                    .padding-2px(4, 5);
                    .flex-row;
                    justify-content: flex-end;
                    flex: 1;
                    padding-top: 0;

                    .ant-btn {
                        margin-left: 10px;
                    }
                }
            }

            &.selected {
                background: #efefef;

                .outer {
                    border-color: #147571;
                }
            }
        }
    }
}

.clean-symbol-main-content {
    .clean-symbol-main-container {
        .main-border;
        .padding-2px(2.5);
    }
}

// #region Withdrawal Audit
.withdrawal-historical-list-tab,
.withdrawal-req-list-tab {
    .withdrawal-audit-content-nav-bar {
        .flex-row-between;
        margin-bottom: @pxh-10;

        .ant-segmented-item-label {
            .count-text {
                .padding-2px(0, 4);
                color: @color-white;
                background: #ff4d4f;
                border-radius: 30px;
                line-height: 1.8;
                margin-left: @px-5;
            }
        }
    }

    .acc-cli-info-col {
        .flex-column;

        .acc-cli-item {
            .flex-row-between;

            & > span:first-child {
                color: #8b8b8b;
            }
            & > span:nth-child(2) {
                font-weight: bold;
            }
        }
    }

    .wa-req-time-id-col {
        .flex-column;

        & > span:nth-child(2) {
            color: #8b8b8b;
            font-size: @font-size-11;
        }
    }

    .wa-blocktypes-col {
        .wa-blocktypes-item {
            .padding-2px(1, 6);
            border-radius: 3px;
            font-weight: bold;
            text-align: center;
            background-color: @border-color-2;
            margin-right: @px-5;
            border: 1px solid #f0f0f0;

            max-width: 100%;
            display: inline-block;
            word-break: break-all;

            &.exceeded {
                margin-bottom: @pxh-2 * 3;
            }
        }
    }

    .wa-amount-col {
        .flex-column;

        .base-amount-currency {
            color: #888888;
        }
    }

    .grouped-dates-col {
        .last-updated-datetime {
            .by-user {
                line-height: 1.2;
                color: #8b8b8b;

                &-title {
                    font-weight: bold;
                    color: #8b8b8b;
                }
            }
            .value {
                margin-bottom: @pxh-10 * 0.8;
                font-weight: 500;
            }
        }
    }
}

.wa-tags {
    display: inline-block;
    word-break: normal;
    border-radius: 3px;
    line-height: 20px;
    font-size: @font-size-12;
    padding: 0 7px;
    max-width: 100%;

    &.approved {
        color: #389e0d;
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
    }
    &.rejected {
        color: #cf1322;
        background-color: #fff1f0;
        border: 1px solid #ffa39e;
    }
    &.pending {
        color: #d46b08;
        background-color: #fff7e6;
        border: 1px solid #ffd591;
    }
    &.checking {
        color: #096dd9;
        background-color: #e6f7ff;
        border: 1px solid #91d5ff;
    }
    &.riskType {
        color: @color-white;
        background-color: #343d46;
        border: 1px solid #a7adba;
    }
}

.withdrawal-audit-logs-modal {
    .padding-2px(5);

    .timeline-item {
        &-title {
            font-size: @font-size-14;
            margin-bottom: @pxh-10 * 0.7;

            .wa-tags {
                &.wa-logs {
                    font-size: @font-size-16;
                    margin-bottom: @pxh-10;
                }
            }
        }
        &-content {
            .padding-2px(4, 8);
            .margin-2px(2, 0);
            .main-border;
            border-radius: 0 5px 5px 5px;
        }
        &-datetime-info {
            font-size: @font-size-11;
            color: #474747;
            .margin-2px(0, 5);
        }
    }

    .ant-timeline-item {
        &.ant-timeline-item-last {
            padding-bottom: 0 !important;
        }
    }
}

.withdrawal-api-logs-tab {
    .wa-req-time-id-col {
        .flex-column;

        & > span:nth-child(2) {
            color: #8b8b8b;
            font-size: @font-size-11;
        }
    }

    .waal-err-details {
        .flex-row;

        .more-btn {
            .margin-2px(0, 5);
            font-size: @font-size-14;
        }
    }
}
// #endregion

.goodbad-btn-panel {
    .flex-row;
    justify-content: flex-end;

    .ant-btn {
        margin-left: @px-10;
    }
}

.transaction-order-checker-container {
    .sub-type-class {
        background: #ededed;
    }
}

.price-backend-opt-setting-v2-container {
    .info-panel {
        .main-border-2;
        .padding-2px(5, 7);
        .flex-row-between;
        border-radius: 3px;
        background: #fafafa;
        box-shadow: inset 1px 1px 3px 0px #e0e0e0;
        margin-bottom: @pxh-10;

        ol {
            .margin-2px(5, 0);
            padding-left: 15px;

            span {
                color: @color-sucess;
                font-weight: bold;
            }
        }

        .refresh-note {
            align-self: center;
        }
    }
}

@primary-color: #00615D;@layout-header-background: #034c58;@layout-header-color: #ffffff;@layout-trigger-background: #1C5A65;@menu-light-inline-submenu-bg: transparent;@text-selection-bg: #5bbbff;@table-row-hover-bg: #ececec;